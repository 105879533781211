let menu = [
  {
    category: "Vegetarian Pizzas",
    items: [
      {
        id: 1,
        name: "Old Classic",
        price: 110,
        description: "Pizza Sauce, Mozzarella Cheese.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ef8/8ce9c4b54c8b28f22496671a28379ef8.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Small 8'",
      },
      {
        id: 2,
        name: "Old Classic",
        price: 210,
        description: "Pizza Sauce, Mozzarella Cheese.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ef8/8ce9c4b54c8b28f22496671a28379ef8.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Medium 10'",
      },
      {
        id: 3,
        name: "Old Classic",
        price: 400,
        description: "Pizza Sauce, Mozzarella Cheese.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ef8/8ce9c4b54c8b28f22496671a28379ef8.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Large 12'",
      },
      {
        id: 4,
        name: "Four Seasons",
        price: 155,
        description: "Onion, Capsicum, Tomato, Mushroom.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5c7/531b166fd4a3a446c6b834c60765a5c7.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Small 8'",
      },
      {
        id: 5,
        name: "Four Seasons",
        price: 310,
        description: "Onion, Capsicum, Tomato, Mushroom.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5c7/531b166fd4a3a446c6b834c60765a5c7.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Medium 10'",
      },
      {
        id: 6,
        name: "Four Seasons",
        price: 430,
        description: "Onion, Capsicum, Tomato, Mushroom.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5c7/531b166fd4a3a446c6b834c60765a5c7.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Large 12'",
      },
      {
        id: 7,
        name: "Spicy Texmex",
        price: 165,
        description:
          "Golden Corn, Bell Pepper, Paprika, Jalapeno, Chipotle Mayo.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/91b/d694e565aa7f843aa835a8d4f581091b.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Small 8'",
      },
      {
        id: 8,
        name: "Spicy Texmex",
        price: 310,
        description:
          "Golden Corn, Bell Pepper, Paprika, Jalapeno, Chipotle Mayo.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/91b/d694e565aa7f843aa835a8d4f581091b.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Medium 10'",
      },
      {
        id: 9,
        name: "Spicy Texmex",
        price: 430,
        description:
          "Golden Corn, Bell Pepper, Paprika, Jalapeno, Chipotle Mayo.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/91b/d694e565aa7f843aa835a8d4f581091b.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Large 12'",
      },
      {
        id: 10,
        name: "Oh Paneer!",
        price: 165,
        description: "Onion, Capsicum, Mushroom, Corn, Paneer.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6d2/d3dcf25529b780071e99443e0fff16d2.jpeg?fit=around|130:130&crop=130:130;*,*", // Matches "Paneer Pizza 8\""
        available: true,
        type: "Veg",
        size: "Small 8'",
      },
      {
        id: 11,
        name: "Oh Paneer!",
        price: 320,
        description: "Onion, Capsicum, Mushroom, Corn, Paneer.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6d2/d3dcf25529b780071e99443e0fff16d2.jpeg?fit=around|130:130&crop=130:130;*,*", // Matches "Paneer Pizza 8\""
        available: true,
        type: "Veg",
        size: "Medium 10'",
      },
      {
        id: 12,
        name: "Oh Paneer!",
        price: 440,
        description: "Onion, Capsicum, Mushroom, Corn, Paneer.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6d2/d3dcf25529b780071e99443e0fff16d2.jpeg?fit=around|130:130&crop=130:130;*,*", // Matches "Paneer Pizza 8\""
        available: true,
        type: "Veg",
        size: "Large 12'",
      },
      {
        id: 13,
        name: "Veggies Love Loaded",
        price: 165,
        description: "Black Olive, Capsicum, Onion, Mushroom, Corn.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6e0/f2ee1ad4a59692362637b11318d0f6e0.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Small 8'",
      },
      {
        id: 14,
        name: "Veggies Love Loaded",
        price: 325,
        description: "Black Olive, Capsicum, Onion, Mushroom, Corn.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6e0/f2ee1ad4a59692362637b11318d0f6e0.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Medium 10'",
      },
      {
        id: 15,
        name: "Veggies Love Loaded",
        price: 445,
        description: "Black Olive, Capsicum, Onion, Mushroom, Corn.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6e0/f2ee1ad4a59692362637b11318d0f6e0.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Large 12'",
      },
      {
        id: 16,
        name: "Captain Veg Kebab",
        price: 165,
        description:
          "Veg Seekh Kebab, Onion, Green Capsicum, Tomato, Sweet Corn, Tandoori Mayo.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/74e/0fb03ce107f0b394875d8cfaf1d4d74e.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Small 8'",
      },
      {
        id: 17,
        name: "Captain Veg Kebab",
        price: 325,
        description:
          "Veg Seekh Kebab, Onion, Green Capsicum, Tomato, Sweet Corn, Tandoori Mayo.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/74e/0fb03ce107f0b394875d8cfaf1d4d74e.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Medium 10'",
      },
      {
        id: 18,
        name: "Captain Veg Kebab",
        price: 445,
        description:
          "Veg Seekh Kebab, Onion, Green Capsicum, Tomato, Sweet Corn, Tandoori Mayo.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/74e/0fb03ce107f0b394875d8cfaf1d4d74e.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Large 12'",
      },
      {
        id: 19,
        name: "All Things Exotic",
        price: 175,
        description:
          "Corn, Black Olives, Yellow Capsicum, Jalapeno, Red Capsicum, Mushroom, Chipotle Mayo.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1a2/5b712e613970291c24847191da32d1a2.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Small 8'",
      },
      {
        id: 20,
        name: "All Things Exotic",
        price: 340,
        description:
          "Corn, Black Olives, Yellow Capsicum, Jalapeno, Red Capsicum, Mushroom, Chipotle Mayo.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1a2/5b712e613970291c24847191da32d1a2.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Medium 10'",
      },
      {
        id: 21,
        name: "All Things Exotic",
        price: 465,
        description:
          "Corn, Black Olives, Yellow Capsicum, Jalapeno, Red Capsicum, Mushroom, Chipotle Mayo.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1a2/5b712e613970291c24847191da32d1a2.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Large 12'",
      },
    ],
  },
  {
    category: "Signature Veg Pizzas",
    items: [
      {
        id: 1,
        name: "Taste of the Mediterranean",
        price: 185,
        description:
          "Bell Pepper, Onion, Sun-dried Tomato, Olive, Jalapeno, Paprika.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/501/86ca2b3074dd099a137ac91219dd3501.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Small 8'",
      },
      {
        id: 2,
        name: "Taste of the Mediterranean",
        price: 345,
        description:
          "Bell Pepper, Onion, Sun-dried Tomato, Olive, Jalapeno, Paprika.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/501/86ca2b3074dd099a137ac91219dd3501.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Medium 10'",
      },
      {
        id: 3,
        name: "Taste of the Mediterranean",
        price: 489,
        description:
          "Bell Pepper, Onion, Sun-dried Tomato, Olive, Jalapeno, Paprika.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/501/86ca2b3074dd099a137ac91219dd3501.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Large 12'",
      },

      {
        id: 4,
        name: "Paneer Keema",
        price: 210,
        description:
          "Paneer Keema, Jalapeno, Paprika, Onion, Capsicum, Tandoori Mayo.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/64f/0a38ff747c6adcd3ff221cd02cc5164f.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Small 8'",
      },
      {
        id: 5,
        name: "Paneer Keema",
        price: 350,
        description:
          "Paneer Keema, Jalapeno, Paprika, Onion, Capsicum, Tandoori Mayo.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/64f/0a38ff747c6adcd3ff221cd02cc5164f.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Medium 10'",
      },
      {
        id: 6,
        name: "Paneer Keema",
        price: 525,
        description:
          "Paneer Keema, Jalapeno, Paprika, Onion, Capsicum, Tandoori Mayo.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/64f/0a38ff747c6adcd3ff221cd02cc5164f.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Large 12'",
      },
    ],
  },
  {
    category: "Pizza Mania Veg",
    items: [
      {
        id: 1,
        name: "Onion Pizza",
        price: 80,
        description: "Pizza topped with fresh onions.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b92/79ab64d96c21a516a464d05a4e0a6b92.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Capsicum Pizza",
        price: 90,
        description: "Pizza topped with fresh capsicum.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ba8/3bde82c64c88c69996af8f6620faeba8.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Tomato Pizza",
        price: 90,
        description: "Pizza topped with fresh tomatoes.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c84/9b802e6d2ec362bc7074743c09a08c84.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Corn Pizza",
        price: 100,
        description: "Pizza topped with sweet corn.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/063/762d56b789ebf991c9b6de07e2371063.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Burgers",
    items: [
      {
        id: 1,
        name: "Aloo Dum Burger",
        price: 135,
        description: "A large burger with spiced aloo dum patty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/17e/882862b9527017d9f1caea846ed9a17e.jpeg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Large",
      },
      {
        id: 2,
        name: "Cheese Burger",
        price: 115,
        description: "A large burger with a cheese patty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e9c/2c70afa379b9fde3c4ebad498779ae9c.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Large",
      },
      {
        id: 3,
        name: "USP Burger",
        price: 105,
        description: "A large unique special patty burger.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/950/f2de9f0377bdb8007f4c382f78e40950.jpeg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Large",
      },
      {
        id: 4,
        name: "Crunchy Burger",
        price: 105,
        description: "A large burger with a crunchy patty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6b0/0a876b279a733e58b0f780023ecb86b0.png", // Matches "Crispy Burger"
        available: true,
        type: "Veg",
        size: "Large",
      },
      {
        id: 5,
        name: "Paneer Burger",
        price: 100,
        description: "A large burger with a paneer patty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/8ef/116f626b6a631199c913b128ecd0a8ef.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Large",
      },
      {
        id: 6,
        name: "Snacker Burger",
        price: 85,
        description: "A small snacker burger with a veg patty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/9e5/34a1c093dab52aef0cac300a3eaf59e5.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Singh Chotu Burger",
        price: 75,
        description: "A small Singh Chotu burger with a veg patty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b37/11980076aa70e72a33180ae5e6673b37.jpeg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
      {
        id: 8,
        name: "Achari Burger",
        price: 55,
        description: "A burger with a tangy achari-flavored patty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/42d/a6795865cc448caeb6a9c5db7c8bb42d.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
      {
        id: 9,
        name: "Aloo Tikki Burger",
        price: 50,
        description: "A burger with a spiced aloo tikki patty.",
        image:
          "https://b.zmtcdn.com/data/dish_images/7697aceeeedd1b1a6a1fd42e8af1975e1630415083.png", // Matches "Aloo Tikki Burger"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Add Ons",
    items: [
      {
        id: 1,
        name: "Cheese Burst",
        price: 65,
        description: "Cheese burst add-on for regular pizza.",
        image:
          "https://lh3.googleusercontent.com/-x7qL7OUc8Ir3vnaMhxrAvN-8ANqLpInJrCXT7Aerco8G70CMVWm3AbY4i1R5aQuIm6dih_XzEuR97_ghVlY989Jfexa37aRIpWNhKyH0g=w256-rw",
        available: true,
        type: "Veg",
        size: "Regular",
      },
      {
        id: 2,
        name: "Cheese Burst ",
        price: 85,
        description: "Cheese burst add-on for medium pizza.",
        image:
          "https://lh3.googleusercontent.com/-x7qL7OUc8Ir3vnaMhxrAvN-8ANqLpInJrCXT7Aerco8G70CMVWm3AbY4i1R5aQuIm6dih_XzEuR97_ghVlY989Jfexa37aRIpWNhKyH0g=w256-rw",
        available: true,
        type: "Veg",
        size: "Medium",
      },
      {
        id: 3,
        name: "Chipotle Dip",
        price: 20,
        description: "Spicy chipotle dip for pizzas and sides.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/357/193b9d72f8e88ebaf0ab2543e9e4a357.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Smokey Peri Peri",
        price: 20,
        description: "Smokey peri peri sauce for pizzas and sides.",
        image:
          "https://www.vegrecipesofindia.com/wp-content/uploads/2011/09/peri-peri-sauce-recipe-11.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Veg Toppings",
    items: [
      {
        id: 1,
        name: "Onion Topping",
        price: 20,
        description: "Fresh onion topping for pizzas.",
        image:
          "https://b.thumbs.redditmedia.com/_plclbTLaVBkt8SdweAQFfOPQqAojsfRdRssDSNrpBY.png",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Capsicum Topping",
        price: 20,
        description: "Fresh capsicum topping for pizzas.",
        image:
          "https://cdn.tarladalal.com/members/9306/procstepimgs/coloured_capsicums-1-191419.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Tomato Topping",
        price: 20,
        description: "Fresh tomato topping for pizzas.",
        image:
          "https://www.cookingclassy.com/wp-content/uploads/2020/05/pizza-sauce-2.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Golden Corn Topping",
        price: 20,
        description: "Sweet golden corn topping for pizzas.",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStOEiJA0VeCyd-KQS5V_nNAgqZAJRGlFWUhQ&s",
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Black Olives Topping",
        price: 30,
        description: "Black olives topping for pizzas.",
        image:
          "https://cdn7.kiwilimon.com/recetaimagen/15739/640x640/7759.jpg.webp",
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Bell Peppers Topping",
        price: 30,
        description: "Bell peppers topping for pizzas.",
        image:
          "https://cdn7.kiwilimon.com/recetaimagen/15739/640x640/7759.jpg.webp",
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Paprika Topping",
        price: 30,
        description: "Paprika topping for pizzas.",
        image:
          "https://static01.nyt.com/images/2009/05/28/health/28recipehealth_600/28recipehealth_600-jumbo.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 8,
        name: "Jalapeno Topping",
        price: 30,
        description: "Spicy jalapeno topping for pizzas.",
        image:
          "https://www.ggcafe.in/uploads/menuitems_image/20221230191010536545jalapeno-pepper-.png",
        available: true,
        type: "Veg",
      },
      {
        id: 9,
        name: "Grilled Mushroom Topping",
        price: 40,
        description: "Grilled mushroom topping for pizzas.",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrb9L0JYbp_RTGoYPJ-MfOYr99RocILi7XdA&shttps://i0.wp.com/www.craving-nomz.com/wp-content/uploads/2024/01/IMG_8987.jpg?resize=256%2C256&ssl=1",
        available: true,
        type: "Veg",
      },
      {
        id: 10,
        name: "Veg Kebab Topping",
        price: 40,
        description: "Veg kebab topping for pizzas.",
        image:
          "https://kababjeespizza.com/_next/image?url=https%3A%2F%2Fassets.indolj.io%2Fimages%2F1697178211-New%2520arival.webp%3Fq%3D10&w=256&q=75",
        available: true,
        type: "Veg",
      },
      {
        id: 11,
        name: "Shitake Mushroom Topping",
        price: 50,
        description: "Shitake mushroom topping for pizzas.",
        image:
          "https://images.stockcake.com/public/9/5/f/95f59215-f065-4759-9259-e088328bc50c_medium/mushroom-assortment-display-stockcake.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 12,
        name: "Sundried Tomato Topping",
        price: 50,
        description: "Sundried tomato topping for pizzas.",
        image:
          "https://images.stockcake.com/public/d/b/7/db79b023-fd09-46dd-b23d-34dac2469537_medium/delicious-pizza-treat-stockcake.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Extra Cheese",
    items: [
      {
        id: 1,
        name: "Extra Cheese (Regular Pizza)",
        price: 30,
        description: "Extra cheese for regular pizza.",
        image:
          "https://i.ytimg.com/vi/MIyY8iAEvZ8/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD5O5V8chHvOhLfHxySRxdbrp4Rrg",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Extra Cheese (Medium Pizza)",
        price: 40,
        description: "Extra cheese for medium pizza.",
        image:
          "https://i.ytimg.com/vi/MIyY8iAEvZ8/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD5O5V8chHvOhLfHxySRxdbrp4Rrg",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Extra Cheese (Large Pizza)",
        price: 50,
        description: "Extra cheese for large pizza.",
        image:
          "https://i.ytimg.com/vi/MIyY8iAEvZ8/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD5O5V8chHvOhLfHxySRxdbrp4Rrg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Fries",
    items: [
      {
        id: 1,
        name: "Chatpata Fries",
        price: 75,
        description: "Classic salted fries, regular size.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ebc/8dfa61650ff6cb71655962590c8a5ebc.jpeg?fit=around|130:130&crop=130:130;*,*", // Matches "French Fries"
        available: true,
        type: "Veg",
        size: "Regular",
      },
      {
        id: 2,
        name: "Chatpata Fries",
        price: 110,
        description: "Classic salted fries, large size.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ebc/8dfa61650ff6cb71655962590c8a5ebc.jpeg?fit=around|130:130&crop=130:130;*,*", // Matches "French Fries"
        available: true,
        type: "Veg",
        size: "Large",
      },
      {
        id: 11,
        name: "Classic Salt Fries",
        price: 75,
        description: "Classic salted fries, regular size.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ebc/8dfa61650ff6cb71655962590c8a5ebc.jpeg?fit=around|130:130&crop=130:130;*,*", // Matches "French Fries"
        available: true,
        type: "Veg",
        size: "Regular",
      },
      {
        id: 21,
        name: "Classic Salt Fries",
        price: 110,
        description: "Classic salted fries, large size.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ebc/8dfa61650ff6cb71655962590c8a5ebc.jpeg?fit=around|130:130&crop=130:130;*,*", // Matches "French Fries"
        available: true,
        type: "Veg",
        size: "Large",
      },
      {
        id: 1,
        name: "Spicy Fries",
        price: 75,
        description: "Spicy Fries, regular size.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ebc/8dfa61650ff6cb71655962590c8a5ebc.jpeg?fit=around|130:130&crop=130:130;*,*", // Matches "French Fries"
        available: true,
        type: "Veg",
        size: "Regular",
      },
      {
        id: 2,
        name: "Spicy Fries",
        price: 110,
        description: "Spicy Fries, large size.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ebc/8dfa61650ff6cb71655962590c8a5ebc.jpeg?fit=around|130:130&crop=130:130;*,*", // Matches "French Fries"
        available: true,
        type: "Veg",
        size: "Large",
      },
      {
        id: 4,
        name: "Chatpata Cheese Fries",
        price: 99,
        description: "Fries topped with cheese sauce.",
        image:
          "https://files.yappe.in/place/small/ufo-fries-and-corn-jalgaon-3437677.webp", // Matches "French Fries"
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Spicy Cheese Fries",
        price: 99,
        description: "Spicy fries topped with cheese sauce.",
        image:
          "https://images.stockcake.com/public/b/e/4/be49b62f-8848-451f-9ad1-2d2d4c64043e_medium/tasty-fries-feast-stockcake.jpg", // Matches "French Fries"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Maggi",
    items: [
      {
        id: 1,
        name: "Veg Maggi",
        price: 80,
        description: "Classic vegetable Maggi noodles.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/df2/68bcfebe364e06d2a88937b853298df2.jpg", // Matches "Veg. Maggi"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Cheese Maggi",
        price: 120,
        description: "Maggi noodles with cheese.",
        image:
          "https://images.stockcake.com/public/b/e/4/be49b62f-8848-451f-9ad1-2d2d4c64043e_medium/tasty-fries-feast-stockcake.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Pasta",
    items: [
      {
        id: 1,
        name: "White Sauce Pasta",
        price: 120,
        description: "Pasta in a creamy white sauce.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0bd/ee7dd6068f63c554954fdd67631780bd.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Red Sauce Pasta",
        price: 140,
        description: "Pasta in a tangy red tomato sauce.",
        image:
          "https://b.zmtcdn.com/data/dish_images/fe22a5e34f119062278d9799c7b72a9a1614233660.png",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Paneer Makhni Pasta",
        price: 160,
        description: "Pasta with paneer in a makhni sauce.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/9cf8166717d81ec3212d0707a06749f91634401116.png",
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Mixed Sauce Pasta",
        price: 180,
        description: "Pasta in a mixed white and red sauce.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/92e/1fee798342660bd8813132dff99f992e.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Roll",
    items: [
      {
        id: 1,
        name: "Egg Roll",
        price: 100,
        description: "Roll with egg filling.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/5a7b8d60e4b3074fa5d83cd15f11848b1634556326.png",
        available: true,
        type: "Non-Veg",
      },
      {
        id: 2,
        name: "Paneer Roll",
        price: 120,
        description: "Roll with paneer filling.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/ec99015317225170a3ab279ad96a72881634556417.png",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Sides & Starter",
    items: [
      {
        id: 1,
        name: "Pizza Puff",
        price: 70,
        description: "Puff pastry filled with pizza toppings.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/40b/59c2d5c5acbc3981e77837a172d1d40b.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Cheese Garlic Bread",
        price: 120,
        description: "Garlic bread topped with cheese.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/735/c74e28521828e6654a5ecffff1de9735.jpeg?fit=around|130:130&crop=130:130;*,*", // Matches "Garlic Bread With Cheese Dip"
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Garlic Bread",
        price: 100,
        description: "Classic garlic bread.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/735/c74e28521828e6654a5ecffff1de9735.jpeg?fit=around|130:130&crop=130:130;*,*", // Matches "Garlic Bread 4pcs"
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Stuffed Garlic Bread",
        price: 150,
        description: "Garlic bread stuffed with fillings.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5f8/bc436ff3d839df52dc707ccfb96225f8.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Coffee & Sandwiches",
    items: [
      {
        id: 1,
        name: "Cold Coffee",
        price: 80,
        description: "Refreshing cold coffee.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/11b/7d4650ef435a60757ce71d68907f011b.jpg?output-format=webp", // Matches "Cold Coffee"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Choco Lava",
        price: 50,
        description: "Warm chocolate lava cake.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ac1/98de3ae07da6c505c6b486ecaeedbac1.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Veg Sandwich",
        price: 99,
        description: "Sandwich with mixed vegetables.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/fc641efbb73b10484257f295ef0b9b981634401116.png", // Matches "Sandwich"
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Mixed Sandwich",
        price: 110,
        description: "Sandwich with a mix of fillings.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/fc641efbb73b10484257f295ef0b9b981634401116.png", // Matches "Sandwich"
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Corn Sandwich",
        price: 120,
        description: "Sandwich with sweet corn filling.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/245/8c8b24b8ffd35fa3d71c7fdb5bdf5245.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Paneer Sandwich",
        price: 130,
        description: "Sandwich with paneer filling.",
        image:
          "https://b.zmtcdn.com/data/dish_images/d1193f890f5f65d203d46cfcab79a8371630431292.png",
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Cheese Sandwich",
        price: 130,
        description: "Sandwich with cheese filling.",
        image:
          "https://b.zmtcdn.com/data/dish_images/15ee438dd073adc3e60932528db53d081630431335.png",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Shakes",
    items: [
      {
        id: 1,
        name: "Vanilla Shake",
        price: 100,
        description: "Classic vanilla shake.",
        image:
          "https://th.bing.com/th/id/R.712faf6a7b7331fab11b1040beb3a02e?rik=LJ9Tqo%2fXvDLTKQ&riu=http%3a%2f%2fcdn2.simplysated.com%2fwp-content%2fuploads%2f2014%2f08%2fHomemade-Vanilla-Ice-Cream-22-1-P7270022.jpg&ehk=EaKLX2iGEZbE9SXMxKid%2f%2biH9ik7OUbP7pSxJp%2fjFqU%3d&risl=&pid=ImgRaw&r=0", // Matches "Vanilla"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Dark Chocolate Shake",
        price: 110,
        description: "Rich dark chocolate shake.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6a7/6f17fb1e565978e61d45804e5223e6a7.jpg?fit=around|130:130&crop=130:130;*,*", // Matches "Chocolate Shake"
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Butter Scotch Shake",
        price: 110,
        description: "Creamy butterscotch shake.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5d8/f3b8a18e191a35f98ea480831401e5d8.png?fit=around|130:130&crop=130:130;*,*", // Matches "Butter Scotch Shake"
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Strawberry Shake",
        price: 110,
        description: "Fresh strawberry shake.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f49/1ee5efdee9f20dd1dc0ceba8ecd88f49.jpg?fit=around|130:130&crop=130:130;*,*", // Matches "Strawberry Shake"
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Blue Berry Shake",
        price: 120,
        description: "Refreshing blueberry shake.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/cda/7da265718a1692c908080f25a8e69cda.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Black Current Shake",
        price: 120,
        description: "Tangy black currant shake.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e86/858704adb08ce68cf48ffd8858d32e86.png?fit=around|130:130&crop=130:130;*,*", // Matches "Black Current Shake"
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Salted Caramel Shake",
        price: 120,
        description: "Sweet and salty caramel shake.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/fcf/1bb8f56777b6334defda71a4a462afcf.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Meals",
    items: [
      {
        id: 1,
        name: "Aloo Tikki Special",
        price: 99,
        description: "Aloo Tikki Burger + Fries + Coke.",
        image:
          "https://cdn.uengage.io/uploads/6670/image-259698-1644511401.jpeg",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Singh Chotu Special",
        price: 149,
        description: "Singh Chotu Burger + Fries + Coke.",
        image:
          "https://cdn.uengage.io/uploads/6670/image-259698-1644511401.jpeg",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Aloo Dum Special",
        price: 199,
        description: "Aloo Dum Burger + Fries + Coke.",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShHzE1MqvzdWpGTVGSXsahHgk47DSz_vFjLA&s",
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Combo 1",
        price: 199,
        description: "Onion + Corn Pizza + Cold Coffee.",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDFWuv7ZmQMWQZw8RfEJjX2igjSugQX7OuKQ&s",
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Combo 2",
        price: 229,
        description: "Oh! Paneer + Aloo Tikki + Cold Coffee.",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDFWuv7ZmQMWQZw8RfEJjX2igjSugQX7OuKQ&s",
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Combo 3",
        price: 249,
        description: "Stuffed Garlic Bread + Aloo Tikki + Mojito.",
        image:
          "https://b.zmtcdn.com/data/dish_images/c95d79eedec450ba3131108afcb06dbd1613653750.png",
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Combo 4",
        price: 299,
        description: "Paneer Keema + Aloo Tikki + Fries + Coke.",
        image:
          "https://images.stockcake.com/public/a/9/3/a9391834-8b8d-4c29-9850-3d3e2bb7bca9_medium/ultimate-burger-feast-stockcake.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
];

const metaData = {
  name: "Pizza Culture",
  location: "Palwal",
  whatsappNo: "8950928221",
  mapLocation: "https://maps.app.goo.gl/pBQJvQDSutyv9kpPA",
  paymentUpi:
    "upi://pay?pa=8950928221@ibl&pn=Hey%20Pizza&mc=0000&mode=02&purpose=00",
  instagram: "https://www.instagram.com/pizzaculture_palwal",
  reviewLink: "https://reviewthis.biz/purple-breeze-6829",
  qrCodes: [{ code: "00036", table: "Table No - 1" }],
};
export default { menu, metaData };
