import DemoMenu from "../DemoMenu";
import FrontireDhabaMenu from "../FrontireDhabaMenu";
import GrandPrakashMenu from "../GrandPrakashMenu";
import GoldenCrums from "../Palwal/GoldenCrums";
import HariKrishnaDhaba from "../Palwal/HariKrishnaDhaba";
import PizzaCulture from "../Palwal/PizzaCulture";

const AllMenu = [];

AllMenu.push(GrandPrakashMenu);
AllMenu.push(FrontireDhabaMenu);
AllMenu.push(PizzaCulture);
AllMenu.push(GoldenCrums);
AllMenu.push(HariKrishnaDhaba);

function getMenu(id) {
  let QRData = null;
  const foundMenu = AllMenu.find((rest) => {
    QRData = rest.metaData.qrCodes.find((qrCode) => qrCode.code === id);
    if (QRData) return rest;
  });
  if (foundMenu) return { ...foundMenu, ...QRData };
  else return DemoMenu;
}
export { getMenu };

export default AllMenu;
