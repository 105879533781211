let page1 = [
  {
    category: "Snacks",
    items: [
      {
        id: 1,
        name: "Cheese Finger",
        price: 240,
        description: "Crispy cheese-filled fingers, a savory snack.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/dec/d42bc7eac6d601340f5cc63dc6b62dec.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Paneer Pakora",
        price: 180,
        description: "Fried paneer fritters, crispy and spicy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/549/31a8422aaafa815d4d4ff36dfa8fb549.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Mix Pakora",
        price: 160,
        description: "Assorted vegetable fritters, deep-fried and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/71a/d0a93049d4b7a58e58a655d77796771a.jpg",

        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Papad Roll",
        price: 210,
        description: "Rolled papad filled with spices, served crispy.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/64d5639c3885d5b96f1d772c897765571632716606.png",
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Veg. Finger",
        price: 220,
        description: "Vegetable fingers, crispy and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/89e/6baf4d1f75a55ed70422f0352e31689e.jpg",

        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Veg. Cutlet",
        price: 220,
        description: "Vegetable cutlets, fried and spiced.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/034/c6729d995fee786246ce89fd79a92034.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "French Fries",
        price: 170,
        description: "Crispy fried potato sticks, lightly salted.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/a0f/892fabb602a2a5b6512fa07cec266a0f.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 8,
        name: "Butter Toast",
        price: 95,
        description: "Toasted bread with butter, simple and tasty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b41/d8e2d1a79dc56f434a50c947e1ffbb41.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 9,
        name: "Sandwich",
        price: 120,
        description: "Vegetable sandwich, layered with fresh veggies.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/fc641efbb73b10484257f295ef0b9b981634401116.png",
        available: true,
        type: "Veg",
      },
      {
        id: 10,
        name: "Grill Sandwich",
        price: 150,
        description: "Grilled vegetable sandwich, crispy and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/3d3/961df251a980b959cefb2bf55aa223d3.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 11,
        name: "Chana Jor Garam",
        price: 260,
        description: "Spicy roasted chickpeas, a traditional street snack.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b5d/b94e981489962b236f0934a6ce9d0b5d.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 12,
        name: "Paneer Tikka",
        price: 280,
        description: "Grilled paneer cubes marinated in spices.",
        image:
          "https://i0.wp.com/vegecravings.com/wp-content/uploads/2018/04/Paneer-Tikka-Recipe-Step-By-Step-Instructions.jpg?fit=2324%2C1944&quality=30&strip=all&ssl=1",
        available: true,
        type: "Veg",
      },
      {
        id: 13,
        name: "Mushroom Kurmure",
        price: 270,
        description: "Crispy mushroom puffs, lightly spiced.",
        image:
          "https://files.yappe.in/place/small/tales-spirits-viman-nagar-3491159.webp",
        available: true,
        type: "Veg",
      },
      {
        id: 14,
        name: "Mushroom Tikka",
        price: 270,
        description: "Grilled mushrooms marinated with spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f05/1929109c70086dcdb668bd9dcf3e6f05.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 15,
        name: "Paneer Kurmure",
        price: 280,
        description: "Crispy paneer puffs, spiced and savory.",
        image:
          "https://th.bing.com/th/id/OIP.g4bFd0LOrqDZdBToPVT0QwHaFj?rs=1&pid=ImgDetMain",
        available: true,
        type: "Veg",
      },
      {
        id: 16,
        name: "Aloo Kurmure",
        price: 200,
        description: "Crispy potato puffs, lightly spiced.",
        image:
          "https://th.bing.com/th/id/OIP.ypQDKcmWJY2BiZiw6zJhtAHaHn?w=194&h=200&c=7&r=0&o=5&dpr=1.4&pid=1.7",
        available: true,
        type: "Veg",
      },
      {
        id: 17,
        name: "Dahi Kabab",
        price: 280,
        description: "Yogurt-based kababs, soft and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/653/655ad41a4d95eaf6f6e098f93e814653.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 18,
        name: "Mix Kurmure",
        price: 280,
        description: "Assorted vegetable puffs, crispy and spiced.",
        image:
          "https://c.ndtvimg.com/2021-02/v1giqui8_spring-roll_625x300_02_February_21.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Crispy Paneer",
        price: 280,
        description: "Crispy fried paneer, lightly spiced.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b93/e2f539695d89192cdca5336b6ac25b93.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Crispy Mushroom",
        price: 270,
        description: "Crispy fried mushrooms, lightly spiced.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/dcd/f3d50e83913c534c511cd03840899dcd.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Chinese",
    items: [
      {
        id: 20,
        name: "Crispy Corn",
        price: 260,
        description: "Crispy fried corn kernels, seasoned with spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/31a/e53fa1fde70c5ea6cf2fa4db32eaf31a.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 21,
        name: "Chowmein",
        price: 170,
        description: "Stir-fried noodles with vegetables, savory and tangy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/93a/461682ab173f4978d7ed05102119193a.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 22,
        name: "Paneer Manchurian",
        price: 270,
        description: "Fried paneer in a tangy Manchurian sauce.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/423/f6b2da2c8022e95239472806f9607423.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 23,
        name: "Spring Roll",
        price: 170,
        description:
          "Crispy rolls filled with vegetables, fried to perfection.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/4c5/e49939940738ba34174f43a3893504c5.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 24,
        name: "Veg. Manchurian",
        price: 250,
        description: "Fried vegetable balls in a tangy Manchurian sauce.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/6f02b8b0b87cb93ce8a40c5f498121011634556481.png",
        available: true,
        type: "Veg",
      },
      {
        id: 25,
        name: "Chilly Paneer",
        price: 280,
        description: "Spicy stir-fried paneer with bell peppers and onions.",
        image:
          "https://b.zmtcdn.com/data/dish_images/591a8862e48193ef9d89cc0fd5c72b4d1615960310.png",
        available: true,
        type: "Veg",
      },
      {
        id: 26,
        name: "Chilly Mushroom",
        price: 280,
        description: "Spicy stir-fried mushrooms with bell peppers and onions.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c1e/03bfe86ec83ad3b334884f83fce60c1e.png",
        available: true,
        type: "Veg",
      },
      {
        id: 27,
        name: "Chilly Potato",
        price: 190,
        description: "Spicy stir-fried potato strips with bell peppers.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1d3/0afe54ef08dc3a2d88d38c63929d81d3.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 28,
        name: "Honey Chilly Potato",
        price: 200,
        description: "Sweet and spicy potato strips with honey glaze.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ea0/cfa5f6ca2dc3aa6cd4e94b43da0bdea0.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 29,
        name: "Chilly Gobhi",
        price: 240,
        description:
          "Spicy stir-fried cauliflower with bell peppers and onions.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/4fd/6924e2737892504892f1c5d7944c24fd.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 30,
        name: "Diet Chaat",
        price: 250,
        description: "Healthy chaat with low-calorie ingredients and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/503/3fc78c988e4f37c629537d0185d2a503.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "South Indian",
    items: [
      {
        id: 1,
        name: "Masala Dosa",
        price: 170,
        description: "Crispy rice crepe filled with spiced potato filling.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c2e/b74229e07654a3c5ac7489115dd00c2e.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Butter Masala Dosa",
        price: 190,
        description: "Crispy buttery dosa with spiced potato filling.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f0b/2ec9cd58b31795c67b8bf71749194f0b.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Onion Masala Dosa",
        price: 180,
        description: "Crispy dosa with onions and spiced potato filling.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f22/b88dfab722930385ce51192700dfaf22.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Mysore Masala Dosa",
        price: 180,
        description: "Spicy Mysore-style dosa with potato filling.",
        image:
          "https://imenu4u.com/rweb/imfile?fileName=72_8144Banglore%20Plain%20Dosa.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Mysore Paneer Masala",
        price: 190,
        description: "Mysore-style dosa with paneer and potato filling.",
        image:
          "https://img.wongnai.com/p/256x256/2024/12/20/6e1ddf3084b646ac9ef5db90a294148a.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Mysore Butter Dosa",
        price: 190,
        description: "Buttery Mysore-style crispy dosa.",
        image:
          "https://imenu4u.com/rweb/imfile?fileName=72_8150Banglore%20Butter%20Masala%20Dosa.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Paper Dosa",
        price: 150,
        description: "Thin and crispy paper dosa, plain and light.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/062/eaa14e7efd5d27dd50401b47985b0062.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 8,
        name: "Paneer Dosa",
        price: 230,
        description: "Crispy dosa filled with paneer and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/10b/0113b00964b4bd0c408a6293c269210b.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
      {
        id: 9,
        name: "Mix Uttapam",
        price: 200,
        description: "Thick pancake with mixed vegetables, South Indian style.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/dd6/f0dce89db2bd94d97714c488a46eedd6.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 10,
        name: "Paneer Uttapam",
        price: 210,
        description: "Thick pancake with paneer, South Indian style.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f10/6ead54ba122a6ff664255b4bed564f10.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 11,
        name: "Onion Uttapam",
        price: 190,
        description: "Thick pancake with onions, South Indian style.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5fe/0756ae370de7a867281c6a429215f5fe.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 12,
        name: "Onion Tomato Uttapam",
        price: 190,
        description:
          "Thick pancake with onions and tomatoes, South Indian style.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0d3/e66e61f8baf95096276b43f0da5bd0d3.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Vegetable",
    items: [
      {
        id: 1,
        name: "Aloo Gobhi",
        price: 160,
        description: "Potatoes and cauliflower cooked with spices.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/5550f5ac66431cd2b695f86efaebb07d1634556106.png",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Aloo Palak",
        price: 160,
        description: "Potatoes cooked with spinach and spices.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/dc3c6d992ccd501dbc4e2975c0ed9b551634556106.png",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Aloo Matar",
        price: 160,
        description: "Potatoes and peas cooked with spices.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/e33ae9cb5decbc500bd2c0176ca827cf1634556106.png",
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Aloo Jeera",
        price: 160,
        description: "Potatoes tempered with cumin seeds and spices.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/f4aafdfc7e79579c8bccfb58f3415e101634556106.png",
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Aloo Tomato",
        price: 160,
        description: "Potatoes cooked with tomatoes and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/529/29d230f63486a6a8332ebd27103de529.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Dum Aloo",
        price: 220,
        description: "Whole potatoes cooked in a rich, spiced gravy.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/cb10bf27d8c776d85e216020c9b5c5dc1634556326.png",
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Stuffed Dum Aloo",
        price: 240,
        description: "Stuffed potatoes cooked in a spiced gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/3ec/865acd6ad404f8f8482e1d041c3c63ec.png",
        available: true,
        type: "Veg",
      },
      {
        id: 8,
        name: "Baingan Bharta",
        price: 180,
        description: "Roasted eggplant mashed and cooked with spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/bc4/eab83da9200f2f095644ff1246213bc4.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 9,
        name: "Channa Masala",
        price: 190,
        description: "Spiced chickpeas cooked in a tangy gravy.",
        image:
          "https://www.seriouseats.com/thmb/IyNUz5Jz1RWpvGtb1x2U-oToe0c=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__images__2016__03__20160328-channa-masala-recipe-8-2ab6195d55ae4068a8c1d36d7371f5f7.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 10,
        name: "Aloo Tikka Masala",
        price: 220,
        description: "Potato tikka in a creamy tomato-based gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/af5/fd2d09af05a475f79e92ab01b3806af5.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 11,
        name: "Pindi Channa",
        price: 190,
        description: "Spiced chickpeas cooked in a dry, flavorful style.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/026/240a4b75c0f1d8a2cd1efe0742c2f026.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 12,
        name: "Mix Veg.",
        price: 220,
        description: "Assorted vegetables cooked with spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/503/28abc47cf13c584dde2b469005822503.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 13,
        name: "Kadhi Pakora",
        price: 180,
        description: "Yogurt-based curry with fried pakoras.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/275/0902803fdb8982d7e6a67fe7e415b275.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 14,
        name: "Stuff Tomato",
        price: 240,
        description: "Stuffed tomatoes cooked with spices.",
        image:
          "https://nishamadhulika.com/imgpst/featured/stuffed-tomato-curry.png",
        available: true,
        type: "Veg",
      },
      {
        id: 15,
        name: "Gobhi Tikka Masala",
        price: 200,
        description: "Cauliflower tikka in a creamy tomato-based gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/144/8d0f57d0d7483b62de97786276b04144.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 16,
        name: "Matar Mushroom",
        price: 250,
        description: "Peas and mushrooms cooked with spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/bef/4ba204955d1d7156bdba9bc2a1f04bef.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 17,
        name: "Mushroom Curry",
        price: 250,
        description: "Mushrooms cooked in a spiced curry.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/55f/ca79cf0de7a5056ef2c1c3e22568855f.png",
        available: true,
        type: "Veg",
      },
      {
        id: 18,
        name: "Mushroom Masala",
        price: 260,
        description: "Mushrooms in a rich, spiced masala gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/55f/ca79cf0de7a5056ef2c1c3e22568855f.png",
        available: true,
        type: "Veg",
      },
      {
        id: 19,
        name: "Mushroom Keema",
        price: 270,
        description: "Minced mushroom curry, spiced and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b70/d7c8943de5c583e7f3290cffc877bb70.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 20,
        name: "Kadhai Mushroom",
        price: 270,
        description: "Mushrooms cooked in a kadhai with spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d66/d5a69db7e96c7f1e60617e43fe94cd66.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 21,
        name: "Mushroom Do-Pyaza",
        price: 270,
        description: "Mushrooms cooked with lots of onions and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/260/65a2186161f168e0cf4e8d13ce480260.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 22,
        name: "Mushroom Kali Mirch",
        price: 270,
        description: "Mushrooms in a black pepper-based gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/260/65a2186161f168e0cf4e8d13ce480260.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 23,
        name: "Kaju Curry",
        price: 300,
        description: "Cashews cooked in a rich, creamy curry.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/39a/e0642c092b27034762a1b98817a9b39a.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 24,
        name: "Sarson Ka Sag in Butter",
        price: 180,
        description: "Mustard greens cooked with butter and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b45/ba131ebe518f1fda5ca67a739c695b45.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 25,
        name: "Sarson Ka Sag in Desi Ghee",
        price: 210,
        description: "Mustard greens cooked with desi ghee and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b45/ba131ebe518f1fda5ca67a739c695b45.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 26,
        name: "Makki Ki Roti",
        price: 40,
        description: "Cornmeal flatbread, traditional with sarson ka sag.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c8c/ab6c383210da1ee22f747924bbbfbc8c.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 27,
        name: "Rajma Masala",
        price: 180,
        description: "Kidney beans cooked in a spiced tomato gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/cd4/5f773095c8ec0cde83c9636c03a18cd4.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 28,
        name: "Bhindi Masala",
        price: 180,
        description: "Okra cooked with spices and tomatoes.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c3b/00f37147bad1de53e0d33904ac658c3b.png",
        available: true,
        type: "Veg",
      },
      {
        id: 29,
        name: "Gravy",
        price: 150,
        description: "Generic vegetable gravy, rich and flavorful.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/730b22db474bce8c6cb75e3b1218f2b91704371953.png",
        available: true,
        type: "Veg",
      },
      {
        id: 30,
        name: "Bhindi Pyaz",
        price: 180,
        description: "Okra cooked with onions and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/53a/8fd852887b3cb0dd875dc77b3b19453a.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Momos",
    items: [
      {
        id: 31,
        name: "Veg Momos",
        price: 100,
        description: "Steamed vegetable dumplings, served with chutney.",
        image:
          "https://b.zmtcdn.com/data/dish_images/6559282bb2f61a2439b08f49e90936f81614098010.png",
        available: true,
        type: "Veg",
      },
      {
        id: 32,
        name: "Paneer Momos",
        price: 140,
        description: "Steamed paneer-filled dumplings, served with chutney.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e14/38526d0e8811d14e5e9ead61f6dbee14.jpeg",
        available: true,
        type: "Veg",
      },
      {
        id: 33,
        name: "Veg. Fry Momos",
        price: 120,
        description: "Fried vegetable dumplings, crispy and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/8d2/644d0d7b4e3b1de9087962356a2138d2.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 34,
        name: "Paneer Fry Momos",
        price: 160,
        description: "Fried paneer-filled dumplings, crispy and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e62/babb1184d351511d5d843fc59d224e62.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "New Arrivals",
    items: [],
  },
  {
    category: "Paneer",
    items: [
      {
        id: 1,
        name: "Shahi Paneer",
        price: 260,
        description: "Rich paneer in a creamy, royal gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/846/08eaa3712f687cad95bb626815c25846.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Malai Paneer",
        price: 260,
        description: "Creamy paneer in a mild, rich gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_images/e44c42ff4b60b025225c8691ef9735b11635781903.png",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Kadhai Paneer",
        price: 260,
        description: "Paneer cooked in a kadhai with spices and bell peppers.",
        image:
          "https://b.zmtcdn.com/data/dish_images/773ac51db83e23b0e7fdfb74f41952f21602873820.png",
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Dhaba Paneer",
        price: 270,
        description: "Rustic paneer preparation, dhaba-style.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0b6/0732b778bdcc6d3d83b6080aae32f0b6.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Paneer-Do-Pyaza",
        price: 280,
        description: "Paneer cooked with lots of onions and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d74/23870727944c2cfa23890edecdbddd74.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Malai Kofi",
        price: 280,
        description: "Creamy paneer kofta in a rich gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/3bf/ee9bd4017f845e70a1164b4c608043bf.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Paneer Tikka Masala",
        price: 280,
        description: "Grilled paneer tikka in a creamy tomato gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0c3/c467664a64e7c2a5f600e4da9b8170c3.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 8,
        name: "Paneer Bhurji",
        price: 250,
        description: "Scrambled paneer with spices, flavorful and dry.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c71/32eb882c2cdeae077cbcac470fce0c71.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 9,
        name: "Paneer Khanda",
        price: 280,
        description: "Paneer in a spiced onion-based gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_images/773ac51db83e23b0e7fdfb74f41952f21602873820.png",
        available: true,
        type: "Veg",
      },
      {
        id: 10,
        name: "Paneer Ranjita",
        price: 340,
        description: "Spicy paneer preparation, rich and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/63e/37d1de2e8d13b1b986af259fb9f8663e.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 11,
        name: "Paneer Kali Mirch",
        price: 280,
        description: "Paneer in a black pepper-based gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/63e/37d1de2e8d13b1b986af259fb9f8663e.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 12,
        name: "Paneer Butter Masala",
        price: 280,
        description: "Paneer in a creamy buttery tomato gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_images/cbe9c1c456aac583487d1670f05ee8411604140457.jpeg",
        available: "true",
        type: "Veg",
      },
    ],
  },
  {
    category: "Chaap",
    items: [
      {
        id: 1,
        name: "Chaap Curry",
        price: 250,
        description: "Unique vegetable preparation in a chaap-style curry.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/efa0232fa263fd8b97ecf1c009e1b4801632716697.png",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Afghani Chaap",
        price: 270,
        description: "Creamy chaap preparation in Afghani style.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6a6/2504a62907e58ee7631881dea47306a6.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Chilly Chaap",
        price: 240,
        description: "Spicy chaap with bell peppers and onions.",
        image:
          "https://b.zmtcdn.com/data/pictures/7/19010457/7583fb36ae31f75af1bfd61423aab62d_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Soya Hot Wings",
        price: 240,
        description: "Spicy soya wings, crispy and tangy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c95/cf4b11ae99d966d1d2929003f7411c95.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Tandoori Chaap",
        price: 260,
        description: "Tandoor-cooked chaap, smoky and spiced.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/9c9/8cd9cb6041966d833ea2d1e14e4a89c9.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Achari Chaap",
        price: 260,
        description: "Pickle-flavored chaap, tangy and spicy.",
        image:
          "https://b.zmtcdn.com/data/pictures/6/20410476/c143f38fa2de72f9de503ad1abefdbde_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Masala Chaap",
        price: 260,
        description: "Chaap in a spiced masala gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d7d/4d33ae4b5e681ca2a56579b8c0a6ed7d.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 8,
        name: "Baby Doll Chaap",
        price: 240,
        description: "Unique paneer preparation in a special chaap style.",
        image: "https://im.whatshot.in/img/2020/Jul/18250-c-1596104168.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },

  {
    category: "Dal",
    items: [
      {
        id: 1,
        name: "Dahi Fry",
        price: 170,
        description: "Tempered yogurt-based dal, creamy and spicy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c78/4eb01b00d1b42734906312afe2215c78.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Dal Fry",
        price: 170,
        description: "Lentils tempered with spices, flavorful and mild.",
        image:
          "https://b.zmtcdn.com/data/dish_images/d6715e3d7ef71f577f0aa20bfa263f5e1604141101.jpeg",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Dal Butter Fry",
        price: 190,
        description: "Buttery lentils tempered with spices.",
        image:
          "https://b.zmtcdn.com/data/dish_images/d6715e3d7ef71f577f0aa20bfa263f5e1604141101.jpeg",
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Dal Arhar",
        price: 190,
        description: "Arhar dal cooked with spices, light and tasty.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/c5f679b7ce4d7cf0b08f120e77d22e2d1632716660.png",
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Dal Arhar Butter",
        price: 210,
        description: "Buttery arhar dal, rich and flavorful.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/c5f679b7ce4d7cf0b08f120e77d22e2d1632716660.png",
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Dal Makhani",
        price: 220,
        description: "Creamy black lentils cooked with butter and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_images/f42f1bf9f33a25cca20b9fb206f9e96c1602873763.png",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Paranthas",
    items: [
      {
        id: 1,
        name: "Aloo Parantha",
        price: 85,
        description: "Stuffed potato flatbread, served with butter or pickle.",
        image:
          "https://b.zmtcdn.com/data/pictures/4/21129034/955eed08afd2cca800355aa88ca3c5d4_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Onion Parantha",
        price: 85,
        description: "Stuffed onion flatbread, served with butter or pickle.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/2b5a5b533473aada22015966f668e30e1633434990.png",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Aloo Pyaz Parantha",
        price: 95,
        description:
          "Stuffed potato and onion flatbread, flavorful and crispy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/7f4/1f362b3d391bd2f022a9e0d1d551b7f4.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Paneer Parantha",
        price: 95,
        description: "Stuffed paneer flatbread, rich and tasty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/33a/35afee41df6a60bfbfd17ac005e6133a.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Mooli Parantha",
        price: 85,
        description: "Stuffed radish flatbread, spicy and crispy.",
        image:
          "https://b.zmtcdn.com/data/dish_images/cbef36ee48143f45e4b10f1451e649301602873853.png",
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Mix Parantha",
        price: 95,
        description: "Mixed vegetable stuffed flatbread, flavorful and crispy.",
        image:
          "https://b.zmtcdn.com/data/pictures/4/21129034/955eed08afd2cca800355aa88ca3c5d4_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Gobhi Parantha",
        price: 95,
        description: "Stuffed cauliflower flatbread, spicy and crispy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d8e/42bd4f8ec4bebec1c65ecc301e292d8e.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Rice",
    items: [
      {
        id: 1,
        name: "Veg. Biryani",
        price: 250,
        description: "Spiced vegetable biryani, aromatic and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_images/a098f678350380809a464fb5aaf7128a1602873799.png",
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Plain Rice",
        price: 160,
        description: "Steamed plain rice, simple and light.",
        image:
          "https://th.bing.com/th/id/OIP.mpy5jO_n3CticsjQHDFQGwHaHa?rs=1&pid=ImgDetMain",
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Jeera Rice",
        price: 180,
        description: "Rice tempered with cumin seeds, aromatic and light.",
        image:
          "https://th.bing.com/th/id/OIP.ELTYHuwJWM3L7_7x1Wc8cwHaEO?rs=1&pid=ImgDetMain",
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Veg. Pulao",
        price: 220,
        description: "Vegetable pulao, spiced and fragrant.",
        image:
          "https://b.zmtcdn.com/data/dish_images/a098f678350380809a464fb5aaf7128a1602873799.png",
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Fried Rice",
        price: 220,
        description: "Stir-fried rice with vegetables, savory and tangy.",
        image:
          "https://th.bing.com/th/id/OIP.ELTYHuwJWM3L7_7x1Wc8cwHaEO?rs=1&pid=ImgDetMain",
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Paneer Rice",
        price: 240,
        description: "Rice mixed with paneer and spices, rich and flavorful.",
        image:
          "https://th.bing.com/th/id/OIP.g229J7ZBAe3UFxGFkXxiGAHaEK?rs=1&pid=ImgDetMain",
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Rajma Rice",
        price: 240,
        description: "Rice served with rajma curry, hearty and spiced.",
        image:
          "https://th.bing.com/th/id/OIP.gl6kSm2aZz7TQHDk8b8vhwHaE7?w=265&h=180&c=7&r=0&o=5&dpr=1.1&pid=1.7",
        available: true,
        type: "Veg",
      },
      {
        id: 8,
        name: "Kadhi Rice",
        price: 240,
        description: "Rice served with kadhi, creamy and tangy.",
        image:
          "https://th.bing.com/th/id/OIP.GOwH_v7rqc97tfueswYL7wHaEJ?w=326&h=183&c=7&r=0&o=5&dpr=1.1&pid=1.7",
        available: true,
        type: "Veg",
      },
      {
        id: 9,
        name: "Chole Rice",
        price: 240,
        description: "Rice served with chole, spicy and flavorful.",
        image:
          "https://th.bing.com/th/id/OIP.bw23g1DwGbiIpDLblMKXAgHaEK?w=297&h=180&c=7&r=0&o=5&dpr=1.1&pid=1.7",
        available: true,
        type: "Veg",
      },
      {
        id: 10,
        name: "Shahi Paneer Rice",
        price: 240,
        description: "Rice served with shahi paneer, rich and creamy.",
        image:
          "https://i.pinimg.com/originals/5c/02/36/5c023694697973427ec3ec847a599c8e.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
];
const page2 = [
  {
    category: "Special Thali",
    items: [
      {
        id: 1,
        name: "Mix Veg. Dal Makhani, Shahi Paneer, Raita, Salad, Papad, Rice, 1 Sweet, 1 Lacha Parantha",
        price: 340,
        description:
          "A comprehensive vegetarian thali with mixed vegetables, dal makhani, shahi paneer, raita, salad, papad, rice, one sweet, and one lacha parantha.",
        image:
          "https://curlytales.com/wp-content/uploads/2023/11/This-Diwali-Try-Making-This-Diwali-Special-Thali-All-Recipes-Inside-1.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 2,
        name: "Shahi Paneer, Raita, Salad, Rice, 1 Butter Naan / 2 Tandoori Roti",
        price: 230,
        description:
          "A vegetarian thali with shahi paneer, raita, salad, rice, and choice of one butter naan or two tandoori rotis.",
        image:
          "https://curlytales.com/wp-content/uploads/2023/11/This-Diwali-Try-Making-This-Diwali-Special-Thali-All-Recipes-Inside-1.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
    ],
  },
  {
    category: "Mini Thali",
    items: [
      {
        id: 3,
        name: "Dal Makhani, Shahi Paneer, Raita, Salad, Rice, 1 Butter Naan / 2 Tandoori Roti",
        price: 250,
        description:
          "A smaller vegetarian thali with dal makhani, shahi paneer, raita, salad, rice, and choice of one butter naan or two tandoori rotis.",
        image:
          "https://th.bing.com/th/id/OIP.1wen-th1c-0VzJSeoYrG6AHaHa?w=187&h=187&c=7&r=0&o=5&dpr=1.1&pid=1.7",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 4,
        name: "Mix Veg., 2 Tandoori Roti",
        price: 150,
        description:
          "A mini thali with mixed vegetables and two tandoori rotis.",
        image:
          "https://i.pinimg.com/originals/fb/08/07/fb08075331c61a716e2cb9bbd8acadba.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
    ],
  },
  {
    category: "Tandoori Se",
    items: [
      {
        id: 5,
        name: "Plain Roti",
        price: 15,
        description: "Simple tandoori-roasted flatbread, plain and light.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/97f/599579bff3c638bc9c3be31a0ceb797f.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 6,
        name: "Butter Chapati",
        price: 20,
        description:
          "Tandoori-roasted chapati with butter, soft and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0d7/5c42d9a98976152ec3cc5e366e6f80d7.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 7,
        name: "Pyaz Roti Butter",
        price: 30,
        description:
          "Tandoori-roasted onion flatbread with butter, spicy and crispy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f1a/f532c1ce53dad35553d26a1c7dfacf1a.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 8,
        name: "Missi Roti Butter",
        price: 40,
        description:
          "Tandoori-roasted gram flour flatbread with butter, savory and crispy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/11a/41615401cf34854582eac90ccea6011a.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 9,
        name: "Missi Onion",
        price: 50,
        description:
          "Tandoori-roasted onion and gram flour flatbread, spicy and crispy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/11a/41615401cf34854582eac90ccea6011a.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 10,
        name: "Lachedar Prantha",
        price: 50,
        description: "Layered tandoori flatbread, crispy and flaky.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/2b5a5b533473aada22015966f668e30e1633434990.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 11,
        name: "Hari Mirch Prantha",
        price: 50,
        description:
          "Tandoori-roasted green chili flatbread, spicy and flavorful.",
        image:
          "https://b.zmtcdn.com/data/pictures/4/21129034/955eed08afd2cca800355aa88ca3c5d4_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 12,
        name: "Garlic Prantha",
        price: 50,
        description: "Tandoori-roasted garlic flatbread, aromatic and tasty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d4e/c62a165cb8979b100b79156cd69c6d4e.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 13,
        name: "Pudina Prantha",
        price: 50,
        description:
          "Tandoori-roasted mint flatbread, refreshing and flavorful.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/2b5a5b533473aada22015966f668e30e1633434990.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 14,
        name: "Plain Naan",
        price: 45,
        description: "Tandoori-roasted plain naan, soft and light.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/81d/e21ec2a6b5251ff42c6ab240cfbcd81d.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 15,
        name: "Butter Naan",
        price: 55,
        description: "Tandoori-roasted butter naan, rich and soft.",
        image:
          "https://b.zmtcdn.com/data/dish_images/afa3add62f60d39ed4e9bc355a970ee51604140116.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 16,
        name: "Garlic Naan",
        price: 55,
        description: "Tandoori-roasted garlic naan, aromatic and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d4e/c62a165cb8979b100b79156cd69c6d4e.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 17,
        name: "Stuffed Naan",
        price: 85,
        description:
          "Tandoori-roasted naan stuffed with vegetables, rich and tasty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d70/02f550126f543df8c0e75d8a6456ad70.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 18,
        name: "Paneer Naan",
        price: 95,
        description:
          "Tandoori-roasted naan stuffed with paneer, creamy and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d70/02f550126f543df8c0e75d8a6456ad70.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
    ],
  },
  {
    category: "Kulfi",
    items: [
      {
        id: 19,
        name: "Kesar Pista Spl.",
        price: 70,
        description: "Rich saffron and pistachio kulfi, creamy and aromatic.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f7a/3bc03a4179d030c4578f37f1bbcbff7a.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 20,
        name: "Gulkand Spl. Kulfi",
        price: 70,
        description: "Rose-flavored kulfi, sweet and fragrant.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/deb/04da86b3f2f9ae6fe9a87b6d56992deb.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 21,
        name: "Kaju Kulfi",
        price: 80,
        description: "Cashew-flavored kulfi, rich and nutty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e95/e7afc54d8cc50a6215fab0a585cd9e95.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
    ],
  },
  {
    category: "Sweets",
    items: [
      {
        id: 22,
        name: "Gulab Jamun (2pcs)",
        price: 50,
        description:
          "Deep-fried milk solids soaked in sugar syrup, sweet and soft.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/3e8/e0672458da2dd2b9e781375b41cd83e8.jpg?output-format=webp",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 23,
        name: "Gajar Halwa (Winter)",
        price: 90,
        description: "Carrot pudding with ghee and nuts, warm and sweet.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/4ab/dcd7aab9d584c8e40f173156fad8f4ab.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
    ],
  },
  {
    category: "Hot & Cold",
    items: [
      {
        id: 24,
        name: "Milk Tea",
        price: 20,
        description: "Hot milk tea, aromatic and refreshing.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/a2163bedb069e3069db0a88d7a3c848f1676534399.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 25,
        name: "Sp. Tea",
        price: 20,
        description: "Special flavored tea, hot and aromatic.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/a2163bedb069e3069db0a88d7a3c848f1676534399.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 26,
        name: "Kulhad Tea",
        price: 30,
        description: "Traditional clay pot tea, hot and earthy.",
        image:
          "https://b.zmtcdn.com/data/dish_images/23a05f0f021d0b54e4ded1c88a870b3b1628748532.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 27,
        name: "Hot Coffee",
        price: 30,
        description: "Hot coffee, strong and aromatic.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/358/6c8a2865515af7be4bda2fdca0343358.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 28,
        name: "Tomato Soup",
        price: 100,
        description: "Hot tomato soup, tangy and comforting.",
        image:
          "https://b.zmtcdn.com/data/dish_images/84f7e304f4dde7a1c8d84f221396a56c1630430933.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 29,
        name: "Sweet Corn Soup",
        price: 130,
        description: "Hot sweet corn soup, sweet and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_images/18c06e5b27d5635d701c747a068045071630431031.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 30,
        name: "Vegetable Soup",
        price: 130,
        description: "Hot vegetable soup, healthy and flavorful.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/30b182731e0b56e3043afe1a8d6cbd9c1632716548.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 31,
        name: "Fresh Lime Water",
        price: 60,
        description: "Cold fresh lime water, refreshing and tangy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/4ad/21135979a725a3c5030166c5298c94ad.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 32,
        name: "Fresh Lime Soda",
        price: 70,
        description: "Cold fresh lime soda, fizzy and tangy.",
        image:
          "https://th.bing.com/th/id/OIP.glET1vW8y1B6U4tMOgq16wAAAA?w=270&h=184&c=7&r=0&o=5&dpr=1.1&pid=1.7",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 33,
        name: "Shikanji",
        price: 60,
        description: "Cold lemon-based drink, refreshing and sweet.",
        image:
          "https://th.bing.com/th/id/R.8816d7cb9baac93964e474da2ba7b98b?rik=weLmHmo2mgAeig&riu=http%3a%2f%2fwww.ndtv.com%2fcooks%2fimages%2flime-soda-620.jpg&ehk=OwFfq%2fW18dQCwzsVqvwXDNmg2GV%2fbSm6J4w6kdAt9bY%3d&risl=&pid=ImgRaw&r=0",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 34,
        name: "Chaach",
        price: 60,
        description: "Cold buttermilk, tangy and cooling.",
        image:
          "https://th.bing.com/th/id/OIP.XxnfxJiJJ-bZQsevELUoHQHaEV?rs=1&pid=ImgDetMain",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 35,
        name: "Lassi Salted",
        price: 80,
        description: "Cold salted lassi, tangy and refreshing.",
        image:
          "https://easyindiancookbook.com/wp-content/uploads/2023/02/salt-lassi-6-jpg.webp",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 36,
        name: "Lassi Sweet",
        price: 85,
        description: "Cold sweet lassi, creamy and sweet.",
        image:
          "https://www.indianveggiedelight.com/wp-content/uploads/2023/01/sweet-lassi-recipe-featured.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 37,
        name: "Cold Drink",
        price: 60,
        description: "Cold bottled drink, refreshing and fizzy.",
        image:
          "https://www.mashed.com/img/gallery/the-sneaky-reason-grocery-stores-have-cold-drinks-in-the-front/l-intro-1675199881.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 38,
        name: "Mineral Water",
        price: 20,
        description: "Bottled mineral water, pure and safe.",
        image:
          "https://th.bing.com/th/id/OIP.wpJyO-KhCv-q9ggZNdCcFwHaHa?rs=1&pid=ImgDetMain",
        available: true,
        type: "Veg",
        size: null,
      },
    ],
  },
  {
    category: "Tandoori Platters",
    items: [
      {
        id: 40,
        name: "Mushroom Tikka",
        price: 350,
        description: "Tandoori-roasted mushroom tikka, spicy and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f05/1929109c70086dcdb668bd9dcf3e6f05.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 41,
        name: "Paneer Tikka",
        price: 350,
        description: "Tandoori-roasted paneer tikka, spiced and smoky.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/825/16b8ed98c80300704c0251e59fb18825.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
    ],
  },
  {
    category: "Namkeen",
    items: [
      {
        id: 42,
        name: "Aloo Tikki Burger",
        price: 90,
        description: "Potato tikki burger, crispy and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_images/7697aceeeedd1b1a6a1fd42e8af1975e1630415083.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 43,
        name: "Mix Chaap",
        price: 90,
        description: "Mixed vegetable chaap, crispy and spiced.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/2e8/499128dde7ba7d7a7ffc9183a19602e8.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 44,
        name: "Pav Bhaji",
        price: 160,
        description:
          "Mashed vegetable curry with buttered pav, spicy and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5fb/b9aa01a386bbc93b5396dbe3d5c0b5fb.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 45,
        name: "Aloo Tikki",
        price: 90,
        description: "Crispy potato patties, spiced and savory.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/923363053c34a4d2d5ac127ebcf77e161688545030.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 46,
        name: "Crispy Burger",
        price: 120,
        description: "Crispy vegetable burger, savory and tangy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6b0/0a876b279a733e58b0f780023ecb86b0.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 47,
        name: "Dahi Gol Gappe",
        price: 90,
        description:
          "Puffed balls filled with spiced water and yogurt, tangy and crispy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/8ef/7880e02c33e8fa588fdd111a0e7088ef.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 48,
        name: "Extra Pau (2pcs)",
        price: 50,
        description: "Additional buttered pav, soft and flavorful.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/3d8a2994d01ae58b54d2a6ba8a0f45b81632716549.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 49,
        name: "Plain Maggi",
        price: 100,
        description: "Plain noodles, simple and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/8e4/7c03f3fa2f0a85f37455082905db38e4.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 50,
        name: "Veg. Maggi",
        price: 140,
        description: "Vegetable noodles, spicy and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/df2/68bcfebe364e06d2a88937b853298df2.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
    ],
  },
  {
    category: "Shakes",
    items: [
      {
        id: 51,
        name: "Cold Coffee",
        price: 150,
        description: "Cold coffee shake, rich and creamy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/11b/7d4650ef435a60757ce71d68907f011b.jpg?output-format=webp",
        available: true,
        type: "Veg",
        size: "M",
      },
      {
        id: 52,
        name: "Cold Coffee",
        price: 190,
        description: "Cold coffee shake, rich and creamy (larger size).",
        image:
          "https://b.zmtcdn.com/data/dish_photos/11b/7d4650ef435a60757ce71d68907f011b.jpg?output-format=webp",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 53,
        name: "Spl. Traffic Jam",
        price: 80,
        description: "Special mixed fruit shake, fruity and refreshing.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6a7/6f17fb1e565978e61d45804e5223e6a7.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 54,
        name: "Mango Shake",
        price: 80,
        description: "Mango shake, sweet and creamy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1bc/2f27ba2fe81cb23aa0be1f8934eea1bc.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 55,
        name: "Mango Shake",
        price: 100,
        description: "Mango shake, sweet and creamy (larger size).",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1bc/2f27ba2fe81cb23aa0be1f8934eea1bc.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 56,
        name: "Banana Shake",
        price: 80,
        description: "Banana shake, creamy and sweet.",
        image:
          "https://www.allrecipes.com/thmb/NHlDzARQoQZnqQOYpEOkZyjJLYw=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/20804banana-shakeTammyLynn4x3-f07c53dc309143619f2ed3aefdabe4ae.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 57,
        name: "Banana Shake",
        price: 100,
        description: "Banana shake, creamy and sweet (larger size).",
        image:
          "https://www.allrecipes.com/thmb/NHlDzARQoQZnqQOYpEOkZyjJLYw=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/20804banana-shakeTammyLynn4x3-f07c53dc309143619f2ed3aefdabe4ae.jpg",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 58,
        name: "Pineapple Shake",
        price: 80,
        description: "Pineapple shake, tangy and refreshing.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/231/2e1a64040818a9e13f9fdba2b311e231.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 59,
        name: "Pineapple Shake",
        price: 100,
        description: "Pineapple shake, tangy and refreshing (larger size).",
        image:
          "https://b.zmtcdn.com/data/dish_photos/231/2e1a64040818a9e13f9fdba2b311e231.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 60,
        name: "Strawberry Shake",
        price: 80,
        description: "Strawberry shake, sweet and fruity.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f49/1ee5efdee9f20dd1dc0ceba8ecd88f49.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 61,
        name: "Strawberry Shake",
        price: 100,
        description: "Strawberry shake, sweet and fruity (larger size).",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f49/1ee5efdee9f20dd1dc0ceba8ecd88f49.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 62,
        name: "Butter Scotch Shake",
        price: 80,
        description: "Butterscotch-flavored shake, creamy and sweet.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5d8/f3b8a18e191a35f98ea480831401e5d8.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 63,
        name: "Butter Scotch Shake",
        price: 100,
        description:
          "Butterscotch-flavored shake, creamy and sweet (larger size).",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5d8/f3b8a18e191a35f98ea480831401e5d8.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 64,
        name: "Chocolate Shake",
        price: 80,
        description: "Chocolate-flavored shake, rich and creamy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6a7/6f17fb1e565978e61d45804e5223e6a7.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 65,
        name: "Chocolate Shake",
        price: 100,
        description: "Chocolate-flavored shake, rich and creamy (larger size).",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6a7/6f17fb1e565978e61d45804e5223e6a7.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 66,
        name: "Kit-kat Shake",
        price: 150,
        description: "Kit-kat flavored shake, crunchy and creamy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/9e1/165791062f509f83fc08f606b966f9e1.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 67,
        name: "Kit-kat Shake",
        price: 190,
        description:
          "Kit-kat flavored shake, crunchy and creamy (larger size).",
        image:
          "https://b.zmtcdn.com/data/dish_photos/9e1/165791062f509f83fc08f606b966f9e1.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 68,
        name: "Oreo Shake",
        price: 150,
        description: "Oreo cookie-flavored shake, creamy and crunchy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/aff/3bef17587c71e47f8cc7acaf25a03aff.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 69,
        name: "Oreo Shake",
        price: 200,
        description:
          "Oreo cookie-flavored shake, creamy and crunchy (larger size).",
        image:
          "https://b.zmtcdn.com/data/dish_photos/aff/3bef17587c71e47f8cc7acaf25a03aff.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 70,
        name: "Vanilla With Choco Chips",
        price: 150,
        description: "Vanilla shake with chocolate chips, sweet and crunchy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e86/858704adb08ce68cf48ffd8858d32e86.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 71,
        name: "Vanilla With Choco Chips",
        price: 190,
        description:
          "Vanilla shake with chocolate chips, sweet and crunchy (larger size).",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e86/858704adb08ce68cf48ffd8858d32e86.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 72,
        name: "Black Current Shake",
        price: 150,
        description: "Black currant-flavored shake, tangy and sweet.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e86/858704adb08ce68cf48ffd8858d32e86.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 73,
        name: "Black Current Shake",
        price: 190,
        description:
          "Black currant-flavored shake, tangy and sweet (larger size).",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e86/858704adb08ce68cf48ffd8858d32e86.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "L",
      },
    ],
  },
  {
    category: "Mocktails",
    items: [
      {
        id: 74,
        name: "Mint Mojito",
        price: 150,
        description: "Refreshing mint and lime mocktail, cool and tangy.",
        image:
          "https://images.stockcake.com/public/e/e/1/ee1a1099-57a6-4dd3-be59-bd7759393a66_medium/minty-mojito-drink-stockcake.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 75,
        name: "Blue Ocean",
        price: 150,
        description: "Blue-colored fruity mocktail, sweet and refreshing.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ff2/dd77a674ff0a9897b5a79b8c3e522ff2.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 76,
        name: "Virgin Mojito",
        price: 150,
        description: "Non-alcoholic mojito, minty and tangy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/aa0/622d46da2f1839abd360a27ef2304aa0.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
    ],
  },
  {
    category: "Ice Cream",
    items: [
      {
        id: 77,
        name: "Vanilla",
        price: 50,
        description: "Classic vanilla ice cream, creamy and sweet.",
        image:
          "https://th.bing.com/th/id/R.712faf6a7b7331fab11b1040beb3a02e?rik=LJ9Tqo%2fXvDLTKQ&riu=http%3a%2f%2fcdn2.simplysated.com%2fwp-content%2fuploads%2f2014%2f08%2fHomemade-Vanilla-Ice-Cream-22-1-P7270022.jpg&ehk=EaKLX2iGEZbE9SXMxKid%2f%2biH9ik7OUbP7pSxJp%2fjFqU%3d&risl=&pid=ImgRaw&r=0",
        available: true,
        type: "Veg",
        size: "M",
      },
      {
        id: 78,
        name: "Vanilla",
        price: 70,
        description:
          "Classic vanilla ice cream, creamy and sweet (larger size).",
        image:
          "https://th.bing.com/th/id/R.712faf6a7b7331fab11b1040beb3a02e?rik=LJ9Tqo%2fXvDLTKQ&riu=http%3a%2f%2fcdn2.simplysated.com%2fwp-content%2fuploads%2f2014%2f08%2fHomemade-Vanilla-Ice-Cream-22-1-P7270022.jpg&ehk=EaKLX2iGEZbE9SXMxKid%2f%2biH9ik7OUbP7pSxJp%2fjFqU%3d&risl=&pid=ImgRaw&r=0",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 79,
        name: "Strawberry",
        price: 50,
        description: "Strawberry-flavored ice cream, fruity and sweet.",
        image:
          "https://www.biggerbolderbaking.com/wp-content/uploads/2018/05/1C5A7448-682x1024.jpg",
        available: true,
        type: "Veg",
        size: "M",
      },
      {
        id: 80,
        name: "Strawberry",
        price: 70,
        description:
          "Strawberry-flavored ice cream, fruity and sweet (larger size).",
        image:
          "https://www.biggerbolderbaking.com/wp-content/uploads/2018/05/1C5A7448-682x1024.jpg",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 81,
        name: "Chocolate",
        price: 60,
        description: "Chocolate-flavored ice cream, rich and sweet.",
        image:
          "https://th.bing.com/th/id/OIP.wdC2awa-TR-sx-3nqPrhfwHaHa?rs=1&pid=ImgDetMain",
        available: true,
        type: "Veg",
        size: "M",
      },
      {
        id: 82,
        name: "Chocolate",
        price: 90,
        description:
          "Chocolate-flavored ice cream, rich and sweet (larger size).",
        image:
          "https://th.bing.com/th/id/OIP.wdC2awa-TR-sx-3nqPrhfwHaHa?rs=1&pid=ImgDetMain",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 83,
        name: "Black Current",
        price: 60,
        description: "Black currant-flavored ice cream, tangy and sweet.",
        image:
          "https://bakesbybrownsugar.com/wp-content/uploads/2020/07/Black-Currant-Ice-Cream-1-1-scaled.jpg",
        available: true,
        type: "Veg",
        size: "M",
      },
      {
        id: 84,
        name: "Black Current",
        price: 90,
        description:
          "Black currant-flavored ice cream, tangy and sweet (larger size).",
        image:
          "https://bakesbybrownsugar.com/wp-content/uploads/2020/07/Black-Currant-Ice-Cream-1-1-scaled.jpg",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 85,
        name: "Choco Chips",
        price: 60,
        description: "Chocolate chip ice cream, crunchy and sweet.",
        image:
          "https://th.bing.com/th/id/R.cc4e4d2ec62c1b5523950612a61fe254?rik=48twqO0An8Corg&riu=http%3a%2f%2fcdn2.tmbi.com%2fTOH%2fImages%2fPhotos%2f37%2f1200x1200%2fCinnamon-Chocolate-Chip-Ice-Cream_exps29313_CM2043886D08_11_1bC_RMS.jpg&ehk=Py%2bZwEaRdGHAvAEm6OYuZyF43wflfHdmkEycguPfZIY%3d&risl=&pid=ImgRaw&r=0",
        available: true,
        type: "Veg",
        size: "M",
      },
      {
        id: 86,
        name: "Choco Chips",
        price: 90,
        description:
          "Chocolate chip ice cream, crunchy and sweet (larger size).",
        image:
          "https://th.bing.com/th/id/R.cc4e4d2ec62c1b5523950612a61fe254?rik=48twqO0An8Corg&riu=http%3a%2f%2fcdn2.tmbi.com%2fTOH%2fImages%2fPhotos%2f37%2f1200x1200%2fCinnamon-Chocolate-Chip-Ice-Cream_exps29313_CM2043886D08_11_1bC_RMS.jpg&ehk=Py%2bZwEaRdGHAvAEm6OYuZyF43wflfHdmkEycguPfZIY%3d&risl=&pid=ImgRaw&r=0",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 87,
        name: "Butter Scotch",
        price: 60,
        description: "Butterscotch-flavored ice cream, creamy and sweet.",
        image:
          "https://www.indianhealthyrecipes.com/wp-content/uploads/2022/03/butterscotch-ice-cream-recipe.jpg",
        available: true,
        type: "Veg",
        size: "M",
      },
      {
        id: 88,
        name: "Butter Scotch",
        price: 90,
        description:
          "Butterscotch-flavored ice cream, creamy and sweet (larger size).",
        image:
          "https://www.indianhealthyrecipes.com/wp-content/uploads/2022/03/butterscotch-ice-cream-recipe.jpg",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 89,
        name: "Tutti Fruity",
        price: 70,
        description: "Mixed fruit-flavored ice cream, fruity and sweet.",
        image:
          "https://www.vegrecipesofindia.com/wp-content/uploads/2017/05/quick-tutti-frutti-ice-cream-recipe-1.jpg",
        available: true,
        type: "Veg",
        size: "M",
      },
      {
        id: 90,
        name: "Tutti Fruity",
        price: 100,
        description:
          "Mixed fruit-flavored ice cream, fruity and sweet (larger size).",
        image:
          "https://www.vegrecipesofindia.com/wp-content/uploads/2017/05/quick-tutti-frutti-ice-cream-recipe-1.jpg",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 91,
        name: "American Nuts",
        price: 70,
        description: "Nut-flavored ice cream, crunchy and sweet.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/6265f49bb9492ef41614a70fd7e0c0ec1715151848.jpeg",
        available: true,
        type: "Veg",
        size: "M",
      },
      {
        id: 92,
        name: "American Nuts",
        price: 100,
        description: "Nut-flavored ice cream, crunchy and sweet (larger size).",
        image:
          "https://b.zmtcdn.com/data/o2_assets/6265f49bb9492ef41614a70fd7e0c0ec1715151848.jpeg",
        available: true,
        type: "Veg",
        size: "L",
      },
      {
        id: 93,
        name: "Kaju Kishmish",
        price: 70,
        description: "Cashew and raisin-flavored ice cream, rich and sweet.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/edc/9659a789779c4135a775eb36cf737edc.jpg",
        available: true,
        type: "Veg",
        size: "M",
      },
      {
        id: 94,
        name: "Kaju Kishmish",
        price: 100,
        description:
          "Cashew and raisin-flavored ice cream, rich and sweet (larger size).",
        image:
          "https://b.zmtcdn.com/data/dish_photos/edc/9659a789779c4135a775eb36cf737edc.jpg",
        available: true,
        type: "Veg",
        size: "L",
      },
    ],
  },
  {
    category: "Pizza",
    items: [
      {
        id: 95,
        name: 'Paneer Pizza 8"',
        price: 220,
        description: "Vegetarian pizza with paneer topping, cheesy and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6d2/d3dcf25529b780071e99443e0fff16d2.jpeg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: '8"',
      },
      {
        id: 96,
        name: 'Sweet Corn Pizza 8"',
        price: 190,
        description:
          "Vegetarian pizza with sweet corn topping, sweet and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/3e0/a302da0d414837495c1576b64dd943e0.jpeg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: '8"',
      },
      {
        id: 97,
        name: 'Mushroom Pizza 8"',
        price: 190,
        description:
          "Vegetarian pizza with mushroom topping, earthy and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b46/352e0cafc4caef8ef5f4eac224b1db46.jpeg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: '8"',
      },
      {
        id: 98,
        name: 'Onion Tomato Capsicum Pizza 8"',
        price: 220,
        description:
          "Vegetarian pizza with onion, tomato, and capsicum topping, fresh and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/07a/b3a64b833dad54028c395233378f807a.jpeg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: '8"',
      },
      {
        id: 99,
        name: 'Mix Spcl Pizza 8"',
        price: 240,
        description:
          "Vegetarian pizza with mixed vegetable topping, flavorful and cheesy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6cd/88fa68bc5734468f0bb187040fa7d6cd.jpeg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: '8"',
      },
    ],
  },
  {
    category: "Garlic Bread",
    items: [
      {
        id: 100,
        name: "Garlic Bread 4pcs",
        price: 160,
        description: "Garlic-flavored bread, crispy and aromatic.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/735/c74e28521828e6654a5ecffff1de9735.jpeg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "4pcs",
      },
      {
        id: 101,
        name: "Garlic Bread With Cheese Dip",
        price: 180,
        description:
          "Garlic-flavored bread with cheese dip, cheesy and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/735/c74e28521828e6654a5ecffff1de9735.jpeg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: null,
      },
    ],
  },
  {
    category: "Salad",
    items: [
      {
        id: 102,
        name: "Onion Lachha",
        price: 50,
        description: "Thinly sliced onion salad, tangy and fresh.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/4b7/c88740ace4c05ac72661852302ac64b7.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 103,
        name: "Green Salad",
        price: 100,
        description: "Fresh vegetable salad, healthy and crisp.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5e4/4bef8e629460ccef47da6163a58745e4.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 104,
        name: "Mix Raita",
        price: 140,
        description: "Mixed vegetable raita, creamy and tangy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/cdc/1550d88421dc1d56aab0517ad394ccdc.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 105,
        name: "Aloo Raita",
        price: 140,
        description: "Potato raita, creamy and spiced.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/7a3/e9b33ddd2a11b5a025c3a6cc846ba7a3.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 106,
        name: "Boondi Raita",
        price: 140,
        description: "Fried gram flour balls in yogurt, tangy and crispy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/04f/dbf2e1aa3c4e61577798e9f0340d404f.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 107,
        name: "Pineapple Raita",
        price: 160,
        description: "Pineapple in yogurt, sweet and tangy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/864/117bfbc95cdbad25525ee44651e5a864.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 108,
        name: "Papad",
        price: 30,
        description: "Crispy roasted or fried papad, light and savory.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/64d5639c3885d5b96f1d772c897765571632716606.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 109,
        name: "Masala Papad",
        price: 60,
        description: "Spiced papad, crispy and flavorful.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/64d5639c3885d5b96f1d772c897765571632716606.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 110,
        name: "Chana Chat",
        price: 140,
        description: "Spiced chickpea salad, tangy and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5e3/0cd3efa8604faf90b01292de9604d5e3.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 111,
        name: "Fresh Paneer",
        price: 180,
        description: "Fresh paneer salad, creamy and light.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1eb/a52fc94d28e757984295d07b594ec1eb.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 112,
        name: "Peanut Masala",
        price: 150,
        description: "Spiced peanuts, crunchy and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5e3/0cd3efa8604faf90b01292de9604d5e3.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 113,
        name: "Plain Raita",
        price: 150,
        description: "Plain yogurt raita, creamy and mild.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e6c/c8e37f026bb851cb4c7c035562f2de6c.png",
        available: true,
        type: "Veg",
        size: null,
      },
      {
        id: 114,
        name: "Kachumber Salad",
        price: 120,
        description: "Diced cucumber and tomato salad, fresh and tangy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/acd/d74d2444a169a164c60f77f3548a5acd.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
    ],
  },
];

let menu = [...page1, ...page2];
// menu.forEach((category) => {
//   category.items.forEach((item) => {
//     item.image = images[Math.floor(Math.random() * images.length)];
//   });
// });

const metaData = {
  id: "68hjue",
  name: "Grand Prakash Dhaba",
  whatsappNo: "9588353519",
  location: "Palwal",
  mapLocation: "https://maps.app.goo.gl/ff7gJtdZxiJFjYxL6",
  paymentUpi: "",
  instagram: "https://www.instagram.com/grand_prakash_dhaba/",
  reviewLink: "https://reviewthis.biz/icy-dawn-5074",
  qrCodes: [
    { code: "00037", table: "Table No - 1" },
    { code: "00038", table: "Table No - 2" },
  ],
};
export default { metaData, menu };
