import { useParams } from "react-router-dom";
import MenuCard from "./MenuCard";
import { getMenu } from "../../MENULIST/Palwal/MenuMap.js/AllMenu";

const MenuFromID = () => {
  const { id } = useParams();
  console.log(id);
  const menuData = getMenu(id);
  console.log(menuData);
  return (
    <>
      <MenuCard
        metaData={menuData.metaData}
        menu={menuData.menu}
        code={menuData.code}
        table={menuData.table}
      />
    </>
  );
};

export default MenuFromID;
