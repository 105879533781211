let menu = [
  {
    category: "Chaap",
    items: [
      {
        id: 4321,
        name: "Chaap Butter Masala",
        price: 250,
        description:
          "Tender soya chaap cooked in a rich buttery masala gravy with aromatic spices.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/efa0232fa263fd8b97ecf1c009e1b4801632716697.png", // Using "Chaap Curry" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 8765,
        name: "Chaap Do Pyaza",
        price: 240,
        description:
          "Soya chaap sautéed with onions in a tangy do pyaza style, bursting with flavors.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6a6/2504a62907e58ee7631881dea47306a6.jpg", // Using "Afghani Chaap" as a fallback (no exact "Do Pyaza")
        available: true,
        type: "Veg",
      },
      {
        id: 2109,
        name: "Malai Chaap (Chef Spl.)",
        price: 250,
        description:
          "A chef's special soya chaap in a creamy malai gravy, rich with cashew and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6a6/2504a62907e58ee7631881dea47306a6.jpg", // Using "Afghani Chaap" for creamy texture
        available: true,
        type: "Veg",
      },
      {
        id: 6543,
        name: "Chaap Kali Mirch",
        price: 260,
        description:
          "Soya chaap cooked with a bold black pepper (kali mirch) gravy, offering a spicy kick.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/9c9/8cd9cb6041966d833ea2d1e14e4a89c9.jpg", // Using "Tandoori Chaap" as a spiced fallback
        available: true,
        type: "Veg",
      },
      {
        id: 9876,
        name: "Chaap Rangeela",
        price: 250,
        description:
          "A colorful soya chaap dish with a medley of spices and vibrant flavors.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d7d/4d33ae4b5e681ca2a56579b8c0a6ed7d.jpg", // Using "Masala Chaap" for vibrant flavor
        available: true,
        type: "Veg",
      },
      {
        id: 3210,
        name: "Gravy Chaap",
        price: 260,
        description:
          "Soya chaap in a thick, savory gravy, perfect for pairing with naan or rice.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/efa0232fa263fd8b97ecf1c009e1b4801632716697.png", // Using "Chaap Curry"
        available: true,
        type: "Veg",
      },
      {
        id: 6544,
        name: "Afgani Chaap",
        price: 260,
        description:
          "Soya chaap in a creamy afgani-style white gravy with a nutty undertone.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6a6/2504a62907e58ee7631881dea47306a6.jpg", // Exact match "Afghani Chaap"
        available: true,
        type: "Veg",
      },
      {
        id: 9877,
        name: "Chilli Chaap",
        price: 260,
        description:
          "Spicy soya chaap tossed in a Indo-Chinese chilli sauce with bell peppers.",
        image:
          "https://b.zmtcdn.com/data/pictures/7/19010457/7583fb36ae31f75af1bfd61423aab62d_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A", // Exact match "Chilly Chaap"
        available: true,
        type: "Veg",
      },
      {
        id: 3211,
        name: "Tandoori Chaap",
        price: 260,
        description:
          "Soya chaap marinated with tandoori spices and grilled to smoky perfection.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/9c9/8cd9cb6041966d833ea2d1e14e4a89c9.jpg", // Exact match "Tandoori Chaap"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Main Course Paneer",
    items: [
      {
        id: 4322,
        name: "Matar Paneer",
        price: 220,
        description:
          "Soft paneer cubes and green peas in a mildly spiced tomato-based gravy.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/e33ae9cb5decbc500bd2c0176ca827cf1634556106.png", // Exact match "Aloo Matar" as closest for peas
        available: true,
        type: "Veg",
      },
      {
        id: 8766,
        name: "Palak Paneer",
        price: 210,
        description:
          "Paneer cubes in a creamy spinach gravy, packed with iron and flavor.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/dc3c6d992ccd501dbc4e2975c0ed9b551634556106.png", // Exact match "Aloo Palak" as closest for spinach
        available: true,
        type: "Veg",
      },
      {
        id: 2110,
        name: "Shahi Paneer",
        price: 260,
        description:
          "A royal dish of paneer in a rich, creamy cashew and tomato gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/846/08eaa3712f687cad95bb626815c25846.jpg", // Exact match "Shahi Paneer"
        available: true,
        type: "Veg",
      },
      {
        id: 6545,
        name: "Khoya Paneer",
        price: 260,
        description:
          "Paneer cooked with khoya in a sweet and savory gravy, rich and indulgent.",
        image:
          "https://b.zmtcdn.com/data/dish_images/e44c42ff4b60b025225c8691ef9735b11635781903.png", // Using "Malai Paneer" as creamy fallback
        available: true,
        type: "Veg",
      },
      {
        id: 9878,
        name: "Paneer Tikka Masala",
        price: 260,
        description:
          "Grilled paneer tikka in a spicy, tangy masala gravy with a smoky flavor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0c3/c467664a64e7c2a5f600e4da9b8170c3.jpg", // Exact match "Paneer Tikka Masala"
        available: true,
        type: "Veg",
      },
      {
        id: 3212,
        name: "Kadai Paneer",
        price: 270,
        description:
          "Paneer stir-fried with bell peppers and onions in a kadai-spiced tomato gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_images/773ac51db83e23b0e7fdfb74f41952f21602873820.png", // Exact match "Kadhai Paneer"
        available: true,
        type: "Veg",
      },
      {
        id: 6546,
        name: "Handi Paneer",
        price: 260,
        description:
          "Paneer cooked in a traditional handi with aromatic spices and a thick gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0b6/0732b778bdcc6d3d83b6080aae32f0b6.jpg", // Using "Dhaba Paneer" as rustic fallback
        available: true,
        type: "Veg",
      },
      {
        id: 9879,
        name: "Paneer Kashmiri",
        price: 250,
        description:
          "Paneer in a Kashmiri-style gravy with dry fruits and a mild, sweet flavor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/846/08eaa3712f687cad95bb626815c25846.jpg", // Using "Shahi Paneer" for rich, nutty vibe
        available: true,
        type: "Veg",
      },
      {
        id: 3213,
        name: "White Paneer",
        price: 260,
        description:
          "Paneer in a creamy white gravy made with cashew and mild spices.",
        image:
          "https://b.zmtcdn.com/data/dish_images/e44c42ff4b60b025225c8691ef9735b11635781903.png", // Using "Malai Paneer" for creamy white gravy
        available: true,
        type: "Veg",
      },
      {
        id: 4323,
        name: "Cream Paneer",
        price: 250,
        description:
          "Paneer in a rich, creamy gravy with a smooth and velvety texture.",
        image:
          "https://b.zmtcdn.com/data/dish_images/e44c42ff4b60b025225c8691ef9735b11635781903.png", // Using "Malai Paneer" for creamy texture
        available: true,
        type: "Veg",
      },
      {
        id: 8767,
        name: "Haryali Paneer",
        price: 250,
        description:
          "Paneer in a green haryali gravy made with spinach, coriander, and mint.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/dc3c6d992ccd501dbc4e2975c0ed9b551634556106.png", // Using "Aloo Palak" for green gravy
        available: true,
        type: "Veg",
      },
      {
        id: 2111,
        name: "Panchranga Paneer",
        price: 260,
        description:
          "A vibrant paneer dish with a mix of five spices, offering a burst of flavors.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0c3/c467664a64e7c2a5f600e4da9b8170c3.jpg", // Using "Paneer Tikka Masala" for vibrant spices
        available: true,
        type: "Veg",
      },
      {
        id: 6547,
        name: "Muglai Paneer",
        price: 260,
        description:
          "Paneer in a Mughlai-style gravy with a rich blend of nuts and aromatic spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/846/08eaa3712f687cad95bb626815c25846.jpg", // Using "Shahi Paneer" for rich Mughlai style
        available: true,
        type: "Veg",
      },
      {
        id: 9880,
        name: "Laccha Paneer",
        price: 260,
        description:
          "Paneer in a laccha-style gravy with layers of spices and a creamy finish.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/63e/37d1de2e8d13b1b986af259fb9f8663e.jpg", // Using "Paneer Ranjita" as a rich fallback
        available: true,
        type: "Veg",
      },
      {
        id: 3214,
        name: "Afgani Paneer",
        price: 260,
        description:
          "Paneer in a creamy afgani-style white gravy with a nutty and mild flavor.",
        image:
          "https://b.zmtcdn.com/data/dish_images/e44c42ff4b60b025225c8691ef9735b11635781903.png", // Using "Malai Paneer" for creamy white gravy
        available: true,
        type: "Veg",
      },
      {
        id: 6548,
        name: "Stuffed Paneer",
        price: 260,
        description:
          "Paneer stuffed with nuts and spices, cooked in a rich tomato-based gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0c3/c467664a64e7c2a5f600e4da9b8170c3.jpg", // Using "Paneer Tikka Masala" as a rich fallback
        available: true,
        type: "Veg",
      },
      {
        id: 9881,
        name: "Tawa Paneer Masala",
        price: 260,
        description:
          "Paneer cooked on a tawa with a spicy masala blend, offering a rustic taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0c3/c467664a64e7c2a5f600e4da9b8170c3.jpg", // Using "Paneer Tikka Masala" for masala flavor
        available: true,
        type: "Veg",
      },
      {
        id: 3215,
        name: "Paneer Butter Masala",
        price: 270,
        description:
          "Paneer in a creamy butter masala gravy, rich with tomatoes and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_images/cbe9c1c456aac583487d1670f05ee8411604140457.jpeg", // Exact match "Paneer Butter Masala"
        available: true,
        type: "Veg",
      },
      {
        id: 4324,
        name: "Paneer Lababdar Masala (Chef Spl.)",
        price: 250,
        description:
          "A chef's special paneer in a creamy lababdar gravy with a hint of sweetness.",
        image:
          "https://b.zmtcdn.com/data/dish_images/e44c42ff4b60b025225c8691ef9735b11635781903.png", // Using "Malai Paneer" for creamy texture
        available: true,
        type: "Veg",
      },
      {
        id: 8768,
        name: "Paneer Rangeela",
        price: 270,
        description:
          "A colorful paneer dish with a mix of spices, offering a vibrant and flavorful experience.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0c3/c467664a64e7c2a5f600e4da9b8170c3.jpg", // Using "Paneer Tikka Masala" for vibrant spices
        available: true,
        type: "Veg",
      },
      {
        id: 2112,
        name: "Paneer Pasanda",
        price: 250,
        description:
          "Paneer in a pasanda-style gravy with a rich, nutty flavor and mild spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/846/08eaa3712f687cad95bb626815c25846.jpg", // Using "Shahi Paneer" for rich, nutty flavor
        available: true,
        type: "Veg",
      },
      {
        id: 6549,
        name: "Paneer Do Pyaza",
        price: 260,
        description:
          "Paneer cooked with onions in a do pyaza style, offering a tangy and spicy taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d74/23870727944c2cfa23890edecdbddd74.jpg", // Exact match "Paneer-Do-Pyaza"
        available: true,
        type: "Veg",
      },
      {
        id: 9882,
        name: "Paneer Kali Mirch",
        price: 260,
        description:
          "Paneer in a black pepper (kali mirch) gravy, delivering a bold and spicy flavor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/63e/37d1de2e8d13b1b986af259fb9f8663e.jpg", // Exact match "Paneer Kali Mirch"
        available: true,
        type: "Veg",
      },
      {
        id: 3216,
        name: "Paneer Bhurji",
        price: 299,
        description:
          "Scrambled paneer cooked with onions, tomatoes, and spices for a hearty dish.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c71/32eb882c2cdeae077cbcac470fce0c71.jpg", // Exact match "Paneer Bhurji"
        available: true,
        type: "Veg",
      },
      {
        id: 6550,
        name: "Paneer Makhani",
        price: 260,
        description:
          "Paneer in a creamy makhani gravy with butter, tomatoes, and aromatic spices.",
        image:
          "https://b.zmtcdn.com/data/dish_images/cbe9c1c456aac583487d1670f05ee8411604140457.jpeg", // Using "Paneer Butter Masala" as makhani fallback
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Mushroom",
    items: [
      {
        id: 4325,
        name: "Mushroom Masala (Chef Spl.)",
        price: 240,
        description:
          "A chef's special mushroom dish in a spicy masala gravy with a rich taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/55f/ca79cf0de7a5056ef2c1c3e22568855f.png", // Exact match "Mushroom Masala"
        available: true,
        type: "Veg",
      },
      {
        id: 8769,
        name: "Kadhai Mushroom",
        price: 250,
        description:
          "Mushrooms stir-fried with bell peppers in a kadhai-spiced tomato gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d66/d5a69db7e96c7f1e60617e43fe94cd66.jpg", // Exact match "Kadhai Mushroom"
        available: true,
        type: "Veg",
      },
      {
        id: 2113,
        name: "Mushroom Tikka Masala",
        price: 250,
        description:
          "Grilled mushrooms in a spicy tikka masala gravy with a smoky flavor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f05/1929109c70086dcdb668bd9dcf3e6f05.jpg", // Using "Mushroom Tikka" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 6551,
        name: "Matar Mushroom",
        price: 240,
        description:
          "Mushrooms and green peas cooked in a flavorful tomato-based gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/bef/4ba204955d1d7156bdba9bc2a1f04bef.jpg", // Exact match "Matar Mushroom"
        available: true,
        type: "Veg",
      },
      {
        id: 9883,
        name: "Mushroom Duplex",
        price: 270,
        description:
          "A unique mushroom dish with a double-layered gravy, rich and aromatic.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/55f/ca79cf0de7a5056ef2c1c3e22568855f.png", // Using "Mushroom Masala" as rich fallback
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Vegetable",
    items: [
      {
        id: 4326,
        name: "Aloo Gobhi",
        price: 170,
        description:
          "Potatoes and cauliflower stir-fried with spices for a classic Indian dish.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/5550f5ac66431cd2b695f86efaebb07d1634556106.png", // Exact match "Aloo Gobhi"
        available: true,
        type: "Veg",
      },
      {
        id: 8770,
        name: "Aloo Matar",
        price: 160,
        description:
          "Potatoes and green peas in a mildly spiced tomato-based gravy.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/e33ae9cb5decbc500bd2c0176ca827cf1634556106.png", // Exact match "Aloo Matar"
        available: true,
        type: "Veg",
      },
      {
        id: 2114,
        name: "Aloo Palak",
        price: 160,
        description:
          "Potatoes cooked in a creamy spinach gravy, packed with nutrients.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/dc3c6d992ccd501dbc4e2975c0ed9b551634556106.png", // Exact match "Aloo Palak"
        available: true,
        type: "Veg",
      },
      {
        id: 6552,
        name: "Aloo Jeera",
        price: 160,
        description:
          "Potatoes tempered with cumin (jeera) and spices, offering a simple yet flavorful taste.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/f4aafdfc7e79579c8bccfb58f3415e101634556106.png", // Exact match "Aloo Jeera"
        available: true,
        type: "Veg",
      },
      {
        id: 9884,
        name: "Aloo Tikka Masala",
        price: 220,
        description:
          "Potatoes in a spicy tikka masala gravy, rich with tomatoes and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/af5/fd2d09af05a475f79e92ab01b3806af5.jpg", // Exact match "Aloo Tikka Masala"
        available: true,
        type: "Veg",
      },
      {
        id: 3217,
        name: "Aloo Do Pyaza",
        price: 220,
        description:
          "Potatoes cooked with onions in a do pyaza style, offering a tangy taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/529/29d230f63486a6a8332ebd27103de529.jpg", // Using "Aloo Tomato" as a tangy fallback
        available: true,
        type: "Veg",
      },
      {
        id: 6553,
        name: "Dum Aloo",
        price: 250,
        description:
          "Baby potatoes slow-cooked in a rich, spiced gravy for a melt-in-the-mouth texture.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/cb10bf27d8c776d85e216020c9b5c5dc1634556326.png", // Exact match "Dum Aloo"
        available: true,
        type: "Veg",
      },
      {
        id: 9885,
        name: "Kashmiri Dum Aloo",
        price: 250,
        description:
          "Baby potatoes in a Kashmiri-style gravy with dry fruits and mild spices.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/cb10bf27d8c776d85e216020c9b5c5dc1634556326.png", // Using "Dum Aloo" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 3218,
        name: "Kadi Pakoda",
        price: 160,
        description:
          "Gram flour fritters in a tangy yogurt-based curry, a comforting Punjabi dish.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/275/0902803fdb8982d7e6a67fe7e415b275.jpg", // Exact match "Kadhi Pakora"
        available: true,
        type: "Veg",
      },
      {
        id: 4327,
        name: "Gobhi Masala",
        price: 200,
        description:
          "Cauliflower cooked in a spicy masala gravy, offering a robust flavor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/144/8d0f57d0d7483b62de97786276b04144.jpg", // Using "Gobhi Tikka Masala" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 8771,
        name: "Aloo Shimla",
        price: 180,
        description:
          "Potatoes and capsicum stir-fried with spices for a simple, flavorful dish.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ea0/cfa5f6ca2dc3aa6cd4e94b43da0bdea0.jpg", // Using "Honey Chilly Potato" as a veggie stir-fry fallback
        available: true,
        type: "Veg",
      },
      {
        id: 2115,
        name: "Gobhi Tikka Masala",
        price: 200,
        description:
          "Cauliflower florets in a spicy tikka masala gravy with a smoky taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/144/8d0f57d0d7483b62de97786276b04144.jpg", // Exact match "Gobhi Tikka Masala"
        available: true,
        type: "Veg",
      },
      {
        id: 6554,
        name: "Kadhai Gobhi",
        price: 230,
        description:
          "Cauliflower stir-fried with bell peppers in a kadhai-spiced tomato gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/144/8d0f57d0d7483b62de97786276b04144.jpg", // Using "Gobhi Tikka Masala" as kadhai fallback
        available: true,
        type: "Veg",
      },
      {
        id: 9886,
        name: "Dahi Fry",
        price: 180,
        description:
          "A creamy yogurt-based dish with spices, offering a tangy and comforting taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c78/4eb01b00d1b42734906312afe2215c78.jpg", // Exact match "Dahi Fry"
        available: true,
        type: "Veg",
      },
      {
        id: 3219,
        name: "Malai Kofta",
        price: 250,
        description:
          "Soft koftas in a creamy malai gravy, rich with cashew and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/3bf/ee9bd4017f845e70a1164b4c608043bf.jpg", // Exact match "Malai Kofi"
        available: true,
        type: "Veg",
      },
      {
        id: 6555,
        name: "Veg. Kolhapuri",
        price: 250,
        description:
          "A spicy Kolhapuri-style vegetable curry with a bold and fiery flavor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/503/28abc47cf13c584dde2b469005822503.jpg", // Using "Mix Veg." as a spicy veggie fallback
        available: true,
        type: "Veg",
      },
      {
        id: 9887,
        name: "Mix Veg.",
        price: 220,
        description:
          "A medley of seasonal vegetables cooked in a mildly spiced gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/503/28abc47cf13c584dde2b469005822503.jpg", // Exact match "Mix Veg."
        available: true,
        type: "Veg",
      },
      {
        id: 3220,
        name: "Kaju Curry",
        price: 299,
        description:
          "A rich curry with cashews in a creamy gravy, offering a nutty flavor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/39a/e0642c092b27034762a1b98817a9b39a.jpg", // Exact match "Kaju Curry"
        available: true,
        type: "Veg",
      },
      {
        id: 4328,
        name: "Cheese Tomato",
        price: 240,
        description:
          "A tangy tomato-based dish with cheese, offering a creamy and savory taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/529/29d230f63486a6a8332ebd27103de529.jpg", // Using "Aloo Tomato" as a tomato-based fallback
        available: true,
        type: "Veg",
      },
      {
        id: 8772,
        name: "Chana Masala",
        price: 200,
        description:
          "Chickpeas cooked in a spicy chana masala gravy, a North Indian classic.",
        image:
          "https://www.seriouseats.com/thmb/IyNUz5Jz1RWpvGtb1x2U-oToe0c=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__images__2016__03__20160328-channa-masala-recipe-8-2ab6195d55ae4068a8c1d36d7371f5f7.jpg", // Exact match "Channa Masala"
        available: true,
        type: "Veg",
      },
      {
        id: 2116,
        name: "Kadai Choley Punjabi",
        price: 240,
        description:
          "Chickpeas cooked in a kadai with Punjabi spices, offering a robust flavor.",
        image:
          "https://www.seriouseats.com/thmb/IyNUz5Jz1RWpvGtb1x2U-oToe0c=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__images__2016__03__20160328-channa-masala-recipe-8-2ab6195d55ae4068a8c1d36d7371f5f7.jpg", // Using "Channa Masala" as kadai fallback
        available: true,
        type: "Veg",
      },
      {
        id: 6556,
        name: "Kaju Kofta",
        price: 310,
        description:
          "Cashew-based koftas in a rich, creamy gravy, offering a luxurious taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/39a/e0642c092b27034762a1b98817a9b39a.jpg", // Using "Kaju Curry" for creamy nutty flavor
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Dal",
    items: [
      {
        id: 4329,
        name: "Yellow Dal Tadka",
        price: 170,
        description:
          "Yellow lentils tempered with a tadka of ghee, cumin, and spices for a comforting taste.",
        image:
          "https://b.zmtcdn.com/data/dish_images/d6715e3d7ef71f577f0aa20bfa263f5e1604141101.jpeg", // Using "Dal Fry" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 8773,
        name: "Dal Makhani",
        price: 190,
        description:
          "Black lentils slow-cooked with butter and cream for a rich, creamy texture.",
        image:
          "https://b.zmtcdn.com/data/dish_images/f42f1bf9f33a25cca20b9fb206f9e96c1602873763.png", // Exact match "Dal Makhani"
        available: true,
        type: "Veg",
      },
      {
        id: 2117,
        name: "Dal Handi",
        price: 190,
        description:
          "Lentils cooked in a traditional handi with aromatic spices, offering a rustic flavor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d61/483583bdef15ff699be502e9dd2cdd61.jpg", // Using "Dal Makhani" as rustic fallback
        available: true,
        type: "Veg",
      },
      {
        id: 6557,
        name: "Dal Fry",
        price: 160,
        description:
          "Yellow lentils fried with onions, tomatoes, and spices for a simple, hearty dish.",
        image:
          "https://b.zmtcdn.com/data/dish_images/d6715e3d7ef71f577f0aa20bfa263f5e1604141101.jpeg", // Exact match "Dal Fry"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Basmati Rice ka Khajana",
    items: [
      {
        id: 4330,
        name: "Veg. Pulao",
        price: 220,
        description:
          "Fragrant basmati rice cooked with mixed vegetables and mild spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/526/b01535ab982ccfc533143de40664f526.jpg", // Exact match "Veg. Pulao"
        available: true,
        type: "Veg",
      },
      {
        id: 8774,
        name: "Matar Pulao",
        price: 230,
        description:
          "Basmati rice cooked with green peas and subtle spices for a light, flavorful dish.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/740/e7c80af055946a5d2d737fb8fb692740.png", // Using "Veg. Pulao" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 2118,
        name: "Veg. Biryani",
        price: 250,
        description:
          "Aromatic basmati rice layered with mixed vegetables and biryani spices.",
        image:
          "https://b.zmtcdn.com/data/dish_images/a098f678350380809a464fb5aaf7128a1602873799.png", // Exact match "Veg. Biryani"
        available: true,
        type: "Veg",
      },
      {
        id: 6558,
        name: "Veg. Fried Rice",
        price: 200,
        description:
          "Stir-fried basmati rice with mixed vegetables in an Indo-Chinese style.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/fd0/07378ce6e5bc14342b35ee1c3f590fd0.jpg?output-format=webp", // Exact match "Fried Rice"
        available: true,
        type: "Veg",
      },
      {
        id: 9888,
        name: "Jeera Rice",
        price: 170,
        description:
          "Basmati rice tempered with cumin seeds for a simple, aromatic side dish.",
        image:
          "https://th.bing.com/th/id/OIP.ELTYHuwJWM3L7_7x1Wc8cwHaEO?rs=1&pid=ImgDetMain", // Exact match "Jeera Rice"
        available: true,
        type: "Veg",
      },
      {
        id: 3221,
        name: "Curd Rice",
        price: 180,
        description:
          "Basmati rice mixed with creamy curd and tempered with mild spices.",
        image:
          "https://th.bing.com/th/id/OIP.mpy5jO_n3CticsjQHDFQGwHaHa?rs=1&pid=ImgDetMain", // Using "Plain Rice" as a simple base
        available: true,
        type: "Veg",
      },
      {
        id: 6559,
        name: "Steamed Rice",
        price: 170,
        description:
          "Plain basmati rice, steamed to perfection, perfect as a side dish.",
        image:
          "https://th.bing.com/th/id/OIP.mpy5jO_n3CticsjQHDFQGwHaHa?rs=1&pid=ImgDetMain", // Using "Plain Rice" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 9889,
        name: "Plain Rice",
        price: 160,
        description:
          "Simple basmati rice, cooked to a fluffy texture, ideal for pairing with gravies.",
        image:
          "https://th.bing.com/th/id/OIP.mpy5jO_n3CticsjQHDFQGwHaHa?rs=1&pid=ImgDetMain", // Exact match "Plain Rice"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Raita",
    items: [
      {
        id: 4331,
        name: "Boondi Raita",
        price: 120,
        description:
          "Creamy yogurt mixed with crispy boondi and mild spices for a refreshing side.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/04f/dbf2e1aa3c4e61577798e9f0340d404f.jpg", // Exact match "Boondi Raita"
        available: true,
        type: "Veg",
      },
      {
        id: 8775,
        name: "Pudina Raita",
        price: 120,
        description:
          "Yogurt infused with fresh mint (pudina), offering a cool and refreshing taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e6c/c8e37f026bb851cb4c7c035562f2de6c.png", // Using "Plain Raita" as a base
        available: true,
        type: "Veg",
      },
      {
        id: 2119,
        name: "Mix Raita",
        price: 130,
        description:
          "A mix of vegetables in creamy yogurt, seasoned with mild spices.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/cdc/1550d88421dc1d56aab0517ad394ccdc.jpg", // Exact match "Mix Raita"
        available: true,
        type: "Veg",
      },
      {
        id: 6560,
        name: "Aloo Raita",
        price: 120,
        description:
          "Yogurt with boiled potatoes, seasoned with spices for a hearty side.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/7a3/e9b33ddd2a11b5a025c3a6cc846ba7a3.jpg", // Exact match "Aloo Raita"
        available: true,
        type: "Veg",
      },
      {
        id: 9890,
        name: "Fruit Raita",
        price: 120,
        description:
          "Yogurt mixed with fresh fruits, offering a sweet and refreshing twist.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/864/117bfbc95cdbad25525ee44651e5a864.jpg", // Using "Pineapple Raita" as a fruity fallback
        available: true,
        type: "Veg",
      },
      {
        id: 3222,
        name: "Pineapple Raita",
        price: 140,
        description:
          "Yogurt with juicy pineapple chunks, a sweet and tangy side dish.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/864/117bfbc95cdbad25525ee44651e5a864.jpg", // Exact match "Pineapple Raita"
        available: true,
        type: "Veg",
      },
      {
        id: 6561,
        name: "Dahi Raita",
        price: 140,
        description:
          "Plain yogurt seasoned with mild spices, a simple and cooling side.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e6c/c8e37f026bb851cb4c7c035562f2de6c.png", // Using "Plain Raita" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 9891,
        name: "Jeera Chach",
        price: 80,
        description:
          "A refreshing buttermilk drink flavored with roasted cumin (jeera).",
        image:
          "https://th.bing.com/th/id/OIP.XxnfxJiJJ-bZQsevELUoHQHaEV?rs=1&pid=ImgDetMain", // Using "Chaach" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 3223,
        name: "Lassi",
        price: 70,
        description:
          "A traditional yogurt-based drink, sweet and creamy, perfect for cooling down.",
        image:
          "https://www.indianveggiedelight.com/wp-content/uploads/2023/01/sweet-lassi-recipe-featured.jpg", // Exact match "Lassi Sweet"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Snacks",
    items: [
      {
        id: 4332,
        name: "Dahi Kabab",
        price: 270,
        description:
          "Crispy kababs made with hung curd and spices, offering a tangy and savory bite.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/653/655ad41a4d95eaf6f6e098f93e814653.jpg", // Exact match "Dahi Kabab"
        available: true,
        type: "Veg",
      },
      {
        id: 8776,
        name: "Paneer Tikka",
        price: 260,
        description:
          "Marinated paneer cubes grilled with spices, offering a smoky tandoori flavor.",
        image:
          "https://i0.wp.com/vegecravings.com/wp-content/uploads/2018/04/Paneer-Tikka-Recipe-Step-By-Step-Instructions.jpg?fit=2324%2C1944&quality=30&strip=all&ssl=1", // Exact match "Paneer Tikka"
        available: true,
        type: "Veg",
      },
      {
        id: 2120,
        name: "Chilly Paneer",
        price: 260,
        description:
          "Paneer tossed in a spicy Indo-Chinese chilly sauce with bell peppers.",
        image:
          "https://b.zmtcdn.com/data/dish_images/591a8862e48193ef9d89cc0fd5c72b4d1615960310.png", // Exact match "Chilly Paneer"
        available: true,
        type: "Veg",
      },
      {
        id: 6562,
        name: "Paneer Manchurian",
        price: 250,
        description:
          "Paneer in a thick Indo-Chinese manchurian sauce with veggies, sweet and tangy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/423/f6b2da2c8022e95239472806f9607423.jpg", // Exact match "Paneer Manchurian"
        available: true,
        type: "Veg",
      },
      {
        id: 9892,
        name: "Veg. Manchurian",
        price: 250,
        description:
          "Mixed vegetables in a thick Indo-Chinese manchurian sauce, savory and tangy.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/6f02b8b0b87cb93ce8a40c5f498121011634556481.png", // Exact match "Veg. Manchurian"
        available: true,
        type: "Veg",
      },
      {
        id: 3224,
        name: "Gobhi Manchurian",
        price: 250,
        description:
          "Cauliflower florets in a tangy Indo-Chinese manchurian sauce with veggies.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/6f02b8b0b87cb93ce8a40c5f498121011634556481.png", // Using "Veg. Manchurian" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 6563,
        name: "French Fries",
        price: 170,
        description:
          "Crispy golden French fries, seasoned with salt, perfect for snacking.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/a0f/892fabb602a2a5b6512fa07cec266a0f.jpg", // Exact match "French Fries"
        available: true,
        type: "Veg",
      },
      {
        id: 9893,
        name: "Veg. Finger",
        price: 240,
        description:
          "Crispy vegetable fingers, breaded and fried, served with a tangy dip.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/89e/6baf4d1f75a55ed70422f0352e31689e.jpg", // Exact match "Veg. Finger"
        available: true,
        type: "Veg",
      },
      {
        id: 3225,
        name: "Chana Jor Garam",
        price: 200,
        description:
          "A spicy and tangy Indian street snack made with roasted chickpeas.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b5d/b94e981489962b236f0934a6ce9d0b5d.jpg", // Exact match "Chana Jor Garam"
        available: true,
        type: "Veg",
      },
      {
        id: 4333,
        name: "Veg. Papad Roll",
        price: 170,
        description:
          "A crispy papad roll stuffed with spiced vegetables, a crunchy snack.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/64d5639c3885d5b96f1d772c897765571632716606.png", // Using "Papad Roll" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 8777,
        name: "Masala Papad",
        price: 100,
        description:
          "Crispy papad topped with a mix of onions, tomatoes, and spices.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/64d5639c3885d5b96f1d772c897765571632716606.png", // Exact match "Masala Papad"
        available: true,
        type: "Veg",
      },
      {
        id: 2121,
        name: "Cheese Finger",
        price: 220,
        description:
          "Crispy fingers stuffed with gooey cheese, breaded and fried to perfection.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/dec/d42bc7eac6d601340f5cc63dc6b62dec.jpg", // Exact match "Cheese Finger"
        available: true,
        type: "Veg",
      },
      {
        id: 6564,
        name: "Paneer Pakoda",
        price: 190,
        description:
          "Paneer cubes dipped in spiced gram flour batter and fried until crispy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/549/31a8422aaafa815d4d4ff36dfa8fb549.jpg", // Exact match "Paneer Pakora"
        available: true,
        type: "Veg",
      },
      {
        id: 9894,
        name: "Veg. Pakoda",
        price: 170,
        description:
          "Assorted vegetables dipped in gram flour batter and fried to a golden crisp.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/71a/d0a93049d4b7a58e58a655d77796771a.jpg", // Using "Mix Pakora" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 3226,
        name: "Veg. Cutlet",
        price: 150,
        description:
          "Spiced vegetable patties, breaded and fried, served with a tangy chutney.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/034/c6729d995fee786246ce89fd79a92034.jpg", // Exact match "Veg. Cutlet"
        available: true,
        type: "Veg",
      },
      {
        id: 6565,
        name: "Cheese Sandwich (Grilled)",
        price: 150,
        description:
          "A grilled sandwich with melted cheese, offering a gooey and savory bite.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/3d3/961df251a980b959cefb2bf55aa223d3.jpg", // Using "Grill Sandwich" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 9895,
        name: "Tomato Sandwich",
        price: 120,
        description:
          "A simple sandwich with fresh tomatoes, seasoned with spices and herbs.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/fc641efbb73b10484257f295ef0b9b981634401116.png", // Using "Sandwich" as a simple fallback
        available: true,
        type: "Veg",
      },
      {
        id: 3227,
        name: "Grilled Veg Sandwich",
        price: 150,
        description:
          "A grilled sandwich stuffed with mixed vegetables and spices, toasted to perfection.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/3d3/961df251a980b959cefb2bf55aa223d3.jpg", // Exact match "Grill Sandwich"
        available: true,
        type: "Veg",
      },
      {
        id: 4334,
        name: "Butter Toast Amul",
        price: 75,
        description:
          "Crispy toast topped with Amul butter, a simple and comforting snack.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b41/d8e2d1a79dc56f434a50c947e1ffbb41.jpg", // Exact match "Butter Toast"
        available: true,
        type: "Veg",
      },
      {
        id: 8778,
        name: "Spring Roll",
        price: 170,
        description:
          "Crispy rolls stuffed with vegetables and noodles, served with a tangy dip.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/4c5/e49939940738ba34174f43a3893504c5.jpg", // Exact match "Spring Roll"
        available: true,
        type: "Veg",
      },
      {
        id: 2122,
        name: "Fresh Paneer",
        price: 220,
        description:
          "Fresh paneer cubes lightly seasoned, a simple and healthy snack.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1eb/a52fc94d28e757984295d07b594ec1eb.jpg", // Exact match "Fresh Paneer"
        available: true,
        type: "Veg",
      },
      {
        id: 6566,
        name: "Tomato Soup",
        price: 150,
        description:
          "A warm and comforting tomato soup with a tangy flavor and fresh herbs.",
        image:
          "https://b.zmtcdn.com/data/dish_images/84f7e304f4dde7a1c8d84f221396a56c1630430933.png", // Exact match "Tomato Soup"
        available: true,
        type: "Veg",
      },
      {
        id: 9896,
        name: "Veg. Soup",
        price: 150,
        description:
          "A hearty vegetable soup with a mix of fresh veggies and mild spices.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/30b182731e0b56e3043afe1a8d6cbd9c1632716548.png", // Exact match "Vegetable Soup"
        available: true,
        type: "Veg",
      },
      {
        id: 3228,
        name: "Veg. Noodles",
        price: 180,
        description:
          "Stir-fried noodles with mixed vegetables in an Indo-Chinese style.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/93a/461682ab173f4978d7ed05102119193a.jpg", // Using "Chowmein" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 6567,
        name: "Mushroom Kurmure",
        price: 260,
        description:
          "Crispy mushroom bites seasoned with spices, offering a crunchy snack.",
        image:
          "https://files.yappe.in/place/small/tales-spirits-viman-nagar-3491159.webp", // Exact match "Mushroom Kurmure"
        available: true,
        type: "Veg",
      },
      {
        id: 9897,
        name: "Paneer Kurmure",
        price: 270,
        description:
          "Crispy paneer bites seasoned with spices, a delightful crunchy snack.",
        image:
          "https://th.bing.com/th/id/OIP.g4bFd0LOrqDZdBToPVT0QwHaFj?rs=1&pid=ImgDetMain", // Exact match "Paneer Kurmure"
        available: true,
        type: "Veg",
      },
      {
        id: 3229,
        name: "Mix Kurmure",
        price: 260,
        description:
          "A mix of crispy veggies and paneer, seasoned with spices for a crunchy bite.",
        image:
          "https://c.ndtvimg.com/2021-02/v1giqui8_spring-roll_625x300_02_February_21.jpg", // Exact match "Mix Kurmure"
        available: true,
        type: "Veg",
      },
      {
        id: 4335,
        name: "Afgani Chaap",
        price: 250,
        description:
          "Soya chaap in a creamy afgani-style white gravy, served as a snack.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6a6/2504a62907e58ee7631881dea47306a6.jpg", // Exact match "Afghani Chaap"
        available: true,
        type: "Veg",
      },
      {
        id: 8779,
        name: "Crispy Corn",
        price: 230,
        description:
          "Crispy corn kernels tossed with spices, offering a sweet and savory snack.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/31a/e53fa1fde70c5ea6cf2fa4db32eaf31a.jpg", // Exact match "Crispy Corn"
        available: true,
        type: "Veg",
      },
      {
        id: 2123,
        name: "Chilly Chaap",
        price: 230,
        description:
          "Soya chaap tossed in a spicy Indo-Chinese chilly sauce with bell peppers.",
        image:
          "https://b.zmtcdn.com/data/pictures/7/19010457/7583fb36ae31f75af1bfd61423aab62d_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A", // Exact match "Chilly Chaap"
        available: true,
        type: "Veg",
      },
      {
        id: 6568,
        name: "Soyabean Kabab",
        price: 250,
        description:
          "Spiced soya kababs, grilled to perfection, offering a savory bite.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/653/655ad41a4d95eaf6f6e098f93e814653.jpg", // Using "Dahi Kabab" as a kabab fallback
        available: true,
        type: "Veg",
      },
      {
        id: 9898,
        name: "Paneer Lollipop",
        price: 300,
        description:
          "Paneer lollipops coated with spices and fried, served with a tangy dip.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b93/e2f539695d89192cdca5336b6ac25b93.jpg", // Using "Crispy Paneer" as a crispy paneer fallback
        available: true,
        type: "Veg",
      },
      {
        id: 3230,
        name: "Bullet Kabab",
        price: 260,
        description:
          "Spicy bullet-shaped kababs made with veggies and spices, a fiery snack.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/653/655ad41a4d95eaf6f6e098f93e814653.jpg", // Using "Dahi Kabab" as a kabab fallback
        available: true,
        type: "Veg",
      },
      {
        id: 6569,
        name: "Veg Chilly Soyabeen",
        price: 250,
        description:
          "Soyabean chunks in a spicy Indo-Chinese chilly sauce with veggies.",
        image:
          "https://b.zmtcdn.com/data/pictures/7/19010457/7583fb36ae31f75af1bfd61423aab62d_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A", // Using "Chilly Chaap" as a chilly soya fallback
        available: true,
        type: "Veg",
      },
      {
        id: 9899,
        name: "Chilly Gobhi",
        price: 250,
        description:
          "Cauliflower florets in a spicy Indo-Chinese chilly sauce with bell peppers.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/4fd/6924e2737892504892f1c5d7944c24fd.jpg", // Exact match "Chilly Gobhi"
        available: true,
        type: "Veg",
      },
      {
        id: 3231,
        name: "Chilly Potato",
        price: 200,
        description:
          "Crispy potato fries tossed in a spicy Indo-Chinese chilly sauce.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1d3/0afe54ef08dc3a2d88d38c63929d81d3.jpg", // Exact match "Chilly Potato"
        available: true,
        type: "Veg",
      },
      {
        id: 4336,
        name: "Tandoori Chaap",
        price: 260,
        description:
          "Soya chaap marinated with tandoori spices and grilled to smoky perfection.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/9c9/8cd9cb6041966d833ea2d1e14e4a89c9.jpg", // Exact match "Tandoori Chaap"
        available: true,
        type: "Veg",
      },
      {
        id: 8780,
        name: "Chilly Mushroom",
        price: 260,
        description:
          "Mushrooms tossed in a spicy Indo-Chinese chilly sauce with bell peppers.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c1e/03bfe86ec83ad3b334884f83fce60c1e.png", // Exact match "Chilly Mushroom"
        available: true,
        type: "Veg",
      },
      {
        id: 2124,
        name: "Kulhad Milk",
        price: 60,
        description:
          "Warm milk served in a traditional kulhad, offering a rustic and comforting taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f9c/d4dd3b898813ed1684ba10444dfedf9c.png",
        available: true,
        type: "Veg",
      },
      {
        id: 6570,
        name: "Peanut Masala",
        price: 180,
        description:
          "Roasted peanuts tossed with spices, onions, and tomatoes for a tangy snack.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5e3/0cd3efa8604faf90b01292de9604d5e3.jpg", // Exact match "Peanut Masala"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Beverages",
    items: [
      {
        id: 4337,
        name: "Cold Drink",
        price: 0,
        description: "A refreshing cold drink, price as per MRP.",
        image:
          "https://www.mashed.com/img/gallery/the-sneaky-reason-grocery-stores-have-cold-drinks-in-the-front/l-intro-1675199881.jpg", // Exact match "Cold Drink"
        available: true,
        type: "Veg",
      },
      {
        id: 8781,
        name: "Mineral Water",
        price: 0,
        description: "Pure mineral water, price as per MRP.",
        image:
          "https://th.bing.com/th/id/OIP.wpJyO-KhCv-q9ggZNdCcFwHaHa?rs=1&pid=ImgDetMain", // Exact match "Mineral Water"
        available: true,
        type: "Veg",
      },
      {
        id: 2125,
        name: "Fresh Lime Soda",
        price: 60,
        description:
          "A fizzy and refreshing lime soda, perfect for cooling down.",
        image:
          "https://th.bing.com/th/id/OIP.glET1vW8y1B6U4tMOgq16wAAAA?w=270&h=184&c=7&r=0&o=5&dpr=1.1&pid=1.7", // Exact match "Fresh Lime Soda"
        available: true,
        type: "Veg",
      },
      {
        id: 6571,
        name: "Jal Jeera",
        price: 20,
        description:
          "A tangy and spicy jal jeera drink, made with cumin and mint, great for digestion.",
        image:
          "https://th.bing.com/th/id/R.8816d7cb9baac93964e474da2ba7b98b?rik=weLmHmo2mgAeig&riu=http%3a%2f%2fwww.ndtv.com%2fcooks%2fimages%2flime-soda-620.jpg&ehk=OwFfq%2fW18dQCwzsVqvwXDNmg2GV%2fbSm6J4w6kdAt9bY%3d&risl=&pid=ImgRaw&r=0", // Using "Shikanji" as a tangy fallback
        available: true,
        type: "Veg",
      },
      {
        id: 9900,
        name: "Spl. Tea",
        price: 28,
        description:
          "A special tea blend with a rich and aromatic flavor, served hot.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/a2163bedb069e3069db0a88d7a3c848f1676534399.png", // Exact match "Sp. Tea"
        available: true,
        type: "Veg",
      },
      {
        id: 3232,
        name: "Tea",
        price: 25,
        description:
          "A classic hot tea, brewed to perfection with a comforting taste.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/a2163bedb069e3069db0a88d7a3c848f1676534399.png", // Using "Milk Tea" as closest match
        available: true,
        type: "Veg",
      },
      {
        id: 6572,
        name: "Black Tea",
        price: 20,
        description:
          "A strong black tea, brewed without milk for a bold flavor.",
        image:
          "https://b.zmtcdn.com/data/dish_images/9b9bb4855ac55722e949d5b272c9bea61630568803.png", // Using "Milk Tea" as a tea fallback
        available: true,
        type: "Veg",
      },
      {
        id: 9901,
        name: "Lemon Tea",
        price: 20,
        description: "A refreshing lemon tea with a tangy twist, served hot.",
        image:
          "https://b.zmtcdn.com/data/dish_images/8bfd6cbdc601114f9c87df7eac8183591628748571.png", // Using "Milk Tea" as a tea fallback
        available: true,
        type: "Veg",
      },
      {
        id: 3233,
        name: "Ginger Tea",
        price: 20,
        description:
          "A warm ginger tea with a spicy kick, perfect for soothing the throat.",
        image:
          "https://b.zmtcdn.com/data/dish_images/b4c0d4bc43520b3ac82dce6be9f97a421628748557.png", // Using "Milk Tea" as a tea fallback
        available: true,
        type: "Veg",
      },
      {
        id: 4338,
        name: "Coffee",
        price: 50,
        description:
          "A hot cup of coffee, brewed with rich coffee beans for a bold taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/358/6c8a2865515af7be4bda2fdca0343358.jpg", // Exact match "Hot Coffee"
        available: true,
        type: "Veg",
      },
      {
        id: 8782,
        name: "Cold Coffee",
        price: 150,
        description:
          "A chilled cold coffee, creamy and refreshing, perfect for a hot day.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/11b/7d4650ef435a60757ce71d68907f011b.jpg?output-format=webp", // Exact match "Cold Coffee"
        available: true,
        type: "Veg",
      },
      {
        id: 2126,
        name: "Lassi",
        price: 60,
        description:
          "A traditional yogurt-based drink, sweet and creamy, perfect for cooling down.",
        image:
          "https://www.indianveggiedelight.com/wp-content/uploads/2023/01/sweet-lassi-recipe-featured.jpg", // Exact match "Lassi Sweet"
        available: true,
        type: "Veg",
      },
      {
        id: 6573,
        name: "Jeera Chhach",
        price: 60,
        description:
          "A refreshing buttermilk drink flavored with roasted cumin (jeera).",
        image:
          "https://th.bing.com/th/id/OIP.XxnfxJiJJ-bZQsevELUoHQHaEV?rs=1&pid=ImgDetMain", // Using "Chaach" as closest match
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Tandoor Se",
    items: [
      {
        id: 4339,
        name: "Garlic Naan",
        price: 80,
        description:
          "Soft naan bread topped with garlic and butter, baked in a tandoor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d4e/c62a165cb8979b100b79156cd69c6d4e.jpg", // Exact match "Garlic Naan"
        available: true,
        type: "Veg",
      },
      {
        id: 8783,
        name: "Paneer Naan",
        price: 90,
        description:
          "Naan stuffed with spiced paneer, baked in a tandoor for a soft and flavorful bread.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d70/02f550126f543df8c0e75d8a6456ad70.jpg", // Exact match "Paneer Naan"
        available: true,
        type: "Veg",
      },
      {
        id: 2127,
        name: "Stuff Naan",
        price: 85,
        description:
          "Naan stuffed with a mix of vegetables and spices, baked in a tandoor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d70/02f550126f543df8c0e75d8a6456ad70.jpg", // Exact match "Stuffed Naan"
        available: true,
        type: "Veg",
      },
      {
        id: 6574,
        name: "Choor-Choor Naan",
        price: 90,
        description:
          "A flaky and crispy naan, torn into pieces, baked in a tandoor with butter.",
        image:
          "https://b.zmtcdn.com/data/dish_images/afa3add62f60d39ed4e9bc355a970ee51604140116.png", // Using "Butter Naan" as a flaky fallback
        available: true,
        type: "Veg",
      },
      {
        id: 9902,
        name: "Mix Paratha",
        price: 80,
        description:
          "A paratha stuffed with a mix of vegetables, cooked on a tawa with ghee.",
        image:
          "https://b.zmtcdn.com/data/pictures/4/21129034/955eed08afd2cca800355aa88ca3c5d4_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A", // Exact match "Mix Parantha"
        available: true,
        type: "Veg",
      },
      {
        id: 3234,
        name: "Aloo Pyaz Paratha",
        price: 70,
        description:
          "A paratha stuffed with spiced potatoes and onions, cooked with ghee.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/7f4/1f362b3d391bd2f022a9e0d1d551b7f4.jpg", // Exact match "Aloo Pyaz Parantha"
        available: true,
        type: "Veg",
      },
      {
        id: 6575,
        name: "Gobhi Paratha",
        price: 70,
        description:
          "A paratha stuffed with spiced cauliflower, cooked on a tawa with ghee.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d8e/42bd4f8ec4bebec1c65ecc301e292d8e.jpg", // Exact match "Gobhi Parantha"
        available: true,
        type: "Veg",
      },
      {
        id: 9903,
        name: "Laccha Paratha",
        price: 49,
        description:
          "A flaky, layered paratha, cooked with ghee for a crispy texture.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/2b5a5b533473aada22015966f668e30e1633434990.png", // Exact match "Lachedar Prantha"
        available: true,
        type: "Veg",
      },

      {
        id: 3235,
        name: "Butter Naan",
        price: 49,
        description:
          "Soft naan bread brushed with butter, baked in a tandoor for a rich taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e96/2114c4dbc8426d3f085c0c881b6aae96.jpeg",
        available: true,
        type: "Veg",
      },
      {
        id: 4340,
        name: "Butter Roti",
        price: 20,
        description:
          "Soft roti brushed with butter, cooked in a tandoor for a rich flavor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/072/a62cc95460cace55c0abcfc59a31d072.jpeg",
        available: true,
        type: "Veg",
      },
      {
        id: 8784,
        name: "Sada Roti",
        price: 17,
        description:
          "A simple roti, cooked in a tandoor, perfect for pairing with gravies.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0da/bf90e1ce1fb9ea2c928c00d6fdb4e0da.jpeg",
        available: true,
        type: "Veg",
      },
      {
        id: 2128,
        name: "Missi Roti Pyaz",
        price: 49,
        description:
          "Missi roti topped with onions, made with gram flour and spices, cooked in a tandoor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/a69/90ce3f6f568deb406eb62b9d94bb6a69.jpeg",
        available: true,
        type: "Veg",
      },
      {
        id: 6576,
        name: "Missi Roti Plain",
        price: 46,
        description:
          "A plain missi roti made with gram flour and spices, cooked in a tandoor.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/a69/90ce3f6f568deb406eb62b9d94bb6a69.jpeg",
        available: true,
        type: "Veg",
      },
      {
        id: 9904,
        name: "Paneer Paratha",
        price: 90,
        description:
          "A paratha stuffed with spiced paneer, cooked on a tawa with ghee.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d7b/c41c0e272d9058b6cd38f37dbf001d7b.png",
        available: true,
        type: "Veg",
      },
      {
        id: 3236,
        name: "Onion Paratha",
        price: 80,
        description:
          "A paratha stuffed with spiced onions, cooked with ghee for a savory taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b64/ee91199891579c8a4b86e99afa7a7b64.png",
        available: true,
        type: "Veg",
      },
      {
        id: 6577,
        name: "Garlic Roti",
        price: 38,
        description:
          "A roti topped with garlic, cooked in a tandoor for a flavorful twist.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0da/bf90e1ce1fb9ea2c928c00d6fdb4e0da.jpeg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Salads",
    items: [
      {
        id: 4341,
        name: "Green Salad",
        price: 95,
        description:
          "A fresh mix of cucumber, tomatoes, onions, and greens, served with a light dressing.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/02c/bf5c3801c16c4959c67a4615bedde02c.jpeg",
        available: true,
        type: "Veg",
      },
      {
        id: 8785,
        name: "Kachumber Salad",
        price: 120,
        description:
          "A tangy salad with diced cucumbers, tomatoes, onions, and a hint of lemon.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/438/e7f272f03d9f5d460d2e8eba6ed5f438.jpeg",
        available: true,
        type: "Veg",
      },
    ],
  },
];

const metaData = {
  name: "Hari Krishna Dhaba",
  location: "Palwal",
  whatsappNo: "9050117777",
  mapLocation: "https://maps.app.goo.gl/o983WBJgSax4RQkP9",
  paymentUpi: "",
  instagram:
    "https://www.instagram.com/explore/locations/681359143/hari-krishan-dhaba-palwal/?hl=en",
  reviewLink: "https://reviewthis.biz/quiet-silence-1936",
  qrCodes: [{ code: "00044", table: "Table No - 1" }],
};
export default { menu, metaData };
