import React, { useState, useRef, useEffect } from "react";
import "./MenuCard.css";
import { Link, useNavigate } from "react-router-dom";
import { FaMapSigns, FaMinus, FaPlus } from "react-icons/fa";
import { BiMenu } from "react-icons/bi";
import { CiMapPin, CiMenuBurger } from "react-icons/ci";
import { GiPlateClaw } from "react-icons/gi";
import { IoRestaurant } from "react-icons/io5";
import { MdRestaurant } from "react-icons/md";
import menuData from "../../MENULIST/Palwal/GrandPrakashMenu";
import { BsFillStarFill, BsInstagram, BsStarFill } from "react-icons/bs";
import { HiMapPin } from "react-icons/hi2";

const MenuDemo = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRefs = useRef({});
  const categoryRefs = useRef({});
  const [savedItems, setSavedItems] = useState([]);
  const navigate = useNavigate();
  let menu = menuData.menu;

  useEffect(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("savedItems")) || [];
    setSavedItems(storedItems);

    const handleScroll = () => {
      menu.forEach((section) => {
        const categoryRef = categoryRefs.current[section.category];
        if (categoryRef) {
          const categoryTop = categoryRef.getBoundingClientRect().top;
          const categoryBottom = categoryRef.getBoundingClientRect().bottom;
          if (categoryTop <= 0 && categoryBottom > 0) {
            categoryRef.classList.add("sticky", "top-0", "z-10");
          } else {
            categoryRef.classList.remove("sticky", "top-0", "z-10");
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const categories = menu.map((cat) => ({
    name: cat.category,
    refKey: cat.category,
  }));

  const scrollToCategory = (category) => {
    menuRefs.current[category].scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setIsMenuOpen(false);
  };

  const addToCart = (item) => {
    setSavedItems((prevItems) => {
      const itemKey = item.size ? `${item.id}-${item.size}` : `${item.id}`;
      const existingItem = prevItems.find(
        (savedItem) => savedItem.id === item.id && savedItem.size === item.size
      );
      let updatedItems;
      if (existingItem) {
        updatedItems = prevItems.map((savedItem) =>
          savedItem.id === item.id && savedItem.size === item.size
            ? { ...savedItem, quantity: savedItem.quantity + 1 }
            : savedItem
        );
      } else {
        updatedItems = [...prevItems, { ...item, quantity: 1 }];
      }
      sessionStorage.setItem("savedItems", JSON.stringify(updatedItems));
      return updatedItems;
    });
  };

  const removeFromCart = (item) => {
    setSavedItems((prevItems) => {
      const existingItem = prevItems.find(
        (savedItem) => savedItem.id === item.id && savedItem.size === item.size
      );
      let updatedItems;
      if (existingItem.quantity > 1) {
        updatedItems = prevItems.map((savedItem) =>
          savedItem.id === item.id && savedItem.size === item.size
            ? { ...savedItem, quantity: savedItem.quantity - 1 }
            : savedItem
        );
      } else {
        updatedItems = prevItems.filter(
          (savedItem) =>
            !(savedItem.id === item.id && savedItem.size === item.size)
        );
      }
      sessionStorage.setItem("savedItems", JSON.stringify(updatedItems));
      return updatedItems;
    });
  };

  return (
    <div className="p-4 bg-gradient-to-br from-gray-50 to-custom-dark/10 min-h-screen relative">
      {/* Restaurant Header */}
      <div className="bg-gradient-to-r from-custom-dark to-theme-green rounded-xl shadow-lg p-4 mb-6">
        <h1 className="text-3xl font-extrabold text-white text-center drop-shadow-md">
          Veer Vikramaditya Dhaba
        </h1>
        <h2 className="text-lg text-theme-green text-center mt-1">Palwal</h2>
        <img
          src={require("../../assets/images/menu/enjoy_the_meel.png")}
          alt="Enjoy the Meal"
          className="w-32 mx-auto mt-3 mb-4 animate-pulse"
        />

        <div className="flex flex-col gap-3">
          <div className="flex gap-2">
            <button
              className="flex items-center justify-center gap-2 bg-white text-theme-green transition-colors duration-300 rounded-full shadow-md p-1 w-full"
              onClick={() => {
                const upiId = "7404121102@ybl";
                const name = "Tejas Goel";
                const upiUrl = `upi://pay?pa=${upiId}&pn=${encodeURIComponent(
                  name
                )}&cu=INR`;
                window.open(upiUrl, "_blank");
              }}
            >
              <img
                src="https://logodix.com/logo/1645140.png"
                alt="UPI Payment Icon"
                className="w-10 h-5"
              />
              <span className="text-base font-semibold">Pay Bill</span>
            </button>
            <button
              className="flex items-center justify-center gap-2 bg-white text-custom-dark  transition-colors duration-300 rounded-full shadow-md p-1 w-full"
              onClick={() =>
                window.open(
                  "https://maps.app.goo.gl/mzkmogymnkSLhWn78",
                  "_blank"
                )
              }
            >
              <img
                src="https://logos-download.com/wp-content/uploads/2016/05/Google_Maps_logo_icon.png"
                alt="Google Maps Icon"
                className="w-6 h-6"
              />
              <span className="text-base font-semibold">Location</span>
            </button>
          </div>
          <div className="flex gap-2">
            <button
              className="flex items-center justify-center gap-2 bg-white text-custom-dark  transition-colors duration-300 rounded-full shadow-md p-1 w-full"
              onClick={() =>
                window.open(
                  "https://www.google.com/search?sca_esv=01c010bbdd704271&sxsrf=AHTn8zqc3cQN2xJXYOI0vLKKNCO4kZOz2Q:1740470713644&si=APYL9bvoDGWmsM6h2lfKzIb8LfQg_oNQyUOQgna9TyfQHAoqUnuHLmBkKvasqmmL0auvlg8TQbOqT88iOjqHjdhY6LQwj7GL4DWgmlysu5IJ2KPD6TWMMW1y12lIMCo65bTxG_PRDcRt5HFxsl22MFRRxC0BlBL2YQ%3D%3D&q=Grand+Prakash+Dhaba+Reviews&sa=X&ved=2ahUKEwicmYPVrt6LAxVmxDgGHe6hNjUQ0bkNegQIQBAD&biw=2560&bih=911&dpr=1#lrd=0x390cd3cffde20ed7:0x8738a623b4b313e2,3&ebo=2",
                  "_blank"
                )
              }
            >
              <BsFillStarFill className="w-5 h-5" color="orange" />
              {/* <BsFillStarFill className="w-6 h-6" color="orange" /> */}
              <span className="text-base font-semibold">Review </span>
              {/* <BsFillStarFill className="w-6 h-6" color="orange" /> */}
            </button>
            <button
              className="flex items-center justify-center gap-2 bg-white text-custom-dark  transition-colors duration-300 rounded-full shadow-md p-1 w-full"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/grand_prakash_dhaba/",
                  "_blank"
                )
              }
            >
              <BsInstagram className="w-5 h-5" color="red" />
              {/* <BsFillStarFill className="w-6 h-6" color="orange" /> */}
              <span className="text-base font-semibold">Instagram</span>
            </button>
          </div>
        </div>
      </div>

      {/* Menu */}
      {menu.map((section, index) => (
        <div
          key={index}
          ref={(el) => (menuRefs.current[section.category] = el)}
        >
          <div
            ref={(el) => (categoryRefs.current[section.category] = el)}
            className="sticky top-0 z-10 bg-white/95 backdrop-blur-sm border-b border-custom-dark/10 p-3 shadow-sm"
          >
            <h2 className="text-xl font-bold text-theme-green flex items-center gap-2">
              <IoRestaurant className="text-custom-dark" />
              {section.category}
            </h2>
          </div>
          <div className="mt-3">
            <div className="space-y-4">
              {section.items.map((item) => {
                let cartItem = savedItems.find(
                  (savedItem) =>
                    savedItem.id === item.id && savedItem.size === item.size
                );

                return (
                  <div
                    key={`${item.id}-${item.size || "default"}`}
                    className="bg-white p-4 rounded-xl shadow-md border border-custom-dark/10"
                  >
                    <div className="flex items-start gap-4">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="w-24 h-24 object-cover rounded-lg shadow-sm"
                      />
                      <div className="flex-1">
                        <div className="flex items-center gap-2">
                          <h3 className="text-lg font-bold text-custom-dark">
                            {item.name}
                          </h3>
                          {item.size && (
                            <span className="bg-theme-green text-white px-2 py-1 rounded-full text-sm font-semibold">
                              {item.size}
                            </span>
                          )}
                        </div>
                        <p className="text-sm text-gray-600 mt-1 line-clamp-2">
                          {item.description}
                        </p>
                        <div className="flex items-center justify-between mt-3">
                          <span className="text-base font-bold text-theme-green">
                            ₹{item.price}
                          </span>
                          {cartItem ? (
                            <div className="flex gap-2 items-center">
                              <button
                                onClick={() => removeFromCart(item)}
                                className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-300"
                              >
                                <FaMinus className="text-sm" />
                              </button>
                              <span className="text-base font-bold text-custom-dark">
                                {cartItem.quantity}
                              </span>
                              <button
                                onClick={() => addToCart(item)}
                                className="p-2 bg-theme-green text-white rounded-full hover:bg-theme-green/80 transition-colors duration-300"
                              >
                                <FaPlus className="text-sm" />
                              </button>
                            </div>
                          ) : (
                            <button
                              onClick={() => addToCart(item)}
                              className={`px-3 py-1 rounded-full text-sm ${
                                item.available
                                  ? "bg-theme-green text-white hover:bg-theme-green/80"
                                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
                              } transition-colors duration-300`}
                              disabled={!item.available}
                            >
                              Add
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ))}

      {/* Floating Menu Button */}
      <div className="fixed bottom-16 right-4 z-50">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="bg-gradient-to-r from-custom-dark to-theme-green text-white p-3 rounded-full shadow-xl flex items-center gap-2"
        >
          <MdRestaurant className="text-2xl" />
          <span className="text-base font-bold">Menu</span>
        </button>

        {isMenuOpen && (
          <div className="absolute bottom-14 right-0 bg-gradient-to-r from-custom-dark to-theme-green/80 text-white rounded-xl shadow-xl w-48 p-3 max-h-[40vh] overflow-y-auto">
            {categories.map((category, index) => (
              <button
                key={index}
                className="block w-full text-left px-3 py-2 bg-custom-dark/80 hover:bg-custom-dark rounded-md transition-colors duration-300 flex items-center gap-2 mt-1 text-sm"
                onClick={() => scrollToCategory(category.refKey)}
              >
                <IoRestaurant className="text-theme-green" />
                {category.name}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Cart Footer */}
      <div
        onClick={() => navigate("/menu/saved-items", { state: { savedItems } })}
        className="fixed w-full left-0 bottom-0 bg-gradient-to-r from-custom-dark to-theme-green text-white px-4 py-3 shadow-xl h-16 flex items-center justify-between"
      >
        <span className="flex items-center gap-2 text-base">
          <GiPlateClaw className="text-yellow-300" />
          Cart ({savedItems.length})
        </span>
        <span className="text-base font-semibold">View 🛒</span>
      </div>
    </div>
  );
};

export default MenuDemo;
