// servicesData.js
export const servicesData = [
  {
    id: "restaurant-menu",
    name: "Restaurant Menu",
    description:
      "QR code Menu,NFC Tap Menu, Location Share, Direct Order on Whatsapp, Direct Payment collection through UPI, Google Review, Instagram Follow",
    icon: require("../assets/servicesIcons/menu-1.png"),
    realPhotos: [
      require("../assets/images/menu/mc-1.jpeg"),
      require("../assets/images/menu/mc-2.jpeg"),
      require("../assets/images/menu/mc-3.jpeg"),
    ],
    serviceRate: "Rs 1299 per year",
    discountedPrice: 600,
  },
  {
    id: "two-wheeler-security",
    name: "Two Wheeler Security",
    description:
      "Our Two Wheeler Security service provides QR-enabled tracking and protection for your motorcycles and scooters. Ensure your two-wheelers are always safe and secure with our advanced technology.",
    icon: require("../assets/servicesIcons/motorcycle.png"),
    realPhotos: [
      require("../assets/realPhotos/1.png"),
      require("../assets/realPhotos/tw-1.jpeg"),
      require("../assets/realPhotos/tw-2.jpeg"),
    ],
    serviceRate: "Rs 250 per year",
  },
  {
    id: "three-wheeler-security",
    name: "Three Wheeler Security",
    description:
      "Monitor and safeguard your three-wheelers with our QR-enabled solutions.",
    icon: require("../assets/servicesIcons/tuk-tuk.png"),
    realPhotos: [
      "../assets/realPhotos/three-wheeler1.jpg",
      "/assets/realPhotos/three-wheeler2.jpg",
      "/assets/realPhotos/three-wheeler3.jpg",
    ],
    serviceRate: "Rs 1199 per year",
  },
  {
    id: "four-wheeler-security",
    name: "Four Wheeler Security",
    description:
      "QR-enabled solutions for car security and tracking, keeping your vehicle safe at all times.",
    icon: require("../assets/servicesIcons/car.png"),
    realPhotos: [
      "/assets/realPhotos/four-wheeler1.jpg",
      "/assets/realPhotos/four-wheeler2.jpg",
      "/assets/realPhotos/four-wheeler3.jpg",
    ],
    serviceRate: "Rs 1499 per year",
  },
  {
    id: "heavy-vehicle-security",
    name: "Heavy Vehicle Security",
    description:
      "Efficient tracking and monitoring for heavy vehicles using advanced QR technology.",
    icon: require("../assets/servicesIcons/delivery-truck.png"),
    realPhotos: [
      "/assets/realPhotos/heavy-vehicle1.jpg",
      "/assets/realPhotos/heavy-vehicle2.jpg",
      "/assets/realPhotos/heavy-vehicle3.jpg",
    ],
    serviceRate: "Rs 1999 per year",
  },
  {
    id: "e-rickshaw-security",
    name: "E-Rickshaw Security",
    description:
      "QR codes for E-Rickshaw safety, providing real-time monitoring and protection.",
    icon: require("../assets/servicesIcons/eRickshaw1.png"),
    realPhotos: [
      "/assets/realPhotos/e-rickshaw1.jpg",
      "/assets/realPhotos/e-rickshaw2.jpg",
      "/assets/realPhotos/e-rickshaw3.jpg",
    ],
    serviceRate: "Rs 899 per year",
  },
  {
    id: "keychain-tracking",
    name: "Keychain Tracking",
    description:
      "Never lose your keys again with QR-enabled tracking for keychains.",
    icon: require("../assets/servicesIcons/house-key.png"),
    realPhotos: [
      "/assets/realPhotos/keychain1.jpg",
      "/assets/realPhotos/keychain2.jpg",
      "/assets/realPhotos/keychain3.jpg",
    ],
    serviceRate: "Rs 499 per year",
  },
  {
    id: "kids-locket",
    name: "Kids Locket",
    description:
      "Ensure your child’s safety with QR-enabled lockets that provide peace of mind.",
    icon: require("../assets/servicesIcons/pendant.png"),
    realPhotos: [
      "/assets/realPhotos/kids-locket1.jpg",
      "/assets/realPhotos/kids-locket2.jpg",
      "/assets/realPhotos/kids-locket3.jpg",
    ],
    serviceRate: "Rs 599 per year",
  },
  {
    id: "bag-tag",
    name: "Bag Tag",
    description: "QR tags for easy tracking of your Bag during school, travel.",
    icon: require("../assets/servicesIcons/school-bag.png"),
    realPhotos: [
      "/assets/realPhotos/bag-tag1.jpg",
      "/assets/realPhotos/bag-tag2.jpg",
      "/assets/realPhotos/bag-tag3.jpg",
    ],
    serviceRate: "Rs 499 per year",
  },
  {
    id: "luggage-tagging",
    name: "Luggage Tagging",
    description:
      "QR tags for easy tracking of your luggage during travel, ensuring nothing gets lost.",
    icon: require("../assets/servicesIcons/traveler.png"),
    realPhotos: [
      "/assets/realPhotos/luggage-tagging1.jpg",
      "/assets/realPhotos/luggage-tagging2.jpg",
      "/assets/realPhotos/luggage-tagging3.jpg",
    ],
    serviceRate: "Rs 499 per year",
  },

  {
    id: "love-tatto",
    name: "Love Memories",
    description:
      "QR tags designed to ensure the safety of your newborn in hospitals.",
    icon: require("../assets/servicesIcons/tattoo.png"),
    realPhotos: [
      "/assets/realPhotos/love-tatto1.jpg",
      "/assets/realPhotos/love-tatto2.jpg",
      "/assets/realPhotos/love-tatto3.jpg",
    ],
    serviceRate: "Rs 799 per year",
  },
  {
    id: "pet-tracking",
    name: "Pet Tracking",
    description:
      "Track your pets with QR codes to ensure their safety and your peace of mind.",
    icon: require("../assets/servicesIcons/collar.png"),
    realPhotos: [
      "/assets/realPhotos/pet-tracking1.jpg",
      "/assets/realPhotos/pet-tracking2.jpg",
      "/assets/realPhotos/pet-tracking3.jpg",
    ],
    serviceRate: "Rs 699 per year",
  },

  {
    id: "mobile-sticker",
    name: "Mobile Sticker",
    description:
      "Protect your mobile devices with QR codes that enhance security and recoverability.",
    icon: require("../assets/servicesIcons/communication.png"),
    realPhotos: [
      "/assets/realPhotos/mobile-sticker1.jpg",
      "/assets/realPhotos/mobile-sticker2.jpg",
      "/assets/realPhotos/mobile-sticker3.jpg",
    ],
    serviceRate: "Rs 399 per year",
  },

  {
    id: "laptop-sticker",
    name: "Laptop Sticker",
    description:
      "Versatile QR stickers for securing laptops, tab and other valuables.",
    icon: require("../assets/servicesIcons/worker.png"),
    realPhotos: [
      "/assets/realPhotos/laptop-sticker1.jpg",
      "/assets/realPhotos/laptop-sticker2.jpg",
      "/assets/realPhotos/laptop-sticker3.jpg",
    ],
    serviceRate: "Rs 699 per year",
  },
  {
    id: "wallet-document",
    name: "Wallet and Documents",
    description:
      "QR tags designed to ensure the safety of your newborn in hospitals.",
    icon: require("../assets/servicesIcons/wallet-2.png"),
    realPhotos: [
      "/assets/realPhotos/new-born-baby1.jpg",
      "/assets/realPhotos/new-born-baby2.jpg",
      "/assets/realPhotos/new-born-baby3.jpg",
    ],
    serviceRate: "Rs 799 per year",
  },
  {
    id: "new-born-baby",
    name: "New Born Baby",
    description:
      "QR tags designed to ensure the safety of your newborn in hospitals.",
    icon: require("../assets/servicesIcons/baby.png"),
    realPhotos: [
      "/assets/realPhotos/new-born-baby1.jpg",
      "/assets/realPhotos/new-born-baby2.jpg",
      "/assets/realPhotos/new-born-baby3.jpg",
    ],
    serviceRate: "Rs 799 per year",
  },
  {
    id: "business-card",
    name: "Business Card",
    description:
      "Qr codes for your business that identifies your business through a website.",
    icon: require("../assets/servicesIcons/business-card.png"),
    realPhotos: [
      "/assets/realPhotos/business-card1.jpg",
      "/assets/realPhotos/business-card2.jpg",
      "/assets/realPhotos/business-card3.jpg",
    ],
    serviceRate: "Rs 699 per year",
  },
  {
    id: "wedding-invite",
    name: "Wedding Invite",
    description:
      "Qr codes for your business that identifies your business through a website.",
    icon: require("../assets/servicesIcons/wedding-invite.png"),
    realPhotos: [
      "/assets/realPhotos/wedding-invite1.jpg",
      "/assets/realPhotos/wedding-invite2.jpg",
      "/assets/realPhotos/wedding-invite3.jpg",
    ],
    serviceRate: "Rs 699 per year",
  },
  {
    id: "door-bell-monitoring",
    name: "Door Bell Monitoring",
    description:
      "Enhance your home security with QR-enabled doorbells for safe and convenient monitoring.",
    icon: require("../assets/servicesIcons/door-bell.png"),
    realPhotos: [
      "/assets/realPhotos/door-bell-monitoring1.jpg",
      "/assets/realPhotos/door-bell-monitoring2.jpg",
      "/assets/realPhotos/door-bell-monitoring3.jpg",
    ],
    serviceRate: "Rs 899 per year",
  },
  {
    id: "human-help",
    name: "Human Help",
    description:
      "QR codes designed to offer personalized support and assistance for individuals with disabilities.",
    icon: require("../assets/servicesIcons/humanHelp.png"),
    realPhotos: [
      "/assets/realPhotos/human-help1.jpg",
      "/assets/realPhotos/human-help2.jpg",
      "/assets/realPhotos/human-help3.jpg",
    ],
    serviceRate: "Rs 1299 per year",
  },

  {
    id: "personal-profile",
    name: "Personal Profile",
    description:
      "QR codes designed to offer personalized support and assistance for individuals with disabilities.",
    icon: require("../assets/servicesIcons/profile.png"),
    realPhotos: [
      "/assets/realPhotos/personal-profile1.jpg",
      "/assets/realPhotos/personal-profile2.jpg",
      "/assets/realPhotos/personal-profile3.jpg",
    ],
    serviceRate: "Rs 1299 per year",
  },
  {
    id: "handicap",
    name: "Handicap Assist",
    description:
      "QR codes designed to offer personalized support and assistance for individuals with disabilities.",
    icon: require("../assets/servicesIcons/handicap.png"),
    realPhotos: [
      "/assets/realPhotos/handicap-assist1.jpg",
      "/assets/realPhotos/handicap-assist2.jpg",
      "/assets/realPhotos/handicap-assist3.jpg",
    ],
    serviceRate: "Rs 1299 per year",
  },

  {
    id: "veer-samman",
    name: "Veer Samman",
    description:
      "QR-enabled services to honor and protect our heroes, ensuring their legacy is preserved.",
    icon: require("../assets/servicesIcons/military-man.png"),
    realPhotos: [
      "/assets/realPhotos/veer-samman1.jpg",
      "/assets/realPhotos/veer-samman2.jpg",
      "/assets/realPhotos/veer-samman3.jpg",
    ],
    serviceRate: "Rs 1599 per year",
  },
  {
    id: "building-history",
    name: "Building History",
    description:
      "Explore the rich histories of buildings and landmarks with QR codes that tell their stories.",
    icon: require("../assets/servicesIcons/hawa-mahal.png"),
    realPhotos: [
      "/assets/realPhotos/building-history1.jpg",
      "/assets/realPhotos/building-history2.jpg",
      "/assets/realPhotos/building-history3.jpg",
    ],
    serviceRate: "Rs 1199 per year",
  },
];
