import React, { useState, useEffect } from "react";

const Carousel = () => {
  // Sample slides data (replace with your images/content)
  const slides = [
    {
      id: 1,
      image: require("../assets/images/menu/mc-1.jpeg"),
      alt: "Slide 1",
      title: "Digital Menu Card",
      //   description: "Menu Card for all resturent.",
    },
    {
      id: 2,
      image: require("../assets/images/menu/mc-2.jpeg"),
      alt: "Slide 2",
      //   title: "Innovative Solutions",
      //   description: "Simple, effective tools for emergencies.",
    },
    {
      id: 3,
      image: require("../assets/images/menu/mc-3.jpeg"),
      alt: "Slide 3",
      //   title: "Digital Menu Card",
      //   description: "Fast, Impressive, Your Resturent Helper.",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  // Auto-slide functionality
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(slideInterval); // Cleanup on unmount
  }, [slides.length]);

  // Manual navigation
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="relative w-full h-auto overflow-hidden bg-[#00133e]">
      <div className="max-w-8xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8 text-white">
          Change Life,<br></br> Digital Life
        </h2>
      </div>
      {/* Slides */}
      <div
        className="flex transition-transform duration-700 ease-in-out"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides.map((slide) => (
          <div
            key={slide.id}
            className="min-w-full h-auto relative flex items-center justify-center"
          >
            <img
              src={slide.image}
              alt={slide.alt}
              className="w-full h-full object-cover"
            />
            {/* Overlay for text readability */}
            <div className="absolute inset-0 bg-black bg-opacity-10 flex items-center justify-center">
              <div className="text-balance text-white px-6 pt-20">
                <h2 className="text-4xl md:text-5xl font-bold mb-4">
                  {slide.title}
                </h2>
                <p className="text-lg md:text-xl">{slide.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Navigation Dots */}
      <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex space-x-3">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-3 h-3 rounded-full ${
              currentSlide === index ? "bg-white" : "bg-gray-400"
            } hover:bg-gray-200 transition-colors`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
