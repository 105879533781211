let menu = [
  {
    category: "Sweats",
    items: [
      {
        id: 3,
        name: "Gulab Jamun (2 Pieces)",
        price: 60,
        description: "Sweet gulab jamun, 2 pieces, a perfect dessert.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/3e8/e0672458da2dd2b9e781375b41cd83e8.jpg?output-format=webp", // Matches "Gulab Jamun (2pcs)"
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Kulfi (Tilli Wali)",
        price: 60,
        description: "Traditional kulfi with tilli, a cool dessert.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/cb0/c71636e3e9956f5a271a5c3df2b7acb0.jpg", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 23,
        name: "Gajar Halwa",
        price: 90,
        description: "Carrot pudding with ghee and nuts, warm and sweet.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/4ab/dcd7aab9d584c8e40f173156fad8f4ab.jpg",
        available: true,
        type: "Veg",
        size: null,
      },
    ],
  },
  {
    category: "Snacks",
    items: [
      {
        id: 1,
        name: "Paneer Kurkure",
        price: 270,
        description: "Crispy paneer kurkure, a savory snack.",
        image:
          "https://th.bing.com/th/id/OIP.g4bFd0LOrqDZdBToPVT0QwHaFj?rs=1&pid=ImgDetMain", // Matches "Paneer Kurmure"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Chilli Mashroom",
        price: 270,
        description: "Spicy chilli mushrooms, a flavorful snack.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c1e/03bfe86ec83ad3b334884f83fce60c1e.png", // Matches "Chilly Mushroom"
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Paneer Tikka",
        price: 260,
        description: "Marinated paneer tikka, grilled to perfection.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/825/16b8ed98c80300704c0251e59fb18825.jpg", // Matches "Paneer Tikka"
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Mashroom Kurkure",
        price: 260,
        description: "Crispy mushroom kurkure, a delightful snack.",
        image:
          "https://files.yappe.in/place/small/tales-spirits-viman-nagar-3491159.webp", // Matches "Mushroom Kurmure" but image is empty
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Chilli Paneer",
        price: 260,
        description: "Spicy chilli paneer, a popular Indo-Chinese snack.",
        image:
          "https://www.cookclickndevour.com/wp-content/uploads/2016/05/chilli-paneer-recipe-1.jpg", // Matches "Chilly Paneer"
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Mashroom Tikka",
        price: 240,
        description: "Marinated mushroom tikka, grilled and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f05/1929109c70086dcdb668bd9dcf3e6f05.jpg", // Matches "Mushroom Tikka"
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Krispi Corn",
        price: 200,
        description: "Crispy corn kernels, lightly spiced.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/31a/e53fa1fde70c5ea6cf2fa4db32eaf31a.jpg", // Matches "Crispy Corn"
        available: true,
        type: "Veg",
      },
      {
        id: 8,
        name: "Manchurian",
        price: 180,
        description: "Indo-Chinese manchurian, a savory snack.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/6f02b8b0b87cb93ce8a40c5f498121011634556481.png", // Matches "Veg. Manchurian"
        available: true,
        type: "Veg",
      },
      {
        id: 9,
        name: "Chilli Potato",
        price: 140,
        description: "Spicy chilli potatoes, a crispy snack.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1d3/0afe54ef08dc3a2d88d38c63929d81d3.jpg", // Matches "Chilly Potato"
        available: true,
        type: "Veg",
      },
      {
        id: 10,
        name: "Sweat Corn",
        price: 140,
        description: "Crispy corn snack with a unique twist.",
        image: "https://buffetfoods.in/wp-content/uploads/2024/08/69.jpg", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 11,
        name: "Finger Chips",
        price: 120,
        description: "Classic finger chips, crispy and golden.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/a0f/892fabb602a2a5b6512fa07cec266a0f.jpg", // Matches "French Fries"
        available: true,
        type: "Veg",
      },
      {
        id: 12,
        name: "Peanut Masala",
        price: 120,
        description: "Paneer with masala spices, a tasty snack.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5e3/0cd3efa8604faf90b01292de9604d5e3.jpg", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 13,
        name: "Paneer Roll",
        price: 120,
        description: "Paneer wrapped in a roll, spiced and savory.",
        image:
          "https://b.zmtcdn.com/data/pictures/9/19010489/be66ecab1c0faced7f1a5e2a7af45d5e_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 14,
        name: "Veg Roll",
        price: 80,
        description: "Vegetable roll, light and savory.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/eedfc4149fc89642b07c72507e4f84b01634556481.png", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 15,
        name: "Veg Noodles",
        price: 120,
        description: "Stir-fried veg noodles, Indo-Chinese style.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/93a/461682ab173f4978d7ed05102119193a.jpg", // Matches "Chowmein"
        available: true,
        type: "Veg",
      },
      {
        id: 16,
        name: "Hakka Noodles",
        price: 160,
        description: "Classic hakka noodles with vegetables.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/93a/461682ab173f4978d7ed05102119193a.jpg", // Matches "Chowmein"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Momos",
    items: [
      {
        id: 1,
        name: "Veg Kurkure Momos",
        price: 160,
        description: "Crispy veg kurkure momos, a delightful bite.",
        image:
          "https://b.zmtcdn.com/data/dish_images/6559282bb2f61a2439b08f49e90936f81614098010.png", // Matches "Veg Momos"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Paneer Fried Momos",
        price: 160,
        description: "Fried paneer momos, savory and crispy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e62/babb1184d351511d5d843fc59d224e62.jpg", // Matches "Paneer Fry Momos"
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Paneer Steam Momos",
        price: 150,
        description: "Steamed paneer momos, soft and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e14/38526d0e8811d14e5e9ead61f6dbee14.jpeg", // Matches "Paneer Momos"
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Veg Fried Momos",
        price: 140,
        description: "Fried veg momos, crispy and tasty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/8d2/644d0d7b4e3b1de9087962356a2138d2.jpg", // Matches "Veg. Fry Momos"
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Veg Steam Momos",
        price: 120,
        description: "Steamed veg momos, light and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_images/6559282bb2f61a2439b08f49e90936f81614098010.png", // Matches "Veg Momos"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Chaap",
    items: [
      {
        id: 1,
        name: "Chilli Chaap",
        price: 140,
        description: "Spicy chilli chaap, a flavorful dish.",
        image:
          "https://b.zmtcdn.com/data/pictures/7/19010457/7583fb36ae31f75af1bfd61423aab62d_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A", // Matches "Chilly Chhap"
        available: true,
        type: "Veg",
      },
      {
        id: 1,
        name: "Chilli Chaap",
        price: 230,
        description: "Spicy chilli chaap, a flavorful dish.",
        image:
          "https://b.zmtcdn.com/data/pictures/7/19010457/7583fb36ae31f75af1bfd61423aab62d_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A", // Matches "Chilly Chhap"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Gravy Chaap",
        price: 130,
        description: "Chaap in a rich gravy, savory and delicious.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/efa0232fa263fd8b97ecf1c009e1b4801632716697.png", // Matches "Chhap Curry"
        available: true,

        type: "Veg",
      },
      {
        id: 2,
        name: "Gravy Chaap",
        price: 230,
        description: "Chaap in a rich gravy, savory and delicious.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/efa0232fa263fd8b97ecf1c009e1b4801632716697.png", // Matches "Chhap Curry"
        available: true,

        type: "Veg",
      },
      {
        id: 3,
        name: "Malai Chaap",
        price: 130,
        description: "Creamy malai chaap, rich and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b4f/1539379836e11b9c2e1871d0828a0b4f.jpg", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Malai Chaap",
        price: 220,
        description: "Creamy malai chaap, rich and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b4f/1539379836e11b9c2e1871d0828a0b4f.jpg", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Afghani Chaap",
        price: 130,
        description: "Afghani-style chaap, creamy and spiced.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6a6/2504a62907e58ee7631881dea47306a6.jpg", // Matches "Afghani Chhap"
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Afghani Chaap",
        price: 210,
        description: "Afghani-style chaap, creamy and spiced.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6a6/2504a62907e58ee7631881dea47306a6.jpg", // Matches "Afghani Chhap"
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Tandoori Chaap",
        price: 120,
        description: "Tandoori chaap, grilled and smoky.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/9c9/8cd9cb6041966d833ea2d1e14e4a89c9.jpg", // Matches "Tandoori Chhap"
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Tandoori Chaap",
        price: 190,
        description: "Tandoori chaap, grilled and smoky.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/9c9/8cd9cb6041966d833ea2d1e14e4a89c9.jpg", // Matches "Tandoori Chhap"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Papad",
    items: [
      {
        id: 1,
        name: "Masala Papad",
        price: 80,
        description: "Crispy papad topped with masala.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/64d5639c3885d5b96f1d772c897765571632716606.png", // Matches "Masala Papad"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Roasted Papad",
        price: 20,
        description: "Simple roasted papad, light and crispy.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/64d5639c3885d5b96f1d772c897765571632716606.png", // Matches "Papad"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Sabji",
    items: [
      {
        id: 1,
        name: "Dahi Kali Mirch Paneer",
        price: 300,
        description: "Paneer in a creamy yogurt and black pepper sauce.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/63e/37d1de2e8d13b1b986af259fb9f8663e.jpg", // Matches "Paneer Kali Mirch"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Paneer Tikka Masala",
        price: 300,
        description: "Grilled paneer in a rich tikka masala gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0c3/c467664a64e7c2a5f600e4da9b8170c3.jpg", // Matches "Paneer Tikka Masala"
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Dahi Kali Mirch Chaap",
        price: 280,
        description: "Soya chaap in a yogurt and black pepper sauce.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6a6/2504a62907e58ee7631881dea47306a6.jpg", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Malai Kofta",
        price: 280,
        description: "Soft koftas in a creamy malai gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/3bf/ee9bd4017f845e70a1164b4c608043bf.jpg", // Matches "Malai Kofi"
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Paneer Lavabdar",
        price: 280,
        description: "Paneer in a rich and creamy tomato-based gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/555/a08117de2c546b76399461affa626555.jpg", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Paneer Pasanda",
        price: 280,
        description: "Stuffed paneer in a creamy and flavorful gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/4bd/0ddc1ae94e0627d4d75648f21e97d4bd.jpg", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Paneer Do Pyaja",
        price: 260,
        description: "Paneer cooked with onions in a spiced gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d74/23870727944c2cfa23890edecdbddd74.jpg", // Matches "Paneer-Do-Pyaza"
        available: true,
        type: "Veg",
      },
      {
        id: 8,
        name: "Paneer Butter Masala",
        price: 260,
        description: "Paneer in a rich buttery tomato gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_images/cbe9c1c456aac583487d1670f05ee8411604140457.jpeg", // Matches "Paneer Butter Masala"
        available: true,
        type: "Veg",
      },
      {
        id: 9,
        name: "Mashroom Masala",
        price: 260,
        description: "Mushrooms cooked in a spiced masala gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/55f/ca79cf0de7a5056ef2c1c3e22568855f.png", // Matches "Mushroom Masala"
        available: true,
        type: "Veg",
      },
      {
        id: 10,
        name: "Kadhai Paneer",
        price: 240,
        description: "Paneer cooked with bell peppers in a kadhai masala.",
        image:
          "https://b.zmtcdn.com/data/dish_images/773ac51db83e23b0e7fdfb74f41952f21602873820.png", // Matches "Kadhai Paneer"
        available: true,
        type: "Veg",
      },
      {
        id: 11,
        name: "Paneer Bhurji",
        price: 240,
        description: "Scrambled paneer with spices and vegetables.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c71/32eb882c2cdeae077cbcac470fce0c71.jpg", // Matches "Paneer Bhurji"
        available: true,
        type: "Veg",
      },
      {
        id: 12,
        name: "Matar Mashroom",
        price: 240,
        description: "Peas and mushrooms in a spiced gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/bef/4ba204955d1d7156bdba9bc2a1f04bef.jpg", // Matches "Matar Mushroom"
        available: true,
        type: "Veg",
      },
      {
        id: 13,
        name: "Shahi Paneer",
        price: 220,
        description: "Paneer in a royal creamy and nutty gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/846/08eaa3712f687cad95bb626815c25846.jpg", // Matches "Shahi Paneer"
        available: true,
        type: "Veg",
      },
      {
        id: 14,
        name: "Matar Paneer",
        price: 220,
        description: "Peas and paneer in a spiced tomato gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f69/0e216337abfcb02e18e48f856517ff69.jpg", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 15,
        name: "Palak Paneer",
        price: 220,
        description: "Paneer in a creamy spinach gravy.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/dc3c6d992ccd501dbc4e2975c0ed9b551634556106.png", // Matches "Aloo Palak" (similar spinach-based dish)
        available: true,
        type: "Veg",
      },
      {
        id: 16,
        name: "Dal Makhani",
        price: 220,
        description: "Creamy black lentils cooked with butter and spices.",
        image:
          "https://b.zmtcdn.com/data/dish_images/f42f1bf9f33a25cca20b9fb206f9e96c1602873763.png", // Matches "Dal Makhani"
        available: true,
        type: "Veg",
      },
      {
        id: 17,
        name: "Mix Veg",
        price: 220,
        description: "Assorted vegetables in a spiced gravy.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/503/28abc47cf13c584dde2b469005822503.jpg", // Matches "Mix Veg."
        available: true,
        type: "Veg",
      },
      {
        id: 18,
        name: "Chana Masala",
        price: 200,
        description: "Chickpeas cooked in a spiced tomato gravy.",
        image:
          "https://www.seriouseats.com/thmb/IyNUz5Jz1RWpvGtb1x2U-oToe0c=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__images__2016__03__20160328-channa-masala-recipe-8-2ab6195d55ae4068a8c1d36d7371f5f7.jpg", // Matches "Channa Masala"
        available: true,
        type: "Veg",
      },
      {
        id: 19,
        name: "Aloo Matar",
        price: 140,
        description: "Potatoes and peas in a spiced gravy.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/e33ae9cb5decbc500bd2c0176ca827cf1634556106.png", // Matches "Aloo Matar"
        available: true,
        type: "Veg",
      },
      {
        id: 20,
        name: "Aloo Palak",
        price: 140,
        description: "Potatoes in a creamy spinach gravy.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/dc3c6d992ccd501dbc4e2975c0ed9b551634556106.png", // Matches "Aloo Palak"
        available: true,
        type: "Veg",
      },
      {
        id: 21,
        name: "Dal Fry",
        price: 140,
        description: "Lentils cooked with spices and tempered with ghee.",
        image:
          "https://b.zmtcdn.com/data/dish_images/d6715e3d7ef71f577f0aa20bfa263f5e1604141101.jpeg", // Matches "Dal Fry"
        available: true,
        type: "Veg",
      },
      {
        id: 22,
        name: "Dal Tadka",
        price: 140,
        description: "Lentils with a spiced tempering.",
        image:
          "https://b.zmtcdn.com/data/dish_images/d6715e3d7ef71f577f0aa20bfa263f5e1604141101.jpeg", // Matches "Dal Fry" (similar tempering style)
        available: true,
        type: "Veg",
      },
      {
        id: 23,
        name: "Sev Bhaji",
        price: 140,
        description: "Vegetable dish, lightly spiced.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/71c/2d970b213a269f69c2638ebe32cae71c.jpg", // No direct match
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Salad",
    items: [
      {
        id: 1,
        name: "Green / Mix Salad",
        price: 80,
        description: "Fresh green or mixed salad, healthy and light.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5e4/4bef8e629460ccef47da6163a58745e4.jpg", // Matches "Green Salad"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Cream Salad",
        price: 120,
        description: "Special Cream salad, fresh and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/962/961f233d5352e67cc850446c14462962.jpg", // No direct match
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Sweats",
    items: [
      {
        id: 3,
        name: "Gulab Jamun (2 Pieces)",
        price: 60,
        description: "Sweet gulab jamun, 2 pieces, a perfect dessert.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/3e8/e0672458da2dd2b9e781375b41cd83e8.jpg?output-format=webp", // Matches "Gulab Jamun (2pcs)"
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Kulfi (Tilli Wali)",
        price: 60,
        description: "Traditional kulfi with tilli, a cool dessert.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/cb0/c71636e3e9956f5a271a5c3df2b7acb0.jpg", // No direct match
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Chinese",
    items: [
      {
        id: 1,
        name: "Cheese Burger",
        price: 80,
        description: "Cheese burger, a quick and tasty bite.",
        image:
          "https://b.zmtcdn.com/data/dish_images/52d584231ffb5f1ee127e2be4bf84fb31630415033.png", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Burger",
        price: 60,
        description: "Simple veg burger, light and savory.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/ec2/01af25d0bf7a0783529ee66a8f95bec2.png?output-format=webp", // No direct match
        available: true,
        type: "Veg",
      },
    ],
  },

  {
    category: "Raita",
    items: [
      {
        id: 1,
        name: "Bundi Raita",
        price: 80,
        description: "Yogurt with bundi, a cooling side.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/04f/dbf2e1aa3c4e61577798e9f0340d404f.jpg", // Matches "Boondi Raita"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Mix Raita",
        price: 80,
        description: "Mixed vegetable raita, fresh and cooling.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/cdc/1550d88421dc1d56aab0517ad394ccdc.jpg", // Matches "Mix Raita"
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Dahi Raita",
        price: 60,
        description: "Plain yogurt raita, simple and refreshing.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e6c/c8e37f026bb851cb4c7c035562f2de6c.png", // Matches "Plain Raita"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Roti / Paratha",
    items: [
      {
        id: 1,
        name: "Paneer Paratha",
        price: 100,
        description: "Paratha stuffed with paneer, savory and filling.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/33a/35afee41df6a60bfbfd17ac005e6133a.jpg", // Matches "Paneer Parantha"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Mix Paratha",
        price: 90,
        description: "Paratha with mixed stuffing, tasty and hearty.",
        image:
          "https://b.zmtcdn.com/data/pictures/4/21129034/955eed08afd2cca800355aa88ca3c5d4_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A", // Matches "Mix Parantha"
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Pyaj / Gobi Paratha",
        price: 80,
        description: "Paratha stuffed with onion or cauliflower.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/2b5a5b533473aada22015966f668e30e1633434990.png", // Matches "Onion Parantha"
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Aloo / Muli Paratha",
        price: 70,
        description: "Paratha stuffed with potato or radish.",
        image:
          "https://b.zmtcdn.com/data/pictures/4/21129034/955eed08afd2cca800355aa88ca3c5d4_o2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A", // Matches "Aloo Parantha"
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Butter Naan",
        price: 90,
        description: "Soft naan with butter, a rich bread.",
        image:
          "https://b.zmtcdn.com/data/dish_images/afa3add62f60d39ed4e9bc355a970ee51604140116.png", // Matches "Butter Naan"
        available: true,
        type: "Veg",
      },
      {
        id: 6,
        name: "Garlic Naan",
        price: 70,
        description: "Naan with garlic flavor, soft and aromatic.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d4e/c62a165cb8979b100b79156cd69c6d4e.jpg", // Matches "Garlic Naan"
        available: true,
        type: "Veg",
      },
      {
        id: 7,
        name: "Butter Naan",
        price: 40,
        description: "Smaller butter naan, soft and rich.",
        image:
          "https://b.zmtcdn.com/data/dish_images/afa3add62f60d39ed4e9bc355a970ee51604140116.png", // Matches "Butter Naan"
        available: true,
        type: "Veg",
      },
      {
        id: 8,
        name: "Lachha Paratha",
        price: 35,
        description: "Layered lachha paratha, crispy and flaky.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/2b5a5b533473aada22015966f668e30e1633434990.png", // Matches "Lachedar Prantha"
        available: true,
        type: "Veg",
      },
      {
        id: 9,
        name: "Tawa Paratha",
        price: 35,
        description: "Paratha cooked on tawa, soft and simple.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5e1/22b1bb11eb7eee0d51313fc977a965e1.jpg", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 10,
        name: "Missi Bhaji Roti",
        price: 30,
        description: "Missi roti with bhaji flavor, rustic and tasty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/11a/41615401cf34854582eac90ccea6011a.jpg", // Matches "Missi Roti Butter"
        available: true,
        type: "Veg",
      },
      {
        id: 11,
        name: "Missi Roti",
        price: 25,
        description: "Traditional missi roti, spiced and hearty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/11a/41615401cf34854582eac90ccea6011a.jpg", // Matches "Missi Roti Butter"
        available: true,
        type: "Veg",
      },
      {
        id: 12,
        name: "Pyaj Roti",
        price: 20,
        description: "Roti with onion, simple and flavorful.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f1a/f532c1ce53dad35553d26a1c7dfacf1a.jpg", // Matches "Pyaz Roti Butter"
        available: true,
        type: "Veg",
      },
      {
        id: 13,
        name: "Gobi Butter Roti",
        price: 20,
        description: "Roti with cauliflower and butter, tasty.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f1a/f532c1ce53dad35553d26a1c7dfacf1a.jpg", // No direct match
        available: true,
        type: "Veg",
      },
      {
        id: 14,
        name: "Butter Roti",
        price: 15,
        description: "Simple roti with butter, soft and light.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0d7/5c42d9a98976152ec3cc5e366e6f80d7.jpg", // Matches "Butter Chapati"
        available: true,
        type: "Veg",
      },
      {
        id: 15,
        name: "Roti Plain",
        price: 10,
        description: "Plain roti, simple and soft.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/97f/599579bff3c638bc9c3be31a0ceb797f.jpg", // Matches "Plain Roti"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Nashta",
    items: [
      {
        id: 1,
        name: "Gheela Sendwich",
        price: 120,
        description: "Gheela sandwich, a flavorful breakfast.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/fc641efbb73b10484257f295ef0b9b981634401116.png", // Matches "Sandwich"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Bread Sendwich",
        price: 100,
        description: "Classic bread sandwich, light and tasty.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/fc641efbb73b10484257f295ef0b9b981634401116.png", // Matches "Sandwich"
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Bread Butter Toast",
        price: 80,
        description: "Bread with butter, toasted and simple.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b41/d8e2d1a79dc56f434a50c947e1ffbb41.jpg", // Matches "Butter Toast"
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Rice",
    items: [
      {
        id: 1,
        name: "Veg Biryani",
        price: 220,
        description: "Flavorful veg biryani, aromatic and spiced.",
        image:
          "https://b.zmtcdn.com/data/dish_images/a098f678350380809a464fb5aaf7128a1602873799.png", // Matches "Veg. Biryani"
        available: true,
        type: "Veg",
      },
      {
        id: 2,
        name: "Veg Fried Rice",
        price: 200,
        description: "Stir-fried veg rice, Indo-Chinese style.",
        image:
          "https://th.bing.com/th/id/OIP.ELTYHuwJWM3L7_7x1Wc8cwHaEO?rs=1&pid=ImgDetMain", // Matches "Fried Rice"
        available: true,
        type: "Veg",
      },
      {
        id: 3,
        name: "Veg Pulao",
        price: 180,
        description: "Veg pulao, lightly spiced and aromatic.",
        image:
          "https://b.zmtcdn.com/data/dish_images/a098f678350380809a464fb5aaf7128a1602873799.png", // Matches "Veg. Pulao"
        available: true,
        type: "Veg",
      },
      {
        id: 4,
        name: "Jeera Rice",
        price: 120,
        description: "Rice with cumin, simple and flavorful.",
        image:
          "https://th.bing.com/th/id/OIP.ELTYHuwJWM3L7_7x1Wc8cwHaEO?rs=1&pid=ImgDetMain", // Matches "Jeera Rice"
        available: true,
        type: "Veg",
      },
      {
        id: 5,
        name: "Plain Rice",
        price: 100,
        description: "Steamed plain rice, a simple side.",
        image:
          "https://th.bing.com/th/id/OIP.mpy5jO_n3CticsjQHDFQGwHaHa?rs=1&pid=ImgDetMain", // Matches "Plain Rice"
        available: true,
        type: "Veg",
      },
    ],
  },
];

const metaData = {
  name: "Demo Menu Dhaba",
  location: "Palwal",
  whatsappNo: "7056070407",
  mapLocation: "https://maps.app.goo.gl/i9KED7CUaUZP1Zhi8",
  paymentUpi:
    "upi://pay?pa=7404121102@ybl@ibl&pn=OWNER%20DEKHLE&mc=0000&mode=02&purpose=00",
  instagram: "",
  reviewLink:
    "https://www.google.com/search?q=frontire+resturent+palwal&sca_esv=108ac3b3f1b82d53&sxsrf=AHTn8zoQIiC6LZZPqmTyU4OYfe9-ZHnw0g%3A1740977833200&source=hp&ei=qTbFZ9PoCfi94-EP96r10QE&iflsig=ACkRmUkAAAAAZ8VEuTqNJNw7ZoeO9HnVkF64Ujf5WOwt&ved=0ahUKEwiT4brqj-2LAxX43jgGHXdVPRoQ4dUDCBo&uact=5&oq=frontire+resturent+palwal&gs_lp=Egdnd3Mtd2l6Ihlmcm9udGlyZSByZXN0dXJlbnQgcGFsd2FsMgcQIRigARgKMgcQIRigARgKMgcQIRigARgKSLYCUABYAHAAeACQAQCYAcIBoAHCAaoBAzAuMbgBA8gBAPgBAvgBAZgCAaACxgGYAwCSBwMwLjGgB5oF&sclient=gws-wiz#lrd=0x39732d005bd3fd39:0xf00a21eb628749b1,3,,,,",
  qrCodes: [],
};
export default { metaData, menu };
