import { useParams } from "react-router-dom";
import MenuCard from "./MenuCard";
import { getMenu } from "../../MENULIST/Palwal/MenuMap.js/AllMenu";
import MenuCardParty from "./MenuCardParty";
import PartyDemoMenu from "../../MENULIST/Palwal/PartyDemoMenu";

const MenuFromPartyID = () => {
  const { id } = useParams();
  console.log(id);
  const menuData = PartyDemoMenu;
  console.log(menuData);
  return (
    <>
      <MenuCardParty
        metaData={menuData.metaData}
        menu={menuData.menu}
        code={menuData.code}
        table={menuData.table}
      />
    </>
  );
};

export default MenuFromPartyID;
