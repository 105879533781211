let menu = [
  {
    category: "Cakes",
    items: [
      {
        id: 1234,
        name: "KitKat",
        price: 525,
        description:
          "A rich eggless chocolate cake layered with creamy KitKat chunks, perfect for chocolate lovers.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b85/dec8378345c09aaaa7fa915386535b85.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 5678,
        name: "KitKat",
        price: 950,
        description:
          "A rich eggless chocolate cake layered with creamy KitKat chunks, perfect for chocolate lovers.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b85/dec8378345c09aaaa7fa915386535b85.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 9012,
        name: "Choco Mud",
        price: 475,
        description:
          "A moist eggless chocolate mud cake with a fudgy texture, ideal for indulgent moments.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/505/a801f4305f3d1437f093a4150431d505.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 3456,
        name: "Choco Mud",
        price: 850,
        description:
          "A moist eggless chocolate mud cake with a fudgy texture, ideal for indulgent moments.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/505/a801f4305f3d1437f093a4150431d505.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 7890,
        name: "Fresh Fruit",
        price: 450,
        description:
          "A light eggless vanilla cake topped with fresh seasonal fruits, offering a refreshing taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/990/7482907eb8bb5c97ca90995ba94d9990.jpg",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 2345,
        name: "Fresh Fruit",
        price: 900,
        description:
          "A light eggless vanilla cake topped with fresh seasonal fruits, offering a refreshing taste.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/990/7482907eb8bb5c97ca90995ba94d9990.jpg",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 6789,
        name: "Red Velvet",
        price: 550,
        description:
          "A classic eggless red velvet cake with a velvety texture and a hint of cocoa, finished with cream cheese frosting.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/cf4/d67b771d3bafa6efffed9845ef28acf4.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 1230,
        name: "Red Velvet",
        price: 1025,
        description:
          "A classic eggless red velvet cake with a velvety texture and a hint of cocoa, finished with cream cheese frosting.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/cf4/d67b771d3bafa6efffed9845ef28acf4.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 4567,
        name: "Pineapple",
        price: 450,
        description:
          "A tropical eggless pineapple cake with juicy pineapple chunks and a light, fluffy texture.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e57/2da637d4ec22641c3746942a3be3ee57.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 8901,
        name: "Pineapple",
        price: 780,
        description:
          "A tropical eggless pineapple cake with juicy pineapple chunks and a light, fluffy texture.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/e57/2da637d4ec22641c3746942a3be3ee57.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 3450,
        name: "Strawberry",
        price: 450,
        description:
          "A delightful eggless strawberry cake with fresh strawberry flavors and a soft, spongy texture.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/345/a016739e27f8dfb1b5434cea67405345.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 6780,
        name: "Strawberry",
        price: 780,
        description:
          "A delightful eggless strawberry cake with fresh strawberry flavors and a soft, spongy texture.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/345/a016739e27f8dfb1b5434cea67405345.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 9013,
        name: "Choco Walnut Cake",
        price: 450,
        description:
          "A nutty eggless chocolate cake with crunchy walnuts, offering a perfect balance of flavors.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/505/a801f4305f3d1437f093a4150431d505.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 2346,
        name: "Choco Walnut Cake",
        price: 800,
        description:
          "A nutty eggless chocolate cake with crunchy walnuts, offering a perfect balance of flavors.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/505/a801f4305f3d1437f093a4150431d505.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 5671,
        name: "Butterscotch",
        price: 450,
        description:
          "A sweet eggless butterscotch cake with a caramelized flavor and crunchy praline bits.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f3d/3f3350fdf37c6e120597ab09b15d6f3d.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 8902,
        name: "Butterscotch",
        price: 800,
        description:
          "A sweet eggless butterscotch cake with a caramelized flavor and crunchy praline bits.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f3d/3f3350fdf37c6e120597ab09b15d6f3d.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 3451,
        name: "Oreo",
        price: 525,
        description:
          "A creamy eggless Oreo cake with layers of crushed Oreo cookies and a chocolatey finish.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b85/dec8378345c09aaaa7fa915386535b85.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 6781,
        name: "Oreo",
        price: 950,
        description:
          "A creamy eggless Oreo cake with layers of crushed Oreo cookies and a chocolatey finish.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b85/dec8378345c09aaaa7fa915386535b85.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 9014,
        name: "Chocochip",
        price: 500,
        description:
          "A delectable eggless chocochip cake packed with gooey chocolate chips in every bite.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6e5/95b210ec6560687320b1c8ef23cd46e5.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 2347,
        name: "Chocochip",
        price: 850,
        description:
          "A delectable eggless chocochip cake packed with gooey chocolate chips in every bite.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6e5/95b210ec6560687320b1c8ef23cd46e5.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 5672,
        name: "Blueberry",
        price: 500,
        description:
          "A fruity eggless blueberry cake with bursts of tangy blueberries and a soft texture.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/588/26a5855caa808761d0283936133e6588.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 8903,
        name: "Blueberry",
        price: 1050,
        description:
          "A fruity eggless blueberry cake with bursts of tangy blueberries and a soft texture.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/588/26a5855caa808761d0283936133e6588.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 3452,
        name: "Truffle",
        price: 500,
        description:
          "A rich eggless truffle cake with a decadent chocolate truffle frosting, perfect for celebrations.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/65c/76ad1bdbcfb3082b8bfa8c346723a65c.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 6782,
        name: "Truffle",
        price: 900,
        description:
          "A rich eggless truffle cake with a decadent chocolate truffle frosting, perfect for celebrations.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/65c/76ad1bdbcfb3082b8bfa8c346723a65c.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
    ],
  },
  {
    category: "Cheese Cakes",
    items: [
      {
        id: 1235,
        name: "Baked Cheesecake",
        price: 90,
        description:
          "A creamy baked cheesecake with a smooth texture and a buttery biscuit base.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5f1/417a70197d6871713e25a7834f7435f1.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Slice",
      },
      {
        id: 5679,
        name: "Baked Cheesecake",
        price: 450,
        description:
          "A creamy baked cheesecake with a smooth texture and a buttery biscuit base.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5f1/417a70197d6871713e25a7834f7435f1.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 9015,
        name: "Baked Cheesecake",
        price: 850,
        description:
          "A creamy baked cheesecake with a smooth texture and a buttery biscuit base.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5f1/417a70197d6871713e25a7834f7435f1.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 2348,
        name: "Blueberry",
        price: 110,
        description:
          "A luscious blueberry cheesecake with a tangy blueberry topping and a creamy filling.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6e1/3ccf9da1e4ae0fae92910462098e86e1.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Slice",
      },
      {
        id: 6783,
        name: "Blueberry",
        price: 550,
        description:
          "A luscious blueberry cheesecake with a tangy blueberry topping and a creamy filling.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6e1/3ccf9da1e4ae0fae92910462098e86e1.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 8904,
        name: "Blueberry",
        price: 1050,
        description:
          "A luscious blueberry cheesecake with a tangy blueberry topping and a creamy filling.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/6e1/3ccf9da1e4ae0fae92910462098e86e1.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 3453,
        name: "Biscoff",
        price: 120,
        description:
          "A delightful Biscoff cheesecake with a caramelized biscuit spread and a crunchy base.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b8b/0346c55f073201387e0fd34e26c64b8b.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Slice",
      },
      {
        id: 5673,
        name: "Biscoff",
        price: 575,
        description:
          "A delightful Biscoff cheesecake with a caramelized biscuit spread and a crunchy base.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b8b/0346c55f073201387e0fd34e26c64b8b.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 9016,
        name: "Biscoff",
        price: 1100,
        description:
          "A delightful Biscoff cheesecake with a caramelized biscuit spread and a crunchy base.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b8b/0346c55f073201387e0fd34e26c64b8b.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
      {
        id: 2349,
        name: "Hazelnut",
        price: 110,
        description:
          "A rich hazelnut cheesecake with a nutty flavor and a velvety smooth texture.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5f1/417a70197d6871713e25a7834f7435f1.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "Slice",
      },
      {
        id: 6784,
        name: "Hazelnut",
        price: 530,
        description:
          "A rich hazelnut cheesecake with a nutty flavor and a velvety smooth texture.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5f1/417a70197d6871713e25a7834f7435f1.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1/2 KG",
      },
      {
        id: 8905,
        name: "Hazelnut",
        price: 1050,
        description:
          "A rich hazelnut cheesecake with a nutty flavor and a velvety smooth texture.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/5f1/417a70197d6871713e25a7834f7435f1.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
        size: "1 KG",
      },
    ],
  },
  {
    category: "Cookies",
    items: [
      {
        id: 1236,
        name: "Coconut",
        price: 110,
        description:
          "Crisp coconut cookies with a tropical flavor and a chewy texture.",
        image:
          "https://i.pinimg.com/474x/0f/ca/ff/0fcaff167f9ea1817bf6b89a0b8a53b9.jpg",
        available: true,
        type: "Veg",
        size: "Small (150gms)",
      },
      {
        id: 5674,
        name: "Coconut",
        price: 150,
        description:
          "Crisp coconut cookies with a tropical flavor and a chewy texture.",
        image:
          "https://i.pinimg.com/474x/0f/ca/ff/0fcaff167f9ea1817bf6b89a0b8a53b9.jpg",
        available: true,
        type: "Veg",
        size: "250gms",
      },
      {
        id: 9017,
        name: "Honey Oat",
        price: 110,
        description:
          "Wholesome honey oat cookies with a sweet honey glaze and a hearty oat crunch.",
        image:
          "https://bigoven-res.cloudinary.com/image/upload/f_auto,q_auto/t_recipe-256/oatmeal-cookies-quaker-oats-40cb4e.jpg",
        available: true,
        type: "Veg",
        size: "Small (150gms)",
      },
      {
        id: 2350,
        name: "Honey Oat",
        price: 150,
        description:
          "Wholesome honey oat cookies with a sweet honey glaze and a hearty oat crunch.",
        image:
          "https://bigoven-res.cloudinary.com/image/upload/f_auto,q_auto/t_recipe-256/oatmeal-cookies-quaker-oats-40cb4e.jpg",
        available: true,
        type: "Veg",
        size: "250gms",
      },
      {
        id: 6785,
        name: "Chocolate Choco Chip",
        price: 120,
        description:
          "Soft chocolate cookies loaded with gooey choco chips for a double chocolate treat.",
        image:
          "https://images.stockcake.com/public/2/a/6/2a6022d3-30ed-4215-8000-c262cd61db0b_medium/fresh-baked-cookies-stockcake.jpg",
        available: true,
        type: "Veg",
        size: "Small (150gms)",
      },
      {
        id: 8906,
        name: "Chocolate Choco Chip",
        price: 180,
        description:
          "Soft chocolate cookies loaded with gooey choco chips for a double chocolate treat.",
        image:
          "https://images.stockcake.com/public/2/a/6/2a6022d3-30ed-4215-8000-c262cd61db0b_medium/fresh-baked-cookies-stockcake.jpg",
        available: true,
        type: "Veg",
        size: "250gms",
      },
      {
        id: 3454,
        name: "Coconut Sugar Free",
        price: 120,
        description:
          "Sugar-free coconut cookies with a tropical taste, perfect for guilt-free snacking.",
        image:
          "https://i.pinimg.com/474x/0f/ca/ff/0fcaff167f9ea1817bf6b89a0b8a53b9.jpg",
        available: true,
        type: "Veg",
        size: "Small (150gms)",
      },
      {
        id: 5675,
        name: "Coconut Sugar Free",
        price: 180,
        description:
          "Sugar-free coconut cookies with a tropical taste, perfect for guilt-free snacking.",
        image:
          "https://i.pinimg.com/474x/0f/ca/ff/0fcaff167f9ea1817bf6b89a0b8a53b9.jpg",
        available: true,
        type: "Veg",
        size: "250gms",
      },
      {
        id: 9018,
        name: "Honey Oat Sugar Free",
        price: 120,
        description:
          "Sugar-free honey oat cookies with a wholesome oat texture and a subtle sweetness.",
        image:
          "https://bigoven-res.cloudinary.com/image/upload/f_auto,q_auto/t_recipe-256/oatmeal-cookies-quaker-oats-40cb4e.jpg",
        available: true,
        type: "Veg",
        size: "Small (150gms)",
      },
      {
        id: 2351,
        name: "Honey Oat Sugar Free",
        price: 180,
        description:
          "Sugar-free honey oat cookies with a wholesome oat texture and a subtle sweetness.",
        image:
          "https://bigoven-res.cloudinary.com/image/upload/f_auto,q_auto/t_recipe-256/oatmeal-cookies-quaker-oats-40cb4e.jpg",
        available: true,
        type: "Veg",
        size: "250gms",
      },
    ],
  },
  {
    category: "Snacks",
    items: [
      {
        id: 1237,
        name: "Paneer Roll",
        price: 60,
        description:
          "A savory roll stuffed with creamy paneer and spices, wrapped in a flaky pastry.",
        image:
          "https://b.zmtcdn.com/data/o2_assets/ec99015317225170a3ab279ad96a72881634556417.png",
        available: true,
        type: "Veg",
      },
      {
        id: 5676,
        name: "Garlic Bread Roll",
        price: 60,
        description:
          "A soft roll filled with garlic butter and herbs, baked to golden perfection.",
        image:
          "https://b.zmtcdn.com/data/dish_images/c95d79eedec450ba3131108afcb06dbd1613653750.png",
        available: true,
        type: "Veg",
      },
      {
        id: 9019,
        name: "Pizza Roll",
        price: 60,
        description:
          "A delicious roll packed with pizza sauce, cheese, and veggies for a pizza-inspired snack.",
        image:
          "https://www.carriesexperimentalkitchen.com/wp-content/uploads/2012/02/Pizza.-Roll-Stromboli3-256x256.jpeg",
        available: true,
        type: "Veg",
      },
      {
        id: 2352,
        name: "Pizza Pie",
        price: 60,
        description:
          "A flaky pie filled with pizza toppings like cheese, sauce, and veggies, baked to perfection.",
        image:
          "https://bigoven-res.cloudinary.com/image/upload/f_auto,q_auto/t_recipe-256/chicago-style-deep-dish-pizza-e618f3.jpg",
        available: true,
        type: "Veg",
      },
      {
        id: 6786,
        name: "Zingy Parcel",
        price: 60,
        description:
          "A zesty parcel stuffed with a mix of spicy veggies and cheese, wrapped in a crisp pastry.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/8a2/9d2f8a23aa746180ea46d36282c578a2.jpg",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Coffee & Hot Drinks",
    items: [
      {
        id: 1240,
        name: "Cappuccino",
        price: 70,
        description:
          "A classic cappuccino with a perfect balance of espresso, steamed milk, and frothy foam.",
        image:
          "https://b.zmtcdn.com/data/dish_images/2950e2274508a858e4e41797555f00881628659651.png",
        available: true,
        type: "Veg",
      },
      {
        id: 5680,
        name: "Latte",
        price: 80,
        description:
          "A smooth latte with rich espresso and creamy steamed milk, lightly topped with foam.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/9f4/7289d37d85c834a3b27fc0bc6d1719f4.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
      {
        id: 9020,
        name: "Americano",
        price: 60,
        description:
          "A bold Americano made with espresso and hot water, offering a strong coffee kick.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/4f0/d3037e45f05def4757e9c662191294f0.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
      {
        id: 2353,
        name: "Espresso Shot Single",
        price: 40,
        description:
          "A single shot of intense espresso, perfect for a quick caffeine boost.",
        image:
          "https://b.zmtcdn.com/data/dish_images/2950e2274508a858e4e41797555f00881628659651.png",
        available: true,
        type: "Veg",
      },
      {
        id: 6787,
        name: "Espresso Shot Double",
        price: 80,
        description:
          "A double shot of bold espresso, ideal for coffee enthusiasts craving a stronger brew.",
        image:
          "https://b.zmtcdn.com/data/dish_images/2950e2274508a858e4e41797555f00881628659651.png",
        available: true,
        type: "Veg",
      },
    ],
  },
  {
    category: "Patties",
    items: [
      {
        id: 1241,
        name: "Aloo Patty",
        price: 30,
        description:
          "A flaky patty filled with spiced mashed potatoes, offering a comforting savory bite.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/2bc/a759e428487ecb68b0d7160e19e702bc.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
      {
        id: 5681,
        name: "Paneer Patty",
        price: 40,
        description:
          "A crispy patty stuffed with creamy paneer and mild spices, perfect for a quick snack.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/54b/1ddef10e78b6c7fff34aa9a3a165854b.jpg?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
      {
        id: 9021,
        name: "Mushroom Patty",
        price: 40,
        description:
          "A savory patty packed with earthy mushrooms and spices, wrapped in a flaky pastry.",
        image:
          "https://b.zmtcdn.com/data/dish_photos/229/168d6209a2af80c60fa0700e395f1229.png?fit=around|130:130&crop=130:130;*,*",
        available: true,
        type: "Veg",
      },
    ],
  },
];

const metaData = {
  name: "Golden Crumbs",
  location: "Palwal",
  whatsappNo: "9812772109",
  mapLocation: "https://maps.app.goo.gl/YEf5D6aWcNK7uhGTA",
  paymentUpi: "upi://pay?pa=paytm.s1bvnu3@pty&pn=Paytm",
  instagram:
    "https://www.instagram.com/goldencrumbs.in?igsh=MXkxYnE3NWg1a28yNA%3D%3D&utm_source=qr",
  reviewLink: "https://reviewthis.biz/gentle-unit-6527",
  qrCodes: [{ code: "00045", table: "Table No - 1" }],
};
export default { menu, metaData };
