import React, { useEffect, useState } from "react";
import { BiLeftArrow } from "react-icons/bi";
import { BsBack } from "react-icons/bs";
import { FaMinus, FaPlus } from "react-icons/fa";
import { FaLeftLong } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";

const SavedItems = () => {
  const navigate = useNavigate();
  const [savedItems, setSavedItems] = useState([]);
  const [tableNo, setTableNo] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedItems = JSON.parse(sessionStorage.getItem("savedItems")) || [];
    const whatsappNo = sessionStorage.getItem("whatsappNo");
    const tableNo = sessionStorage.getItem("tableNo");
    setWhatsappNo(whatsappNo);
    setTableNo(tableNo);
    setSavedItems(storedItems);
  }, []);

  const handleOrder = () => {
    alert("Order placed successfully!");
    navigate("/");
  };

  const addToCart = (item) => {
    setSavedItems((prevItems) => {
      const itemKey = item.size ? `${item.id}-${item.size}` : `${item.id}`;
      const existingItem = prevItems.find(
        (savedItem) => savedItem.id === item.id && savedItem.size === item.size
      );
      let updatedItems;
      if (existingItem) {
        updatedItems = prevItems.map((savedItem) =>
          savedItem.id === item.id && savedItem.size === item.size
            ? { ...savedItem, quantity: savedItem.quantity + 1 }
            : savedItem
        );
      } else {
        updatedItems = [...prevItems, { ...item, quantity: 1 }];
      }
      sessionStorage.setItem("savedItems", JSON.stringify(updatedItems));
      return updatedItems;
    });
  };
  const clearCart = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to clear the cart?"
    );
    if (isConfirmed) {
      setSavedItems([]);
      sessionStorage.setItem("savedItems", JSON.stringify([]));
    }
  };
  const removeFromCart = (item) => {
    setSavedItems((prevItems) => {
      const existingItem = prevItems.find(
        (savedItem) => savedItem.id === item.id && savedItem.size === item.size
      );
      let updatedItems;
      if (existingItem.quantity > 1) {
        updatedItems = prevItems.map((savedItem) =>
          savedItem.id === item.id && savedItem.size === item.size
            ? { ...savedItem, quantity: savedItem.quantity - 1 }
            : savedItem
        );
      } else {
        updatedItems = prevItems.filter(
          (savedItem) =>
            !(savedItem.id === item.id && savedItem.size === item.size)
        );
      }
      sessionStorage.setItem("savedItems", JSON.stringify(updatedItems));
      return updatedItems;
    });
  };

  const handleSendMessage = () => {
    let message = `🍴 *Order for  ${tableNo}* 🍴\n\n`;
    savedItems.forEach((item, index) => {
      message +=
        `📌 *${index + 1}. ${item.name}* - ₹${item.price}\n` +
        `🔢 Qty: ${item.quantity}\n` +
        (item.size ? `📏 Size: ${item.size}\n` : "") +
        `------------------------\n`;
    });
    message += `💰 *Total:* ₹${savedItems.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    )}\n\n`;
    message += `✅ Please reply "Yes" to confirm your order! 😊\n`;
    const timestamp = new Date().getTime(); // Unique timestamp

    let formattedNumber =
      whatsappNo.startsWith("+") || whatsappNo.startsWith("91")
        ? whatsappNo
        : `+91${whatsappNo}`;

    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${formattedNumber.replace(
      /\D/g,
      ""
    )}?text=${encodedMessage}&_=${timestamp}`;

    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="p-4 bg-gradient-to-br from-gray-50 to-custom-dark/10 min-h-screen relative">
      <h1 className="text-2xl font-extrabold text-custom-dark mb-4 text-center drop-shadow-md">
        Your Cart
      </h1>
      {savedItems.length > 0 ? (
        <div>
          {savedItems.map((item, index) => (
            <div
              key={`${item.id}-${item.size || "default"}`}
              className="bg-white p-4 rounded-xl shadow-md border border-custom-dark/10 mb-4"
            >
              <div className="flex items-start gap-4">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-24 h-24 object-cover rounded-lg shadow-sm"
                />
                <div className="flex-1">
                  <div className="flex items-center gap-2">
                    <h3 className="text-lg font-bold text-custom-dark">
                      {item.name}
                    </h3>
                    {item.size && (
                      <span className="bg-theme-green text-white px-2 py-1 rounded-full text-sm font-semibold">
                        {item.size}
                      </span>
                    )}
                  </div>
                  <p className="text-sm text-gray-600 mt-1 line-clamp-2">
                    {item.description}
                  </p>
                  <div className="flex items-center justify-between mt-3">
                    <span className="text-base font-bold text-theme-green">
                      ₹{item.price}
                    </span>
                    <div className="flex gap-2 items-center">
                      <button
                        onClick={() => removeFromCart(item)}
                        className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-300"
                      >
                        <FaMinus className="text-sm" />
                      </button>
                      <span className="text-base font-bold text-custom-dark">
                        {item.quantity}
                      </span>
                      <button
                        onClick={() => addToCart(item)}
                        className="p-2 bg-theme-green text-white rounded-full hover:bg-theme-green/80 transition-colors duration-300"
                      >
                        <FaPlus className="text-sm" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <h2 className="text-xl font-bold mt-4 text-custom-dark text-center">
            Total: ₹
            {savedItems.reduce(
              (acc, item) => acc + item.price * item.quantity,
              0
            )}
          </h2>
          <button
            onClick={handleSendMessage}
            className="mt-4 w-full bg-gradient-to-r from-custom-dark to-theme-green text-white font-bold py-2 px-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-300"
          >
            Place Order
          </button>
          <button
            onClick={clearCart}
            className="mt-4 ml-auto w-full bg-gradient-to-r from-custom-dark to-theme-green text-white font-bold py-2 px-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-300"
          >
            Clear Cart
          </button>
        </div>
      ) : (
        <p className="text-gray-600 text-center text-lg font-medium mt-16">
          Your cart is empty.
        </p>
      )}
      <div
        onClick={() => navigate(-1)}
        className="fixed w-full left-0 bottom-0 bg-gradient-to-r from-custom-dark to-theme-green text-white px-4 py-3 shadow-xl h-16 flex items-center gap-2"
      >
        <FaLeftLong className="text-yellow-300 text-xl" />
        <span className="text-base font-semibold">Back to Menu</span>
      </div>
    </div>
  );
};

export default SavedItems;
